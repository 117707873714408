import {
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { cn } from "@/lib/utils";
import { Portal } from "@headlessui/react";

interface FloatingBarProps extends HTMLAttributes<HTMLDivElement> {
  action?: ReactNode;
}

export default function FloatingBar({
  className,
  children,
  action,
  ...props
}: FloatingBarProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [coords, setCoords] = useState({});

  const calculateCoords = useCallback(() => {
    if (!ref.current) return;
    const el = ref.current.parentElement!;
    const left = el.getBoundingClientRect().left - 8;
    setCoords({ left, width: el.offsetWidth + 16 });
  }, [ref]);

  useLayoutEffect(() => {
    calculateCoords();
  }, [calculateCoords]);

  useEffect(() => {
    window.addEventListener("resize", calculateCoords);
    return () => window.removeEventListener("resize", calculateCoords);
  }, [calculateCoords]);

  return (
    <div ref={ref}>
      <Portal>
        <div
          className={cn(
            "pointer-events-none fixed bottom-4 z-30 flex items-center justify-center",
            className,
          )}
          style={coords}
          {...props}
        >
          <div className="pointer-events-auto inline-flex max-w-full flex-col items-center gap-x-8 gap-y-4 rounded-xl border bg-card p-4 text-card-foreground shadow-xl md:min-w-[32rem] md:flex-row">
            {children && (
              <div className="flex flex-grow flex-wrap items-center gap-x-5 gap-y-2 px-2 py-1">
                {children}
              </div>
            )}
            {action && (
              <div className="flex items-center justify-center gap-4 md:-my-1 md:-mr-1 md:ml-auto">
                {action}
              </div>
            )}
          </div>
        </div>
      </Portal>
    </div>
  );
}

export function InlineLabeledValue({
  value,
  label,
}: {
  value: ReactNode;
  label: ReactNode;
}) {
  return (
    <div>
      <span>{label}:</span>
      <span className="ml-1.5 font-semibold">{value}</span>
    </div>
  );
}
