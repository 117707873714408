import { Check, Plus, XIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { SyntheticEvent } from "react";

export default function AddProductIcon({
  selected = false,
  outOfStock = false,
  onClick,
}: {
  selected?: boolean | number;
  outOfStock?: boolean;
  onClick?: (e: SyntheticEvent) => void;
}) {
  return (
    <div
      role={onClick ? "button" : undefined}
      className="group absolute bottom-0 right-0"
      title={
        selected ? "Selected" : outOfStock ? "Out of stock" : "Select product"
      }
      onClick={onClick}
    >
      <div
        className={cn(
          "mx-4 my-3 flex h-7 items-center justify-center rounded-full p-1",
          typeof selected === "number" ? "w-auto px-2" : "w-7",
          selected
            ? "bg-green-500 text-white"
            : outOfStock
            ? "bg-red-500 text-white"
            : cn(
                "border border-primary bg-card text-primary",
                onClick && "group-hover:bg-primary group-hover:text-white",
              ),
        )}
      >
        {selected ? (
          <Check className="size-5" />
        ) : outOfStock ? (
          <XIcon className="size-5" />
        ) : (
          <Plus className="size-5" />
        )}
        {typeof selected === "number" && (
          <span className="ml-1 text-xs">x {selected}</span>
        )}
      </div>
    </div>
  );
}
