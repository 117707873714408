import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { giftStatusSchema } from "avail-types";
import startCase from "lodash/startCase";

export type GiftFilters = Partial<{
  sender_id: string;
  queue: string | null;
  status: string;
}>;

function filterToString(filter: unknown) {
  if (filter == null) {
    return "undefined";
  }
  if (typeof filter === "object") {
    return "null";
  }
  return String(filter);
}

function stringToFilter(value: string) {
  return value === "undefined"
    ? undefined
    : value === "null"
    ? { null: 1 }
    : value;
}

export default function GiftsTableFilters({
  senders,
  queues,
  filters,
  setFilters,
}: {
  senders:
    | {
        [key: number]: string;
      }
    | undefined;
  queues: string[] | undefined;
  filters: GiftFilters;
  setFilters: React.Dispatch<React.SetStateAction<GiftFilters>>;
}) {
  const handleFilterChange = (
    filterType: "sender_id" | "queue" | "status",
    value: string,
  ) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: stringToFilter(value),
    }));
  };

  const sendersArray = Object.entries(senders ?? {});

  return (
    <div className="flex flex-row space-x-4">
      {sendersArray.length > 1 && (
        <Select
          value={filterToString(filters.sender_id)}
          onValueChange={(value) => handleFilterChange("sender_id", value)}
        >
          <SelectTrigger className="w-44">
            <SelectValue placeholder="Choose a user" className="mr-2" />
          </SelectTrigger>
          <SelectContent className="pr-2">
            <SelectItem value="undefined">All Senders</SelectItem>
            {sendersArray.map(([key, value]) => (
              <SelectItem key={key} value={key}>
                {value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}

      {queues && queues.length > 0 && (
        <Select
          value={filterToString(filters.queue)}
          onValueChange={(value) => handleFilterChange("queue", value)}
        >
          <SelectTrigger className="w-44">
            <SelectValue placeholder="Choose a queue" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="undefined">All Queues</SelectItem>
            <SelectItem value="null">No Queue</SelectItem>
            {queues.map((queue: string) => (
              <SelectItem key={queue} value={queue}>
                {queue}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}

      <Select
        defaultValue={filterToString(filters.status)}
        onValueChange={(value) => handleFilterChange("status", value)}
      >
        <SelectTrigger className="w-44">
          <SelectValue placeholder="Choose a status" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="undefined">All Statuses</SelectItem>
          {giftStatusSchema.options
            .map((o) => o.value)
            .map((status) => (
              <SelectItem key={status} value={status}>
                {startCase(status)}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    </div>
  );
}
