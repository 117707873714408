import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Cart, DecorationConfig, OrderCreatePayload } from "avail-types";
import { useDebounce } from "usehooks-ts";

export const CART_KEY = "cart";

export function useGetCart() {
  return useQuery(
    [CART_KEY, "detail"],
    () => axios.get<Cart>("/cart").then(({ data }) => data),
    {
      refetchOnMount: false,
    },
  );
}

export interface AddToCartPayload {
  product_id: number;
  variants: { variant_id: number; qty: number }[];
  design_layout_id?: number;
  decoration?: DecorationConfig;
  collection_id?: number;
}

export function useAddItem() {
  const queryClient = useQueryClient();

  return useMutation((payload: AddToCartPayload) =>
    axios.post<Cart>("/cart/items", payload).then(({ data }) => {
      queryClient.setQueryData<Cart>([CART_KEY, "detail"], data);
    }),
  );
}

export function useRemoveItem() {
  const queryClient = useQueryClient();

  return useMutation((id: number) =>
    axios.delete<Cart>(`cart/items/${id}`).then(({ data }) => {
      queryClient.setQueryData<Cart>([CART_KEY, "detail"], data);
    }),
  );
}

export function useCartPreview(payload: AddToCartPayload, enabled: boolean) {
  const debounced = useDebounce({ payload, enabled }, 500);

  return useQuery(
    [CART_KEY, "preview", JSON.stringify(debounced.payload)],
    () =>
      axios
        .post<OrderCreatePayload>("/cart/preview", debounced.payload)
        .then(({ data }) => data),
    {
      enabled: debounced.enabled,
    },
  );
}
