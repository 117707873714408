import EmptyState from "@/components/ui/empty-state";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Globe } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useGetDomains } from "@/api/domains";

export default function DomainsCard() {
  const { data: domains = [] } = useGetDomains();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Email Domains</CardTitle>
      </CardHeader>
      <CardContent>
        {domains.length > 0 ? (
          <Card className="mt-4">
            <ul className="divide-y divide-slate-200">
              {domains.map((d) => (
                <li
                  key={d.domain}
                  className="flex items-center space-x-3 px-5 py-3"
                >
                  <Avatar>
                    <AvatarFallback className="bg-secondary text-secondary-foreground">
                      <Globe />
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex-grow flex-col">
                    <div>{d.domain}</div>
                    <div className="text-sm text-muted-foreground">
                      Users who sign up with this email domain
                      {d.is_auto
                        ? " will automatically join this account as a guest."
                        : " can request to join this account."}
                    </div>
                  </div>
                  <div className="ml-auto flex items-center space-x-2"></div>
                </li>
              ))}
            </ul>
          </Card>
        ) : (
          <EmptyState
            title="No domains"
            description="Ask your Avail account manager about claiming email domains."
          />
        )}
      </CardContent>
    </Card>
  );
}
