import { AddressPayload } from "avail-types";
import { useState } from "react";
import { Loader } from "lucide-react";
import pluralize from "pluralize";
import { useShippingEstimate } from "@/api/fulfillment-orders";

export default function TransitTimeEstimator({
  address,
}: {
  address?: AddressPayload;
}) {
  const [enabled, setEnabled] = useState(false);

  const { data, isLoading } = useShippingEstimate(
    enabled ? address : undefined,
  );

  return (
    <div className="mt-2 flex items-center">
      {!address?.zip ? null : !enabled ? (
        <button
          type="button"
          onClick={() => setEnabled(true)}
          className="text-sm font-medium text-primary hover:underline"
        >
          Estimate Transit Time
        </button>
      ) : isLoading ? (
        <Loader className="animate-spin" />
      ) : (
        <p className="text-sm">
          The estimate transit time to your destination is{" "}
          <b className="font-semibold">
            {pluralize("day", data?.estimated_days, true)}
          </b>
          .
        </p>
      )}
    </div>
  );
}
