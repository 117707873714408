import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { giftingTemplatePayloadSchema } from "avail-types";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import InsertTemplateVariable from "@/components/gifting/templates/InsertTemplateVariable";
import { SheetFooter } from "@/components/ui/sheet";

type GiftingTemplatePayload = z.infer<typeof giftingTemplatePayloadSchema>;

export default function GiftingTemplateForm({
  defaultValues = {},
  onSubmit,
}: {
  defaultValues?: Partial<GiftingTemplatePayload>;
  onSubmit: (payload: GiftingTemplatePayload) => Promise<unknown>;
}) {
  const form = useForm<GiftingTemplatePayload>({
    resolver: zodResolver(giftingTemplatePayloadSchema),
    defaultValues,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="field"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Field</FormLabel>
              <Select value={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a field" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className="max-h-[20rem] overflow-y-auto">
                  <SelectItem value="subject">Gift Subject</SelectItem>
                  <SelectItem value="message">Gift Message</SelectItem>
                  <SelectItem value="gift_note">Custom Note Card</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="template"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center justify-between">
                <FormLabel>Template</FormLabel>
                <InsertTemplateVariable
                  value={field.value}
                  onChange={field.onChange}
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  value={field.value || ""}
                  className="min-h-[15rem]"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <SheetFooter className="mt-6">
          <Button type="submit" isLoading={form.formState.isSubmitting}>
            Submit
          </Button>
        </SheetFooter>
      </form>
    </Form>
  );
}
