import { useEffect } from "react";
import { ALLOWS_SIGNUP } from "@/constants/services";
import { analytics } from "@/lib/analytics";

const BASE_TITLE = ALLOWS_SIGNUP ? "Avail Swag Management" : "Avail Portal";

export function useTitle(primary: string | null | undefined, backup?: string) {
  const pageName = backup || primary; // If backup is provided, it's the generic page name "Project"
  const title = primary || backup; // If primary is provided, it's the specific page name (e.g. Project Name)"

  useEffect(() => {
    document.title = `${title} - ${BASE_TITLE}`;
  }, [title]);

  useEffect(() => {
    if (ALLOWS_SIGNUP) {
      analytics.page(pageName || "Unknown", {
        name: primary,
      });
    }
  }, [pageName, primary]);
}
