import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  FulfillmentOrderPayload,
  fulfillmentOrderPayloadSchema,
} from "avail-types";
import { Checkbox } from "@/components/ui/checkbox";
import { CalendarIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import AddressFormSection from "@/components/addresses/AddressFormSection";
import { useAppState } from "@/context/AppContext";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";
import { FormErrors } from "@/components/ui/form-errors";
import { formatDate } from "@/lib/dates";
import { Label } from "@/components/ui/label";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import TransitTimeEstimator from "@/components/fulfillmentOrders/TransitTimeEstimator";
import { Textarea } from "@/components/ui/textarea";

const formSchema = fulfillmentOrderPayloadSchema.pick({
  email: true,
  address: true,
  earliest_ship_date: true,
  customer_po: true,
  send_confirmation: true,
});

const METHODS = [
  "Best Value",
  "Cheapest",
  "Ground",
  "2-Day",
  "3-Day",
  "Next Day Standard (PM)",
  "Next Day AM",
  "Next Day Air",
  "Pickup",
];

export default function OrderDetailsStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: Partial<FulfillmentOrderPayload>;
  onSubmit: (values: Partial<FulfillmentOrderPayload>) => void;
}) {
  const [shouldWait, setShouldWait] = useState(false);
  const { settings } = useAppState();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: {
        method: "Best Value",
        country: "US",
      },
      send_confirmation: true,
      ...defaultValues,
    },
  });

  const isThirdParty = form.watch("address.method")?.startsWith("third_party:");
  const earliestShipDate = form.watch("earliest_ship_date");

  useEffect(() => {
    if (!shouldWait) {
      form.setValue("earliest_ship_date", null);
    }
  }, [shouldWait]);

  useEffect(() => {
    if (!earliestShipDate) {
      setShouldWait(false);
    }
  }, [earliestShipDate]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <AddressFormSection form={form} size="sm" />

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          <div className="lg:col-span-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="send_confirmation"
              render={({ field }) => (
                <FormItem className="mt-3 flex items-center space-x-3 space-y-0 whitespace-nowrap">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel variant="checkbox">
                    Send Shipment Confirmation
                  </FormLabel>
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="address.method"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Method</FormLabel>
                <Select
                  value={field.value || ""}
                  onValueChange={field.onChange}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a method" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="max-h-[20rem] overflow-y-auto">
                    {METHODS.map((method) => (
                      <SelectItem key={method} value={method}>
                        {method}
                      </SelectItem>
                    ))}
                    {settings.third_party_accounts.map((account) => (
                      <SelectItem
                        key={account.account_number}
                        value={`third_party:${account.account_number}`}
                      >
                        {startCase(account.carrier)} Third Party #
                        {account.account_number}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="customer_po"
            render={({ field }) => (
              <FormItem>
                <FormLabel>PO Number</FormLabel>
                <FormControl>
                  <Input type="text" {...field} value={field.value || ""} />
                </FormControl>
                <FormDescription>
                  This optional field could be a PO number, cost center, or any
                  reference number.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          {isThirdParty && (
            <>
              <FormField
                control={form.control}
                name="address.reference_1"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Reference 1</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} value={field.value || ""} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address.reference_2"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Reference 2</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} value={field.value || ""} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}

          <Label
            variant="checkbox"
            className="col-span-full flex items-center space-x-2"
          >
            <Checkbox
              checked={shouldWait}
              onCheckedChange={(b) => setShouldWait(Boolean(b))}
            />
            <span>This order needs to arrive within a window</span>
          </Label>

          {shouldWait && (
            <>
              <div>
                <FormField
                  control={form.control}
                  name="earliest_ship_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Earliest Ship Date</FormLabel>
                      <FormControl>
                        <div>
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-full justify-start rounded-lg text-left font-normal",
                                  !field.value && "text-muted-foreground",
                                )}
                              >
                                <CalendarIcon className="mr-2 size-4" />
                                {field.value ? (
                                  formatDate(field.value)
                                ) : (
                                  <span>Pick a date</span>
                                )}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                              <Calendar
                                mode="single"
                                selected={
                                  field.value
                                    ? new Date(field.value)
                                    : undefined
                                }
                                onSelect={(selected) =>
                                  field.onChange(
                                    selected ? selected.toISOString() : null,
                                  )
                                }
                                fromDate={new Date()}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <TransitTimeEstimator address={form.watch("address")} />
              </div>
              <FormField
                control={form.control}
                name="address.customer_label"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Additional Label Instructions</FormLabel>
                    <FormControl>
                      <Textarea {...field} value={field.value || ""} />
                    </FormControl>
                    <FormDescription>
                      These instructions will be attached as a label to each box
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
        </div>

        <FormErrors form={form} />

        <WizardSubmitButton>Submit</WizardSubmitButton>
      </form>
    </Form>
  );
}
