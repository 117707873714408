import { useAppContext } from "@/context/AppContext";
import { ReactNode } from "react";
import { MembershipRole } from "avail-types";

export default function Gate({
  role,
  children,
  fallback = null,
}: {
  role: MembershipRole | MembershipRole[];
  children: ReactNode;
  fallback?: ReactNode;
}) {
  const { membership } = useAppContext();
  const roles = Array.isArray(role) ? role : [role];

  if (roles.length === 0) {
    return children;
  }

  if (!membership || !roles.includes(membership.role)) {
    return fallback;
  }

  return children;
}
