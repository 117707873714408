import ProjectOptionCard from "@/components/projects/ProjectOptionCard";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ProjectOptionGroupMenu from "@/components/projects/ProjectOptionGroupMenu";
import AddProductIcon from "@/components/products/AddProductIcon";
import pluralize from "pluralize";
import { ProjectOption, ProjectSelection } from "avail-types";
import { useState } from "react";

export default function ProjectOptionGroup({
  options,
  onSelectOption,
  selections,
  onEditOption,
}: {
  options: ProjectOption[];
  onSelectOption: (o: ProjectOption) => void;
  onEditOption: (o: ProjectOption) => void;
  selections: ProjectSelection[];
}) {
  const [index, setIndex] = useState(0);
  const option = options[index]!;
  const selection = selections.find((s) => s.option.id === option.id);
  const lastIndex = options.length - 1;

  return (
    <div key={option.id} className="group relative">
      <ProjectOptionCard option={option} onClick={() => onSelectOption(option)}>
        <ProjectOptionGroupMenu onEditOption={onEditOption} option={option} />

        <AddProductIcon
          selected={selection?.quantities.reduce((a, b) => a + b, 0)}
        />

        {lastIndex > 0 && (
          <>
            <p className="mt-1 text-sm text-muted-foreground">
              +{pluralize("color", lastIndex, true)}
            </p>

            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIndex((prev) => (prev === 0 ? lastIndex : prev - 1));
              }}
              className="absolute left-3 top-1/2 hidden rounded-full bg-card p-1 shadow-sm hover:shadow group-hover:inline-flex"
            >
              <ChevronLeft className="size-5 text-muted-foreground" />
            </button>

            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIndex((prev) => (prev === lastIndex ? 0 : prev + 1));
              }}
              className="absolute right-3 top-1/2 hidden rounded-full bg-card p-1 shadow-sm hover:shadow group-hover:inline-flex"
            >
              <ChevronRight className="size-5 text-muted-foreground" />
            </button>
          </>
        )}
      </ProjectOptionCard>
    </div>
  );
}
