import AddressFields from "@/components/addresses/AddressFields";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addressPayloadSchema } from "avail-types";
import { z } from "zod";
import { FormErrors } from "@/components/ui/form-errors";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";

const formSchema = z.object({
  address: addressPayloadSchema,
});

type FormShape = z.infer<typeof formSchema>;

export default function GiftAddressForm({
  defaultValues,
  onSubmit,
  isLoading,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
  isLoading: boolean;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h1 className="mb-4 text-2xl font-semibold">
          Enter Your Shipping Address
        </h1>

        <AddressFields form={form} />

        <FormErrors form={form} />

        <WizardSubmitButton
          className="mt-6"
          isLoading={isLoading || form.formState.isSubmitting}
        >
          Next
        </WizardSubmitButton>
      </form>
    </Form>
  );
}
