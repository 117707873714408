import {
  GiftingProductPayload,
  giftingProductPayloadSchema,
} from "avail-types";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InventoryType from "@/components/gifting/products/form/InventoryType";
import KitType from "@/components/gifting/products/form/KitType";
import MadeToOrderType from "@/components/gifting/products/form/MadeToOrderType";
import TypeSelect from "@/components/gifting/products/form/TypeSelect";
import { Button } from "@/components/ui/button";
import { useCreateGiftingProduct } from "@/api/gifting-products";
import { handleLaravelErrors } from "@/lib/form";
import { FormErrors } from "@/components/ui/form-errors";
import { Card } from "@/components/ui/card";
import { usePersistentForm } from "@/hooks/usePersistentForm";
import GiftCardType from "@/components/gifting/products/form/GiftCardType";
import StripeGate from "@/components/account/StripeGate";

const STORAGE_KEY = "giftingProductForm";

export default function GiftingProductCreateForm({
  defaultValues,
}: {
  defaultValues: Partial<GiftingProductPayload>;
}) {
  const form = useForm<GiftingProductPayload>({
    resolver: zodResolver(giftingProductPayloadSchema),
    defaultValues: defaultValues,
  });
  const createRequest = useCreateGiftingProduct();
  const type = form.watch("type");

  const { clearPersistedState } = usePersistentForm<GiftingProductPayload>({
    key: STORAGE_KEY,
    form,
  });

  const onSubmit = (data: GiftingProductPayload) =>
    createRequest
      .mutateAsync(data)
      .then(() => {
        clearPersistedState();
      })
      .catch(handleLaravelErrors(form));

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="max-w-6xl space-y-6"
      >
        <div className="grid gap-6 lg:grid-cols-3">
          <TypeSelect
            form={form}
            className={type ? "col-span-1" : "col-span-3"}
          />

          {type && (
            <Card className="mt-8 px-5 py-4 lg:col-span-2">
              {type === "inventory" ? (
                <InventoryType form={form} />
              ) : type === "kit" ? (
                <KitType form={form} isCreate />
              ) : type === "on_demand" ? (
                <MadeToOrderType form={form} isCreate defaultType="database" />
              ) : type === "batch" ? (
                <MadeToOrderType form={form} isCreate defaultType="database" />
              ) : type === "gift_card" ? (
                <StripeGate
                  feature="gift-cards"
                  featureTitle="Digital Gift Cards"
                >
                  <GiftCardType form={form} isCreate />
                </StripeGate>
              ) : null}
            </Card>
          )}
        </div>

        <FormErrors form={form} />

        <div className="flex justify-end">
          <Button type="submit" isLoading={form.formState.isSubmitting}>
            Create
          </Button>
        </div>
      </form>
    </Form>
  );
}
