import {
  GiftingProduct,
  GiftingProductPayload,
  giftingProductPayloadSchema,
} from "avail-types";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import ProjectOptionImagesField from "@/components/projects/ProjectOptionImagesField";
import InventoryType from "@/components/gifting/products/form/InventoryType";
import KitType from "@/components/gifting/products/form/KitType";
import MadeToOrderType from "@/components/gifting/products/form/MadeToOrderType";
import { Button } from "@/components/ui/button";
import { useUpdateGiftingProduct } from "@/api/gifting-products";
import { handleLaravelErrors } from "@/lib/form";
import { Card } from "@/components/ui/card";
import startCase from "lodash/startCase";
import { Textarea } from "@/components/ui/textarea";
import GiftCardType from "@/components/gifting/products/form/GiftCardType";

export default function GiftingProductEditForm({
  product,
}: {
  product: GiftingProduct;
}) {
  const form = useForm<GiftingProductPayload>({
    resolver: zodResolver(giftingProductPayloadSchema),
    defaultValues: product,
  });
  const updateRequest = useUpdateGiftingProduct(product.id);

  const type = form.watch("type");
  const designLayoutId = form.watch("design_layout_id");

  const onSubmit = (data: GiftingProductPayload) =>
    updateRequest.mutateAsync(data).catch(handleLaravelErrors(form));

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-6 grid grid-cols-5 gap-6">
          <div className="col-span-full space-y-4 lg:col-span-3">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="images"
              render={({ field }) => (
                <ProjectOptionImagesField
                  images={field.value}
                  onChange={field.onChange}
                  designLayoutId={designLayoutId}
                />
              )}
            />

            <FormField
              control={form.control}
              name="is_active"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <FormLabel variant="checkbox">Active</FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </div>
          <Card className="col-span-full flex flex-col px-5 py-4 lg:col-span-2">
            <h4 className="mb-4 text-lg font-semibold">
              {startCase(type)} Product Details
            </h4>
            {type === "inventory" ? (
              <InventoryType form={form} />
            ) : type === "kit" ? (
              <KitType form={form} />
            ) : type === "on_demand" ? (
              <MadeToOrderType
                form={form}
                defaultType={
                  form.getValues()["product_id"] ? "database" : "custom"
                }
              />
            ) : type === "batch" ? (
              <MadeToOrderType
                form={form}
                defaultType={
                  form.getValues()["product_id"] ? "database" : "custom"
                }
              />
            ) : type === "gift_card" ? (
              <GiftCardType form={form} />
            ) : null}
          </Card>
        </div>
        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Save
        </Button>
      </form>
    </Form>
  );
}
