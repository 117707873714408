import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";
import SkuItem from "@/components/inventory/SkuItem";
import { useGetVariant } from "@/api/variants";

interface SkuItemProps extends HTMLAttributes<HTMLDivElement> {
  variantId: number;
  qty?: number;
}

export default function SkuItemById({
  variantId,
  className,
  ...other
}: SkuItemProps) {
  const { data } = useGetVariant(variantId);

  if (!data) {
    return (
      <div className={cn("flex items-center", className)} {...other}>
        <Skeleton className="mr-2 size-10 rounded-full" />
        <Skeleton />
      </div>
    );
  }

  return <SkuItem sku={data} className={className} {...other} />;
}
