import { MinusIcon, PlusIcon } from "lucide-react";
import { ButtonHTMLAttributes } from "react";
import { cn } from "@/lib/utils";

function QtyButton({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      className={cn(
        "inline-flex h-8 w-8 items-center justify-center rounded-full border border-primary bg-card text-primary hover:bg-primary hover:text-white",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export default function QuantityInput({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <div className="flex items-center gap-4 text-primary">
      <QtyButton
        onClick={(e) => {
          e.stopPropagation();
          onChange(value - 1);
        }}
      >
        <MinusIcon className="size-4" />
      </QtyButton>
      <span className="w-6 text-center text-xl font-medium">{value}</span>
      <QtyButton
        onClick={(e) => {
          e.stopPropagation();
          onChange(value + 1);
        }}
      >
        <PlusIcon className="size-4" />
      </QtyButton>
    </div>
  );
}
