import Mustache from "mustache";

export const TEMPLATE_VARIABLES = [
  "first_name",
  "last_name",
  "company",
  "email",
  "sender_name",
  "sender_email",
] as const;

export interface GiftVariables {
  first_name: string;
  last_name: string;
  company?: string | null;
  email: string;
  sender_name: string;
  sender_email: string;
}

export function renderTemplate(template: string, variables: GiftVariables) {
  try {
    return Mustache.render(template, variables);
  } catch (e) {
    if (e instanceof Error) {
      return `Error rendering template: ${e.message}`;
    }
    return "Error rendering template";
  }
}
