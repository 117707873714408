import { UseFormReturn } from "react-hook-form";
import { CartProduct, GiftingProductPayload } from "avail-types";
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import ProductSelect from "@/components/selects/ProductSelect";
import { useQueryClient } from "@tanstack/react-query";
import { getProductQueryKey } from "@/api/products";

export default function InventoryType({
  form,
}: {
  form: UseFormReturn<GiftingProductPayload>;
}) {
  const queryClient = useQueryClient();

  const handleProductChange = (id: number) => {
    form.setValue("inventory_product_id", id);

    const product = queryClient.getQueryData<CartProduct>(
      getProductQueryKey(id),
    );

    if (!product) {
      console.warn(`Product #${id} not found in cache`);
      return;
    }

    form.setValue("name", product.name);
    form.setValue(
      "images",
      product.images.map((i) => i.url),
    );
    form.setValue(
      "variants",
      product.variants.map((v) => ({
        inventory_variant_id: v.id,
      })),
    );
  };

  return (
    <FormField
      control={form.control}
      name="inventory_product_id"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Inventory Product</FormLabel>
          <ProductSelect
            value={field.value || null}
            onChange={handleProductChange}
            params={{
              owned: "1",
            }}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
