import { Link, useParams } from "react-router-dom";
import SplashScreen from "@/pages/SplashScreen";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { DataCell, DataList } from "@/components/ui/data-list";
import AddressBlock from "@/components/addresses/AddressBlock";
import { ArrowRight } from "lucide-react";
import FulfillmentOrderStatus from "@/components/fulfillmentOrders/FulfillmentOrderStatus";
import InventoryReturnStatus from "@/components/inventory/InventoryReturnStatus";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card } from "@/components/ui/card";
import { useGetFulfillmentOrder } from "@/api/fulfillment-orders";
import { useTitle } from "@/lib/title";
import { formatDate } from "@/lib/dates";

export default function FulfillmentOrderDetail() {
  const { id } = useParams<{ id: string }>();

  const { data: order, isLoading } = useGetFulfillmentOrder(Number(id));
  useTitle(order?.number, "Fulfillment Orders");

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!order) {
    return null;
  }

  return (
    <div className="flex-1 space-y-8">
      <div>
        <Title className="flex items-center gap-4">
          {order.number}
          <FulfillmentOrderStatus status={order.status} />
        </Title>
        <Description>
          <span>Inventory order placed </span>
          {order.user && <span>by {order.user.name} </span>}
          <span>on {formatDate(order.date)}</span>
        </Description>
      </div>

      <Card className="p-5">
        <DataList className="xl:grid-cols-3 2xl:grid-cols-4">
          <DataCell label="Shipping Address">
            <AddressBlock address={order.address} />
          </DataCell>
          <DataCell label="Contact Information">
            <div>{order.name}</div>
            <div>{order.email}</div>
            <div>{order.phone}</div>
          </DataCell>
          {order.address.customer_label && (
            <DataCell
              label="Additional Label Instructions"
              className="whitespace-pre-wrap"
            >
              {order.address.customer_label}
            </DataCell>
          )}
          {order.shipments.map((s) => (
            <DataCell label="Tracking Number" key={s.id}>
              <a
                target="_blank"
                rel="noreferrer"
                href={s.tracking_url || "#"}
                className="font-medium text-primary"
              >
                {s.tracking_number}
              </a>
            </DataCell>
          ))}
        </DataList>
      </Card>

      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>SKU</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Quantity</TableHead>
              <TableHead>Notes</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {order.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <span className="font-medium">{item.sku}</span>
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.qty.toLocaleString()}</TableCell>
                <TableCell>{item.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      {order.inventory_returns && order.inventory_returns.length > 0 ? (
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Return Number</TableHead>
                <TableHead>Date Created</TableHead>
                <TableHead>Status</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {order.inventory_returns.map((inventory_return) => (
                <TableRow key={inventory_return.number}>
                  <TableCell>
                    <Link
                      to={`/inventory-returns/${inventory_return.id}`}
                      className="font-medium"
                    >
                      {inventory_return.number}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {formatDate(inventory_return.created_at)}
                  </TableCell>
                  <TableCell>
                    <InventoryReturnStatus status={inventory_return.status} />
                  </TableCell>
                  <TableCell>
                    <div className="space-x-2">
                      <Link to={`/inventory-returns/${inventory_return.id}`}>
                        <ArrowRight className="size-5 text-muted-foreground" />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      ) : null}
    </div>
  );
}
