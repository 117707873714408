import { CreditCard, ShirtIcon } from "lucide-react";
import { GiftingProduct } from "avail-types";
import { imageUrl } from "@/lib/image";
import { cn } from "@/lib/utils";

export default function GiftingProductImage({
  product,
  className = "mx-auto w-full size-40 md:size-56",
  iconClassName = "p-8 md:p-12",
}: {
  product: Pick<GiftingProduct, "name" | "images" | "fulfillment_type">;
  className?: string;
  iconClassName?: string;
}) {
  if (!product.images[0]) {
    const Icon =
      product.fulfillment_type === "gift_card" ? CreditCard : ShirtIcon;
    return (
      <Icon className={cn("text-muted-foreground", className, iconClassName)} />
    );
  }
  return (
    <img
      className={cn("object-contain", className)}
      src={imageUrl(product.images[0], { w: 500 })}
      alt={product.name}
    />
  );
}
