import { useEffect } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";

export function usePersistentForm<TFieldValues extends FieldValues>({
  key,
  form,
}: {
  key: string;
  form: UseFormReturn<TFieldValues>;
}) {
  useEffect(() => {
    const savedState = sessionStorage.getItem(key);
    if (savedState) {
      try {
        const parsedState = JSON.parse(savedState);
        form.reset(parsedState);
      } catch (error) {
        console.error("Failed to parse saved form state:", error);
        sessionStorage.removeItem(key);
      }
    }
  }, [form, key]);

  useEffect(() => {
    const subscription = form.watch((value) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    });
    return () => subscription.unsubscribe();
  }, [form, key]);

  const clearPersistedState = () => {
    sessionStorage.removeItem(key);
  };

  return { clearPersistedState };
}
