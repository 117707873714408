import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  Collection,
  GiftingTemplate,
  GiftingTemplatePayload,
} from "avail-types";

export const GIFTING_TEMPLATES_KEY = "giftingTemplates";

export function useGetGiftingTemplates(type: string) {
  return useQuery(
    [GIFTING_TEMPLATES_KEY, "list", type],
    () =>
      axios
        .get<Collection<GiftingTemplate>>(
          `gifting-templates?filter[field]=${type}&count=100`,
        )
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 60,
    },
  );
}

export function useCreateGiftingTemplate() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: GiftingTemplatePayload) =>
      axios
        .post<GiftingTemplate>("gifting-templates", payload)
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GIFTING_TEMPLATES_KEY]);
      },
    },
  );
}

export function useUpdateGiftingTemplate() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, ...payload }: GiftingTemplatePayload & { id: number }) =>
      axios
        .put<GiftingTemplate>(`gifting-templates/${id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GIFTING_TEMPLATES_KEY]);
      },
    },
  );
}

export function useDeleteGiftingTemplate() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`gifting-templates/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([GIFTING_TEMPLATES_KEY]);
    },
  });
}
