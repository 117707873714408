import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { GiftBulkCreatePayload, GiftRecipientPayload } from "avail-types";
import { useGetConnectedIntegrations } from "@/api/integrations";
import { useMemo, useState } from "react";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { Button } from "@/components/ui/button";
import { PlusIcon, TrashIcon, UploadIcon, UsersIcon } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import GiftRecipientForm from "@/components/gifting/send/GiftRecipientForm";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import GiftRecipientUploadForm from "@/components/gifting/send/GiftRecipientUploadForm";
import Link from "@/components/ui/link";
import AddressText from "@/components/addresses/AddressText";
import { GiftRecipientsFromIntegrationDialog } from "@/components/gifting/send/GiftRecipientsFromIntegrationDialog";
import EmptyState from "@/components/ui/empty-state";
import { Card } from "@/components/ui/card";
import { IntegrationRecipient } from "@/api/integration-recipients";
import { getIsDigitalGift } from "@/components/gifting/utils";

const columnHelper = createColumnHelper<
  GiftRecipientPayload | IntegrationRecipient
>();
export default function GiftBulkRecipientStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<GiftBulkCreatePayload>;
  onSubmit: (v: Partial<GiftBulkCreatePayload>) => Promise<void>;
}) {
  const [adding, setAdding] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [recipients, setRecipients] = useState<
    (GiftRecipientPayload | IntegrationRecipient)[]
  >(defaultValues.recipients || []);
  const peopleIntegrations = useGetConnectedIntegrations().filter(
    (i) => i.category === "people",
  );
  const isDigitalGift = getIsDigitalGift(defaultValues);

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor("first_name", {
          header: "First Name",
        }),
        columnHelper.accessor("last_name", {
          header: "Last Name",
        }),
        columnHelper.accessor("company", {
          header: "Company",
        }),
        columnHelper.accessor("email", {
          header: "Email",
          cell: ({ row, getValue }) => (
            <div className="flex items-center gap-2">
              {getValue()}
              {row.original.external_source && (
                <IntegrationIcon
                  type={row.original.external_source}
                  size="size-5"
                  url={
                    "external_url" in row.original
                      ? row.original.external_url
                      : undefined
                  }
                />
              )}
            </div>
          ),
        }),
        !isDigitalGift &&
          columnHelper.accessor("address", {
            header: "Address",
            cell: ({ getValue }) => {
              const address = getValue();
              return address ? <AddressText address={address} /> : null;
            },
          }),
        columnHelper.display({
          id: "actions",
          cell: ({ row }) => (
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => {
                  setRecipients((prev) =>
                    prev.filter((r) => r !== row.original),
                  );
                }}
              >
                <TrashIcon className="size-4 text-muted-foreground" />
              </button>
            </div>
          ),
        }),
      ].filter(Boolean) as ColumnDef<
        GiftRecipientPayload | IntegrationRecipient
      >[],
    [isDigitalGift],
  );

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: false,
    initialState: {
      pagination: {
        pageSize: 100,
      },
    },
    data: recipients,
    columns,
  });

  const actions = (
    <div className="flex items-center justify-center gap-1">
      {peopleIntegrations.map((i) => (
        <GiftRecipientsFromIntegrationDialog
          key={i.type}
          integration={i}
          onSubmit={(recipients) => {
            setRecipients((prev) => [...prev, ...recipients]);
          }}
        />
      ))}

      <Dialog open={uploading} onOpenChange={setUploading}>
        <DialogTrigger asChild>
          <Button size="xs" variant="outline">
            <UploadIcon className="mr-2 size-4" />
            Upload
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload Spreadsheet</DialogTitle>
            <DialogDescription>
              Using <Link href="/gift-recipients.csv">our template</Link>, you
              can upload a CSV file of your recipients below.
            </DialogDescription>
          </DialogHeader>
          <GiftRecipientUploadForm
            onSubmit={(recipients) => {
              setRecipients((prev) => [...prev, ...recipients]);
              setUploading(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Sheet open={adding} onOpenChange={setAdding}>
        <SheetTrigger asChild>
          <Button size="xs" variant="outline">
            <PlusIcon className="mr-2 size-4" />
            Add
          </Button>
        </SheetTrigger>
        <SheetContent className="sm:max-w-3xl">
          <SheetHeader className="mb-6">
            <SheetTitle>Add Recipient Manually</SheetTitle>
          </SheetHeader>

          <GiftRecipientForm
            defaultValues={{}}
            onSubmit={(recipient) => {
              setRecipients((prev) => [...prev, recipient]);
              setAdding(false);
              return Promise.resolve();
            }}
            isDigitalGift={isDigitalGift}
          >
            <SheetFooter className="mt-6">
              <Button type="submit">Submit</Button>
            </SheetFooter>
          </GiftRecipientForm>
        </SheetContent>
      </Sheet>
    </div>
  );

  return (
    <WizardStep>
      <Toolbar>
        <Title>Who are the recipients of this gift?</Title>
        <Description>
          Load recipients from an integration, upload a CSV file, or add each
          recipient manually.
        </Description>
      </Toolbar>

      {recipients.length === 0 ? (
        <Card className="p-5">
          <EmptyState
            Icon={UsersIcon}
            title="No Recpients Yet!"
            description="Choose an option below to get started with adding gift recipients."
          >
            {actions}
          </EmptyState>
        </Card>
      ) : (
        <DataTable
          table={table}
          isLoading={false}
          header={<div className="flex justify-end">{actions}</div>}
        />
      )}

      <WizardSubmitButton
        type="button"
        onClick={() => onSubmit({ recipients })}
      >
        Next
      </WizardSubmitButton>
    </WizardStep>
  );
}
