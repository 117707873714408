import { ChevronDownIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Checkbox } from "@/components/ui/checkbox";

interface SelectOption<T> {
  value: T;
  label: string;
}

export interface FilterDropdown<T> {
  id: string;
  name: string;
  options: SelectOption<T>[];
}

function getKeyForSelectOption(option: SelectOption<unknown>) {
  return typeof option.value === "object"
    ? JSON.stringify(option.value)
    : String(option.value);
}

export default function FilterDropdown<T>({
  id,
  name,
  options,
  values,
  onChange,
}: {
  id: string;
  name: string;
  options: SelectOption<T>[];
  values: T[];
  onChange: (newValues: T[]) => void;
}) {
  const handleChange = (value: T) => (checked: boolean) => {
    if (checked) {
      onChange([...values, value]);
    } else {
      onChange(values.filter((v) => v !== value));
    }
  };

  return (
    <Popover>
      <PopoverTrigger className="group inline-flex items-center justify-center text-sm font-medium text-slate-700 hover:text-slate-900 focus:outline-none">
        <span>{name}</span>
        {values.length > 0 && (
          <span className="ml-1.5 rounded bg-slate-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-slate-700">
            {values.length}
          </span>
        )}
        <ChevronDownIcon
          className="-mr-1 ml-1 h-4 w-4 flex-shrink-0 text-slate-400 group-hover:text-slate-500"
          aria-hidden="true"
        />
      </PopoverTrigger>

      <PopoverContent align="end">
        <form className="space-y-4">
          {options.map((option, optionIdx) => (
            <div
              key={getKeyForSelectOption(option)}
              className="flex items-center"
            >
              <Checkbox
                id={`filter-${id}-${optionIdx}`}
                name={`${id}[]`}
                onCheckedChange={handleChange(option.value)}
                checked={values.includes(option.value)}
              />
              <label
                htmlFor={`filter-${id}-${optionIdx}`}
                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium"
              >
                {option.label}
              </label>
            </div>
          ))}
        </form>
      </PopoverContent>
    </Popover>
  );
}
