import {
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomerTeam } from "avail-types";
import { useMemo } from "react";
import { DataTable } from "@/components/ui/data-table";
import { formatDate } from "@/lib/dates";
import TeamDeleteButton from "@/components/gifting/teams/TeamDeleteButton";
import { curr } from "@/lib/curr";
import { ArrowRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { TEAMS_KEY } from "@/api/teams";

const columnHelper = createColumnHelper<CustomerTeam>();

export default function TeamsTable() {
  const tableState = useManualTableState([
    {
      id: "name",
      desc: false,
    },
  ]);
  const teamsQuery = useManualTableQuery<CustomerTeam>(
    TEAMS_KEY,
    "/teams",
    tableState,
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ getValue, row }) => (
          <Link
            className="font-medium hover:underline"
            to={`/gifting-budgets/${row.original.id}`}
          >
            {getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("budget", {
        header: "Budget",
        cell: ({ getValue }) => curr(getValue()),
      }),
      columnHelper.accessor("interval", {
        header: "Interval",
      }),
      columnHelper.accessor("is_default", {
        header: "Default",
        cell: ({ getValue }) => getValue() && <Badge>Default</Badge>,
      }),
      columnHelper.accessor("current_period", {
        header: "Current Period",
        cell: ({ getValue }) => {
          const [start, end] = getValue();
          return `${formatDate(start)} - ${formatDate(end)}`;
        },
      }),
      columnHelper.accessor("created_at", {
        header: "Created At",
        cell: ({ getValue }) => formatDate(getValue()),
      }),
      columnHelper.display({
        id: "actions",
        header: "",
        cell: ({ row }) => (
          <div className="flex items-center gap-3">
            <TeamDeleteButton team={row.original} />
            <Link
              className="text-muted-foreground hover:text-black"
              to={`/gifting-budgets/${row.original.id}`}
            >
              <ArrowRightIcon className="size-4" />
            </Link>
          </div>
        ),
      }),
    ],
    [],
  );
  const table = useManualTable(tableState, teamsQuery, columns);
  return <DataTable table={table} isLoading={teamsQuery.isLoading} />;
}
