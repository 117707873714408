import { Card, CardDescription, CardTitle } from "@/components/ui/card";
import NotificationsList from "@/components/notifications/NotificationsList";
import { Loader } from "lucide-react";
import { useNotifications } from "@/api/notifications";
import { HTMLAttributes } from "react";
import SlackLogo from "@/assets/slack.svg?react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useGetApiUrl } from "@/lib/auth";

export default function ActivityCard({
  onClose,
  ...props
}: HTMLAttributes<HTMLDivElement> & { onClose?: () => void }) {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useNotifications();
  const getApiUrl = useGetApiUrl();

  return (
    <Card {...props}>
      <div className="flex items-start justify-between p-5">
        <div className="flex flex-col space-y-1.5">
          <CardTitle>Activity</CardTitle>
          <CardDescription>Here's what has been going on</CardDescription>
        </div>
        <div className="flex items-center gap-5">
          <a
            href={getApiUrl("slack/redirect")}
            title="Get your notifications in Slack"
          >
            <SlackLogo className="size-6" />
          </a>
          {onClose && (
            <button
              type="button"
              onClick={onClose}
              className="text-muted-foreground hover:text-inherit"
            >
              <XMarkIcon className="size-5 " />
            </button>
          )}
        </div>
      </div>
      {data && (
        <NotificationsList notifications={data.pages.flatMap((p) => p.data)} />
      )}
      <div className="px-5 py-2">
        {(isFetchingNextPage || isLoading) && (
          <div className="py-2">
            <Loader className="size-5 animate-spin" />
          </div>
        )}
        {!isFetchingNextPage && hasNextPage && (
          <button
            type="button"
            onClick={() => fetchNextPage()}
            className="py-2 text-primary"
          >
            Load Older
          </button>
        )}
      </div>
    </Card>
  );
}
