import { useTitle } from "@/lib/title";
import GiftingProductCreateForm from "@/components/gifting/products/GiftingProductCreateForm";
import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";

export default function GiftingProductCreate() {
  useTitle("Create Gifting Product");

  return (
    <div className="flex-1 space-y-6">
      <Toolbar>
        <Title>Add Gifting Product</Title>
      </Toolbar>
      <GiftingProductCreateForm defaultValues={{ images: [] }} />
    </div>
  );
}
