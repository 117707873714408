import { useMemberships } from "@/api/membership";
import { CustomerTeam } from "avail-types";
import TeamMemberRow from "@/components/account/TeamMemberRow";
import { Loader, PlusIcon } from "lucide-react";
import EmptyState from "@/components/ui/empty-state";
import { Button } from "@/components/ui/button";
import { CardFooter } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import AddMemberToTeamForm from "@/components/gifting/teams/AddMemberToTeamForm";
import { useState } from "react";
import RemoveMemberFromTeamButton from "@/components/gifting/teams/RemoveMemberFromTeamButton";
import { Badge } from "@/components/ui/badge";
import { curr } from "@/lib/curr";

export default function TeamMembers({ team }: { team: CustomerTeam }) {
  const [adding, setAdding] = useState(false);
  const { data = [], isLoading } = useMemberships({
    "filter[team_id]": String(team.id),
    with: "team",
  });

  return (
    <div>
      {isLoading ? (
        <Loader className="size-6 animate-spin" />
      ) : data.length === 0 ? (
        <EmptyState title="There are no members of this budget" />
      ) : (
        <ul className="divide-y divide-slate-200">
          {data.map((m) => (
            <TeamMemberRow
              key={m.id}
              membership={m}
              canInvite={false}
              canLeave={false}
              actions={
                <>
                  <Badge variant="green">{curr(m.gifting_balance)}</Badge>
                  <RemoveMemberFromTeamButton member={m} />
                </>
              }
            />
          ))}
        </ul>
      )}

      <CardFooter className="pt-4">
        <Dialog open={adding} onOpenChange={setAdding}>
          <DialogTrigger asChild>
            <Button variant="outline" size="xs">
              <PlusIcon className="mr-2 size-4" /> Add Member
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add Member to Budget</DialogTitle>
            </DialogHeader>
            <AddMemberToTeamForm
              team={team}
              onSuccess={() => setAdding(false)}
            />
          </DialogContent>
        </Dialog>
      </CardFooter>
    </div>
  );
}
