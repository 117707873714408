import SearchInput from "@/components/ui/search-input";
import ProductCard, {
  ProductCardSkeleton,
} from "@/components/products/ProductCard";
import ProductFilter from "@/components/shop/ProductFilter";
import startCase from "lodash/startCase";
import { Popover } from "@headlessui/react";
import Pagination from "@/components/ui/pagination";
import { Link, useLocation } from "react-router-dom";
import pickBy from "lodash/pickBy";
import { useSearchProducts } from "@/api/products";
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

export default function ShopProducts() {
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 0));
  const [query, setQuery] = useQueryParam(
    "query",
    withDefault(StringParam, ""),
  );
  const [filters, setFilters] = useQueryParam(
    "filters",
    withDefault(JsonParam, {}),
  );
  const { search } = useLocation();
  const { data, isLoading } = useSearchProducts({
    query,
    filters,
    page,
  });

  const products = data?.data || [];
  const facets = data?.facets || {};

  const handleQuery = (query: string) => {
    setPage(0);
    setQuery(query);
  };

  const handleChange = (keyName: string, option: string, checked: boolean) => {
    const existing = (filters[keyName] || []) as string[];
    setFilters(
      pickBy(
        {
          ...filters,
          [keyName]: checked
            ? [...existing, option]
            : existing.filter((o) => o !== option),
        },
        (v) => v.length > 0,
      ),
    );
  };
  return (
    <div>
      <div className="mb-6 space-y-4 lg:flex lg:justify-between lg:space-y-0">
        <SearchInput onChange={handleQuery} />

        <Popover.Group className="flex space-x-8">
          {Object.entries(facets).map(([keyName, options]) => (
            <ProductFilter
              key={keyName}
              keyName={keyName}
              label={startCase(keyName)}
              options={options}
              selected={filters[keyName] || []}
              handleSelect={handleChange}
            />
          ))}
        </Popover.Group>
      </div>

      <div className="mb-4 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {products.map((p) => (
          <Link
            key={p.id}
            to={`/shop/products/${p.slug}${search}`}
            className="focus:outline-none"
          >
            <ProductCard product={p} />
          </Link>
        ))}
        {isLoading &&
          Array.from({ length: 10 }).map((_, i) => (
            <ProductCardSkeleton key={i} />
          ))}
      </div>

      {data && (
        <Pagination
          pageIndex={page}
          totalPages={data.meta.last_page}
          canGetPrevious={page > 0}
          onPrevious={() => setPage(page - 1)}
          canGetNextPage={data.meta.current_page < data.meta.last_page}
          onNext={() => setPage(page + 1)}
        />
      )}
    </div>
  );
}
