import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import useDebounce from "@/hooks/useDebounce";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { imageUrl } from "@/lib/image";
import { useGetProductById, useSearchProducts } from "@/api/products";
import { INPUT_CLASSES } from "@/components/ui/input";

export default function ProductSelect({
  value,
  onChange,
  params = {},
}: {
  value: number | null;
  onChange: (value: number) => void;
  params?: Record<string, string | boolean>;
}) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);

  const { data, isLoading } = useSearchProducts({
    query: debouncedQuery,
    params,
  });
  const { data: selectedProduct } = useGetProductById(value);
  const products = data ? data.data : [];

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", INPUT_CLASSES)}
        >
          <span className="truncate">
            {value ? selectedProduct?.name : "Find Product"}
          </span>

          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search Products..."
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>
            {isLoading ? "Loading..." : "No Product found."}
          </CommandEmpty>
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {products.map((product) => (
              <CommandItem
                key={product.id}
                value={String(product.id)}
                onSelect={(currentValue) => {
                  onChange(Number(currentValue));
                  setOpen(false);
                }}
              >
                <Avatar className="mr-2">
                  <AvatarImage
                    src={imageUrl(product.image, {
                      h: 100,
                      w: 100,
                      fit: "contain",
                    })}
                  />
                </Avatar>
                {product.name}
                <Check
                  className={cn(
                    "ml-2 size-4",
                    value === product.id ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
