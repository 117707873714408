import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { handleLaravelErrors } from "@/lib/form";
import { CustomerSku } from "avail-types";
import { curr } from "@/lib/curr";
import SkuItem from "@/components/inventory/SkuItem";
import CurrencyInput from "@/components/ui/currency-input";
import { useUpdateVariant } from "@/api/variants";

const formSchema = z.object({
  alert_level: z.coerce.number().nullish(),
  par_level: z.coerce.number().nullish(),
  value: z.coerce.number().nullish(),
});

export default function EditSkuForm({
  sku,
  onSuccess,
}: {
  sku: CustomerSku;
  onSuccess: () => void;
}) {
  const updateRequest = useUpdateVariant(sku.id);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      alert_level: sku.alert_level,
      par_level: sku.par_level,
      value: sku.value,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    return updateRequest
      .mutateAsync(values)
      .then(() => {
        onSuccess();
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <SkuItem sku={sku} />

        <FormField
          control={form.control}
          name="alert_level"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Min Level</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  step={1}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              </FormControl>
              <FormDescription>
                This is the minimum quantity of this SKU that should be in
                stock. {typeof field.value}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="par_level"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Par Level</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  step={1}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              </FormControl>
              <FormDescription>
                When doing a re-order, this is the desired stock level to get
                back to.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Unit Value</FormLabel>
              <FormControl>
                <CurrencyInput
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              </FormControl>
              <FormDescription>
                This is used to calculate the remaining inventory value and will
                override the average purchase price.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {sku.average_purchase_price != null && (
          <div className="mt-2 text-sm text-slate-900">
            Average Purchase Price: <b>{curr(sku.average_purchase_price)}</b>
          </div>
        )}

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Save
        </Button>
      </form>
    </Form>
  );
}
