import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CurrencyInput from "@/components/ui/currency-input";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import startCase from "lodash/startCase";
import { SyntheticEvent, useRef, useState } from "react";
import { CartProduct } from "avail-types";
import { useQueryClient } from "@tanstack/react-query";
import { getProductQueryKey } from "@/api/products";
import { useProject } from "@/context/ProjectContext";
import { Checkbox } from "@/components/ui/checkbox";
import NativeSelect from "@/components/ui/native-select";

export default function VariantTable() {
  const form = useFormContext();
  const [bulkEditing, setBulkEditing] = useState<"price" | "cost">();
  const bulkEditInputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();

  const productType = form.watch("product_type");
  const productId = form.watch("product_id");
  const inventoryProductId = form.watch("inventory_product_id");
  const useDynamicPricing = form.watch("use_dynamic_pricing");
  const firstVariant = form.watch("variants.0");

  const isInventory = useProject().shipping_mode === "inventory";
  const askAboutNewSku = isInventory && Boolean(inventoryProductId);

  const variantsField = useFieldArray({
    control: form.control,
    name: "variants",
  });

  const onSetVariantField = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (bulkEditing) {
      variantsField.fields.forEach((_, i) => {
        form.setValue(
          `variants.${i}.${bulkEditing}`,
          Number(bulkEditInputRef.current?.value),
        );
      });
      setBulkEditing(undefined);
    }
  };

  const getInventoryForVariantIndex = (index: number) => {
    const variantId = form.watch(`variants.${index}.variant_id`);
    const product = queryClient.getQueryData<CartProduct>(
      getProductQueryKey(productId),
    );

    if (product) {
      return product.variants.find((v) => v.id === variantId)
        ?.vendor_inventory_qty;
    }

    return undefined;
  };

  const getSizesForVariantIndex = (index: number) => {
    if (!askAboutNewSku) {
      return null;
    }
    const createNewSku = form.watch(`variants.${index}.create_sku`);
    if (createNewSku) {
      return null;
    }

    const product = queryClient.getQueryData<CartProduct>(
      getProductQueryKey(inventoryProductId!),
    );

    if (product) {
      return product.variants.map((v) => v.size);
    }

    return null;
  };

  return (
    <div className="-mx-6 [&_td:first-child]:pl-5 [&_td:last-child]:pr-4 [&_td]:px-2 [&_td]:py-2 [&_th:first-child]:pl-6 [&_th:last-child]:pr-4 [&_th]:px-3">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Size</TableHead>
            <TableHead>
              <button type="button" onClick={() => setBulkEditing("price")}>
                Price
              </button>
            </TableHead>
            {productType === "custom" ? (
              <TableHead>
                <button type="button" onClick={() => setBulkEditing("cost")}>
                  Cost
                </button>
              </TableHead>
            ) : (
              <TableHead>Cost</TableHead>
            )}
            {askAboutNewSku && <TableHead>New SKU?</TableHead>}
            {productType === "database" && productId && (
              <TableHead>Inv</TableHead>
            )}
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {variantsField.fields.map((f, i) => (
            <TableRow key={f.id}>
              <TableCell>
                <FormField
                  control={form.control}
                  name={`variants.${i}.size`}
                  render={({ field }) => {
                    const sizes = getSizesForVariantIndex(i);
                    return (
                      <FormItem>
                        <FormControl>
                          {sizes ? (
                            <NativeSelect
                              {...field}
                              className="h-9 w-20 text-sm"
                              disabled={productType === "database"}
                            >
                              <option value="">--</option>
                              {sizes.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                            </NativeSelect>
                          ) : (
                            <Input
                              {...field}
                              className="h-9 text-sm"
                              disabled={productType === "database"}
                            />
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </TableCell>
              <TableCell>
                <FormField
                  control={form.control}
                  name={`variants.${i}.price`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CurrencyInput
                          {...field}
                          className="h-9 text-sm"
                          disabled={useDynamicPricing}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TableCell>
              <TableCell>
                <FormField
                  control={form.control}
                  name={`variants.${i}.cost`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CurrencyInput
                          {...field}
                          className="h-9 text-sm"
                          disabled={productType === "database"}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TableCell>
              {askAboutNewSku && (
                <TableCell className="!pr-2 text-center">
                  <FormField
                    control={form.control}
                    name={`variants.${i}.create_sku`}
                    render={({ field }) => (
                      <FormItem>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormItem>
                    )}
                  />
                </TableCell>
              )}
              {productType === "database" && productId && (
                <TableCell>{getInventoryForVariantIndex(i)}</TableCell>
              )}
              <TableCell>
                <button type="button" onClick={() => variantsField.remove(i)}>
                  <X className="size-4 text-muted-foreground" />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {productType === "custom" && (
        <div className="px-6">
          <Button
            type="button"
            variant="ghost"
            onClick={() =>
              variantsField.append({
                size: "OS",
                price: 0,
                cost: 0,
              })
            }
          >
            Add Variant
          </Button>
        </div>
      )}

      <Dialog
        open={bulkEditing !== undefined}
        onOpenChange={() => setBulkEditing(undefined)}
      >
        <DialogContent>
          <form onSubmit={onSetVariantField} className="space-y-4">
            <DialogTitle>Bulk Edit: {startCase(bulkEditing)}</DialogTitle>
            <DialogDescription>
              Enter a value to set the {bulkEditing} of all variants.
            </DialogDescription>
            <CurrencyInput
              ref={bulkEditInputRef}
              defaultValue={
                bulkEditing && firstVariant
                  ? String(firstVariant[bulkEditing])
                  : undefined
              }
            />
            <DialogFooter>
              <Button type="submit">Submit</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
