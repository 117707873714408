import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import { Button } from "@/components/ui/button";
import { useTitle } from "@/lib/title";
import { PlusCircleIcon } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import TeamForm from "@/components/gifting/teams/TeamForm";
import { useState } from "react";
import { useCreateTeam } from "@/api/teams";
import TeamsTable from "@/components/gifting/teams/TeamsTable";
import StripeGate from "@/components/account/StripeGate";

export default function GiftingBudgets() {
  useTitle("Gifting Teams");

  const [creating, setCreating] = useState(false);
  const createRequest = useCreateTeam();

  const actions = (
    <Button onClick={() => setCreating(true)}>
      <PlusCircleIcon className="mr-2 size-4" />
      New Budget
    </Button>
  );

  return (
    <StripeGate feature="budget-management" featureTitle="Budget Management">
      <div className="flex-1 space-y-6">
        <Toolbar actions={actions}>
          <Title>Gifting Budgets</Title>
        </Toolbar>

        <TeamsTable />

        <Sheet open={creating} onOpenChange={setCreating}>
          <SheetContent className="sm:max-w-xl">
            <SheetHeader className="mb-4">
              <SheetTitle>New Budget</SheetTitle>
            </SheetHeader>
            <TeamForm
              defaultValues={{}}
              onSubmit={(payload) =>
                createRequest.mutateAsync(payload).then(() => {
                  setCreating(false);
                })
              }
            />
          </SheetContent>
        </Sheet>
      </div>
    </StripeGate>
  );
}
