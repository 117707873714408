import {
  useProjectOptions,
  useRestoreProjectOption,
} from "@/api/project-options";
import { Button } from "@/components/ui/button";
import { Tags, Trash } from "lucide-react";
import pluralize from "pluralize";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { imageUrl } from "@/lib/image";

export default function TrashedOptions() {
  const { data } = useProjectOptions();
  const restoreOption = useRestoreProjectOption();
  const trashed = data?.trashed || [];

  if (trashed.length === 0) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="secondary">
          <Trash className="mr-2 size-5" />
          {pluralize("option", trashed.length, true)} in trash
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Trashed Options</DialogTitle>
        </DialogHeader>
        <ul>
          {trashed.map((option) => (
            <li key={option.id} className="group flex items-center gap-2 py-2">
              <Avatar className="mr-2 size-12 rounded-md border p-1">
                <AvatarImage
                  src={imageUrl(option.images[0], {
                    h: 200,
                    w: 200,
                    fit: "contain",
                  })}
                />
                <AvatarFallback>
                  <Tags className="size-6" />
                </AvatarFallback>
              </Avatar>
              <div className="flex-grow text-sm leading-snug">
                {option.name}
              </div>
              <Button
                variant="link"
                disabled={restoreOption.isLoading}
                onClick={() => restoreOption.mutate(option.id)}
                className="invisible group-hover:visible"
              >
                Restore
              </Button>
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
}
