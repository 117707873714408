import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import { Button } from "@/components/ui/button";
import microsoft from "@/assets/microsoft.svg";
import google from "@/assets/google.svg";
import { useMsal } from "@azure/msal-react";
import { useAfterLogin } from "@/lib/auth";
import { track } from "@/lib/analytics";
import { useMutation } from "@tanstack/react-query";
import { AuthenticationResult } from "@azure/msal-browser";

export default function SocialLogins({
  verb,
  onError,
}: {
  verb: string;
  onError: (error: string) => void;
}) {
  const afterLogin = useAfterLogin();
  const { instance } = useMsal();

  const getShouldRememberMe = (): boolean => {
    const rememberMeCheckbox = document.getElementById(
      "remember-me-checkbox",
    ) as HTMLInputElement | undefined;
    return rememberMeCheckbox?.checked || false;
  };
  const devLoginRequest = useMutation(() => {
    return axios.post("/oauth/dev").then(afterLogin);
  });

  const googleLoginRequest = useMutation(
    (
      tokenResponse: Omit<
        TokenResponse,
        "error" | "error_uri" | "error_description"
      >,
    ) =>
      axios
        .post(`/oauth/google`, {
          ...tokenResponse,
          remember: getShouldRememberMe(),
        })
        .then(() => {
          track("Logged In", {
            provider: "Google",
          });
          return afterLogin();
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            onError(error.response?.data.message || "An error occurred");
          }
        }),
  );

  const microsoftLoginRequest = useMutation((response: AuthenticationResult) =>
    axios
      .post("/oauth/microsoft", {
        access_token: response.accessToken,
        remember: getShouldRememberMe(),
      })
      .then(() => {
        track("Logged In", {
          provider: "Microsoft",
        });
        return afterLogin();
      }),
  );

  const googleLogin = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    onSuccess: (tokenResponse) => {
      googleLoginRequest.mutate(tokenResponse);
    },
  });

  if (import.meta.env.DEV) {
    return (
      <Button
        variant="outline"
        className="w-full rounded-lg"
        onClick={() => devLoginRequest.mutate()}
        isLoading={devLoginRequest.isLoading}
      >
        Login as Developer
      </Button>
    );
  }

  return (
    <div className="space-y-2">
      <Button
        variant="outline"
        className="w-full justify-between rounded-lg"
        onClick={() => googleLogin()}
        isLoading={googleLoginRequest.isLoading}
      >
        <img src={google} className="w-6" />
        <span>{verb} with Google</span>
        <div />
      </Button>
      <Button
        variant="outline"
        className="w-full justify-between rounded-lg"
        onClick={() => {
          instance
            .loginPopup({
              redirectUri: window.location.href.split("?")[0],
              scopes: ["User.Read", "openid", "profile", "email"],
            })
            .then((response) => {
              microsoftLoginRequest.mutate(response);
            });
        }}
        isLoading={microsoftLoginRequest.isLoading}
      >
        <img src={microsoft} className="w-6" />
        <span>{verb} with Microsoft</span>
        <div />
      </Button>
    </div>
  );
}
