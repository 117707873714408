import { useGetGiftQueues, useProcessGiftQueue } from "@/api/gifts";
import { Alert } from "@/components/ui/alert";
import pluralize from "pluralize";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";

export default function GiftQueues() {
  const { data: queues = [] } = useGetGiftQueues();
  const processRequest = useProcessGiftQueue();

  return (
    <div className="space-y-2">
      {queues.map((queue) => (
        <Alert variant="info" key={queue.queue}>
          <p>
            There {pluralize("is", queue.gifts_count)}{" "}
            {pluralize("gift", queue.gifts_count, true)} in the{" "}
            <b className="font-semibold">{queue.queue}</b> queue.
          </p>
          <Dialog>
            <DialogTrigger asChild>
              <Button size="xs" className="absolute right-4 top-2.5">
                Process
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Process Queue</DialogTitle>
              </DialogHeader>
              <p>Are you sure you want to process this queue?</p>
              <DialogFooter>
                <DialogClose asChild>
                  <Button variant="outline">Cancel</Button>
                </DialogClose>
                <Button
                  onClick={() => processRequest.mutate(queue.queue)}
                  isLoading={processRequest.isLoading}
                >
                  Process
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </Alert>
      ))}
    </div>
  );
}
