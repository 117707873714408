import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useTitle } from "@/lib/title";
import { PlusCircleIcon } from "lucide-react";
import GiftingProductsGrid from "@/components/gifting/products/GiftingProductsGrid";
import Gate from "@/components/roles/Gate";

export default function GiftingProducts() {
  useTitle("Gifting Products");

  const actions = (
    <Gate role={["avail", "avail_incognito", "admin"]}>
      <Button asChild>
        <Link to="/gifting-products/create">
          <PlusCircleIcon className="mr-2 size-4" />
          Add Product
        </Link>
      </Button>
    </Gate>
  );

  return (
    <div className="flex-1 space-y-6">
      <Toolbar actions={actions}>
        <Title>Gifting Catalog</Title>
      </Toolbar>

      <GiftingProductsGrid />
    </div>
  );
}
