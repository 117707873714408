import { cn } from "@/lib/utils";
import SidenavCount from "@/components/navigation/SidenavCount";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

interface SidenavItemProps extends HTMLAttributes<HTMLDivElement> {
  Icon?: React.ComponentType<{ className?: string }>;
  count?: number;
  isActive?: boolean;
  activeClassName?: string;
  hoverClassName?: string;
  isCollapsed?: boolean;
  isChild?: boolean;
  label: string;
}

const SidenavItem = forwardRef(
  (
    {
      className,
      label,
      Icon,
      count,
      isActive,
      activeClassName = "bg-drawer-foreground/20",
      hoverClassName = "hover:bg-drawer-foreground/10",
      isCollapsed,
      isChild,
      children,
      ...props
    }: SidenavItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <span
        ref={ref}
        className={cn(
          "group flex items-center gap-x-3 rounded-full font-semibold leading-6",
          isChild ? "h-8 px-3 py-2 text-xs" : "h-10 px-4 py-3 text-sm",
          isActive ? activeClassName : hoverClassName,
          className,
        )}
        title={label}
        {...props}
      >
        {Icon && (
          <Icon className="size-6 shrink-0 -translate-x-1" aria-hidden="true" />
        )}
        {!isCollapsed && label}
        {!isCollapsed && children}
        {!isCollapsed && count != null ? (
          <SidenavCount>{count}</SidenavCount>
        ) : null}
      </span>
    );
  },
);

SidenavItem.displayName = "SidenavItem";

export default SidenavItem;
