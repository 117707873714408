import { Button } from "@/components/ui/button";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils";
import { ArrowRight } from "lucide-react";
import { useHideBeacon } from "@/lib/beacon";

export default function WizardSubmitButton({
  children,
  className,
  type = "submit",
  forceMobile,
  ...props
}: ComponentPropsWithoutRef<typeof Button> & { forceMobile?: boolean }) {
  useHideBeacon();

  return (
    <Button
      type={type}
      className={cn(
        "fixed bottom-10 right-4 z-10 shadow-md md:bottom-6 md:right-6 lg:right-8",
        !forceMobile && "sm:static sm:shadow-none",
        className,
      )}
      {...props}
    >
      {children}
      <ArrowRight className="ml-2 size-4" />
    </Button>
  );
}
