import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSection,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { handleLaravelErrors } from "@/lib/form";
import VendorSelect from "@/components/selects/VendorSelect";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ProductSelect from "@/components/selects/ProductSelect";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import omit from "lodash/omit";
import startCase from "lodash/startCase";
import { DECORATION_METHODS, OPTION_TAGS } from "@/constants/project";
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import ProjectOptionImagesField from "@/components/projects/ProjectOptionImagesField";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import LayoutBuilderDialog from "@/components/gifting/products/designLayouts/LayoutBuilderDialog";
import FeeTable from "@/components/projects/optionForm/FeeTable";
import VariantTable from "@/components/projects/optionForm/VariantTable";
import DynamicPricingSwitch from "@/components/projects/optionForm/DynamicPricingSwitch";
import { getProductQueryKey, useGetProductById } from "@/api/products";
import { Alert } from "@/components/ui/alert";
import { findProductDecoration } from "@/lib/products";
import { useQueryClient } from "@tanstack/react-query";
import {
  CartProduct,
  ProjectOptionPayload,
  projectOptionPayloadSchema,
} from "avail-types";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { HelpCircleIcon, Star } from "lucide-react";
import SageSearchDialog from "@/components/projects/SageSearchDialog";
import SearchUserOptionsDialog from "@/components/projects/SearchUserOptionsDialog";
import { Checkbox } from "@/components/ui/checkbox";
import { useProject } from "@/context/ProjectContext";
import { z } from "zod";
import { FormErrors } from "@/components/ui/form-errors";

const formSchema = projectOptionPayloadSchema.and(
  z.object({
    product_type: z.union([z.literal("custom"), z.literal("database")]),
  }),
);

type FormShape = z.infer<typeof formSchema>;

function ProjectOptionForm(
  {
    onSubmit,
    defaultValues,
  }: {
    onSubmit: (values: ProjectOptionPayload) => Promise<void>;
    defaultValues: Partial<
      ProjectOptionPayload & { product_type: "database" | "custom" }
    >;
  },
  ref: ForwardedRef<{ isDirty: boolean }>,
) {
  const form = useForm<FormShape>({
    resolver: zodResolver(
      z.discriminatedUnion("product_type", [
        projectOptionPayloadSchema
          .omit({ number: true, vendor_id: true })
          .extend({
            product_type: z.literal("custom"),
            number: z.string(),
            vendor_id: z.number(),
          }),
        projectOptionPayloadSchema.omit({ product_id: true }).extend({
          product_type: z.literal("database"),
          product_id: z.number(),
        }),
      ]),
    ),
    defaultValues: {
      product_type: defaultValues.product_id ? "database" : "custom",
      ...defaultValues,
    },
  });

  const project = useProject();
  const productType = form.watch("product_type");
  const productId = form.watch(`product_id`);
  const layoutConfig = form.watch("layout_config");
  const name = form.watch("name");
  const orderType = form.watch("type");
  const useDynamicPricing = form.watch("use_dynamic_pricing");
  const locations = form.watch("layout_config.locations");
  const createInventoryProduct = form.watch("create_inventory_product");

  const queryClient = useQueryClient();
  const { data: product } = useGetProductById(productId);

  const canUseDynamicPricing = Boolean(
    orderType === "screenprint" ||
      orderType === "embroidery" ||
      (orderType === "ad_specialty" &&
        findProductDecoration(product, locations?.[0])),
  );
  const isUsingDynamicAdSpecPricing =
    canUseDynamicPricing && orderType === "ad_specialty" && useDynamicPricing;

  useImperativeHandle(ref, () => ({
    isDirty: form.formState.isDirty,
  }));

  useEffect(() => {
    if (orderType === "blanks") {
      form.setValue("layout_config", null);
    }
    if (!canUseDynamicPricing) {
      form.setValue("use_dynamic_pricing", false);
    }
  }, [form, orderType, canUseDynamicPricing]);

  useEffect(() => {
    if (createInventoryProduct) {
      form.setValue("inventory_product_id", null);
    }
  }, [form, createInventoryProduct]);

  const handleSubmit = (values: FormShape) => {
    return onSubmit(values).catch(handleLaravelErrors(form));
  };

  const handleProductType = (value: FormShape["product_type"]) => {
    form.setValue("product_type", value);
    if (value === "custom") {
      form.unregister("product_id");
      form.setValue("variants", [{ size: "OS", price: 0, cost: 0 }]);
    } else {
      form.unregister("vendor_id");
      form.unregister("number");
      form.unregister("color");
      form.setValue("variants", []);
    }
  };

  const handleProductChange = (id: number) => {
    form.setValue("product_id", id);

    const product = queryClient.getQueryData<CartProduct>(
      getProductQueryKey(id),
    );

    if (!product) {
      console.warn(`Product #${id} not found in cache`);
      return;
    }

    form.setValue("name", product.name);
    form.setValue("description", product.description || "");
    form.setValue("type", product.decoration_method);
    form.setValue(
      "images",
      product.images.map((i) => i.url),
    );
    form.setValue(
      "variants",
      product.variants.map((v) => ({
        variant_id: v.id,
        size: v.size,
        price: v.price,
        cost: v.cost!,
      })),
    );
    if (product.min_qty) {
      form.setValue("min_qty", product.min_qty);
    }
  };

  const setFormWithData = (payload: ProjectOptionPayload) => {
    form.reset({
      ...form.getValues(),
      ...omitBy(omit(payload, ["project_item_id"]), isNil),
    } as ProjectOptionPayload);
  };

  const handleFavoriting = (oldValue: boolean) => {
    form.setValue("is_pinned", !oldValue);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="divide-y">
          <div>
            <FormField
              control={form.control}
              name="nickname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Nickname
                    <SimpleTooltip tooltip="This is an optional field that will be used as the nickname when the ERP order is created. It's purely meant to help you better identify this option and won't be seen by the customer.">
                      <HelpCircleIcon className="ml-1.5 size-4" />
                    </SimpleTooltip>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="e.g. Black Hoodies"
                      value={field.value || ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="is_pinned"
              render={({ field }) => (
                <FormItem>
                  <button
                    type="button"
                    onClick={() => handleFavoriting(field.value || false)}
                    className="absolute right-2 top-10"
                  >
                    <SimpleTooltip tooltip="Add this to your favorites for next time">
                      <Star
                        color={field.value ? "#f59e0b" : "#d1d5db"}
                        fill={field.value ? "#f59e0b" : "none"}
                        size={32}
                      />
                    </SimpleTooltip>
                  </button>
                </FormItem>
              )}
            />

            <FormSection
              title="Product Details"
              action={
                <>
                  <SageSearchDialog
                    projectItemId={form.watch("project_item_id") as number}
                    onSelect={setFormWithData}
                  />
                  <SearchUserOptionsDialog onSelect={setFormWithData} />
                </>
              }
            >
              <Tabs
                value={productType}
                onValueChange={(type) =>
                  handleProductType(type as FormShape["product_type"])
                }
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="database">Database</TabsTrigger>
                  <TabsTrigger value="custom">Custom</TabsTrigger>
                </TabsList>
                <TabsContent value="database" className="space-y-4">
                  <FormField
                    control={form.control}
                    name="product_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Database Product</FormLabel>
                        <ProductSelect
                          value={field.value || null}
                          onChange={handleProductChange}
                          params={{
                            all: true,
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="enable_color_options"
                    render={({ field }) => (
                      <div className="flex items-center space-x-2">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                        <Label
                          variant="checkbox"
                          htmlFor="existing-layout-switch"
                        >
                          Present other color options
                        </Label>
                      </div>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TabsContent>
                <TabsContent value="custom" className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="number"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Number</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ""} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="color"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Color</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ""} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="vendor_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Vendor</FormLabel>
                        <VendorSelect
                          value={field.value || null}
                          onChange={field.onChange}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TabsContent>
              </Tabs>

              <FormField
                control={form.control}
                name="images"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Images</FormLabel>
                    <ProjectOptionImagesField
                      images={field.value}
                      onChange={field.onChange}
                      designLayoutId={form.watch("layout_config.id")}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </FormSection>
          </div>

          {project.shipping_mode === "inventory" && (
            <FormSection title="Inventory">
              {!createInventoryProduct && (
                <FormField
                  control={form.control}
                  name="inventory_product_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inventory Product</FormLabel>
                      <ProductSelect
                        value={field.value || null}
                        onChange={field.onChange}
                        params={{
                          owned: "1",
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="create_inventory_product"
                render={({ field }) => (
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <Label variant="checkbox">Create Inventory Product</Label>
                  </div>
                )}
              />
            </FormSection>
          )}

          <FormSection title="Decoration Details">
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Decoration Method</FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a decoration method" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {DECORATION_METHODS.map((type) => (
                        <SelectItem key={type} value={type}>
                          {startCase(type)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {orderType && orderType !== "blanks" && (
              <LayoutBuilderDialog
                type={orderType}
                product={product}
                initialValue={
                  layoutConfig || {
                    name: name || "My Layout",
                  }
                }
                onSubmit={(v) => form.setValue("layout_config", v)}
              />
            )}
          </FormSection>

          <FormSection
            title="Pricing"
            action={<DynamicPricingSwitch disabled={!canUseDynamicPricing} />}
          >
            <VariantTable />

            {isUsingDynamicAdSpecPricing ? (
              <Alert>Fees will be dynamically calculated</Alert>
            ) : (
              <FeeTable />
            )}

            <FormField
              control={form.control}
              name="min_qty"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Minimum Quantity</FormLabel>
                  <FormControl>
                    <Input {...field} type="number" inputMode="numeric" />
                  </FormControl>
                  <FormMessage />
                  <FormDescription>
                    Price is good as long as this many units are ordered.
                  </FormDescription>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="estimated_qty"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Estimated Quantity</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={field.value || ""}
                      type="number"
                      inputMode="numeric"
                      placeholder={`e.g. ${project.budget_qty}`}
                    />
                  </FormControl>
                  <FormMessage />
                  <FormDescription>
                    Optionally used to show estimated pricing (e.g. $20 for 24
                    units). Leave blank to show "Starting at" pricing.
                  </FormDescription>
                </FormItem>
              )}
            />
          </FormSection>

          <FormSection title="Tags">
            {OPTION_TAGS.map((tag) => (
              <FormField
                control={form.control}
                name={`tags.${tag}`}
                render={({ field }) => (
                  <Label
                    variant="checkbox"
                    className="flex items-center space-x-2"
                  >
                    <Checkbox
                      checked={field.value || false}
                      onCheckedChange={field.onChange}
                    />
                    <span>{startCase(tag)}</span>
                  </Label>
                )}
              />
            ))}
          </FormSection>
        </div>

        <FormErrors form={form} />

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Submit
        </Button>
      </form>
    </Form>
  );
}

export default forwardRef(ProjectOptionForm);
