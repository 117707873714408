import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { Form } from "@/components/ui/form";
import {
  FulfillmentOrderPayload,
  fulfillmentOrderPayloadSchema,
} from "avail-types";
import SkuItemById from "@/components/inventory/SkuItemById";
import AddFulfillmentOrderItemForm from "@/components/fulfillmentOrders/AddFulfillmentOrderItemForm";
import { X } from "lucide-react";
import { FormErrors } from "@/components/ui/form-errors";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const formSchema = fulfillmentOrderPayloadSchema.pick({
  items: true,
});

export default function OrderItemsStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: Partial<FulfillmentOrderPayload>;
  onSubmit: (values: Partial<FulfillmentOrderPayload>) => void;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      items: [],
      ...defaultValues,
    },
  });

  function onAddItem(variantId: number, qty: number) {
    form.setValue("items", [
      ...form.getValues().items,
      { variant_id: variantId, qty: qty },
    ]);
  }

  function removeItemAtIndex(index: number) {
    form.setValue(
      "items",
      form.getValues().items.filter((_, i) => i !== index),
    );
  }

  const items = form.watch("items");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-6 lg:grid-cols-2">
          <AddFulfillmentOrderItemForm onSubmit={onAddItem} />

          <Card>
            <CardHeader>
              <CardTitle>Items in Order</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-4">
                {items.map((item, index) => (
                  <li key={`${item.variant_id}.${index}`}>
                    <SkuItemById variantId={item.variant_id!} qty={item.qty}>
                      <button
                        type="button"
                        onClick={() => removeItemAtIndex(index)}
                      >
                        <X className="size-4 text-slate-500" />
                      </button>
                    </SkuItemById>
                  </li>
                ))}
              </ul>
              {items.length === 0 && (
                <p className="text-muted-foreground">No items in order.</p>
              )}
            </CardContent>
          </Card>
        </div>

        <FormErrors form={form} />

        <div className="mt-6 flex justify-end">
          <WizardSubmitButton>Next</WizardSubmitButton>
        </div>
      </form>
    </Form>
  );
}
