import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { Integration } from "@/api/integrations";
import { useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import AddressText from "@/components/addresses/AddressText";
import pluralize from "pluralize";
import { DataTable } from "@/components/ui/data-table";
import {
  IntegrationRecipient,
  useGetIntegrationRecipients,
} from "@/api/integration-recipients";

const columnHelper = createColumnHelper<IntegrationRecipient>();

const EMPTY_ARRAY: IntegrationRecipient[] = [];

export function GiftRecipientsFromIntegrationDialog({
  integration,
  onSubmit,
}: {
  integration: Integration;
  onSubmit: (recipients: IntegrationRecipient[]) => void;
}) {
  const [open, setOpen] = useState(false);

  const { data = EMPTY_ARRAY, isLoading } = useGetIntegrationRecipients({
    type: open ? integration.type : undefined, // If there's no type, we won't make API call
  });

  const columns = [
    columnHelper.accessor("first_name", {
      header: "First Name",
    }),
    columnHelper.accessor("last_name", {
      header: "Last Name",
    }),
    columnHelper.accessor("company", {
      header: "Company",
    }),
    columnHelper.accessor("email", {
      header: "Email",
    }),
    columnHelper.accessor("address", {
      header: "Address",
      cell: ({ getValue }) => {
        const address = getValue();
        if (!address) return null;
        return <AddressText address={address} />;
      },
    }),
  ];

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.id,
    data,
    columns,
  });

  const selectedRows = table.getSelectedRowModel().rows;

  const handleSubmit = () => {
    onSubmit(selectedRows.map((r) => r.original));
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="xs" variant="outline">
          <IntegrationIcon size="size-4 mr-2" type={integration.type} />
          Add from {integration.title}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-6xl">
        <DialogHeader>
          <DialogTitle>Add Recipients from {integration.title}</DialogTitle>
        </DialogHeader>

        <DataTable table={table} isLoading={isLoading} searchable />

        <DialogFooter>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={selectedRows.length === 0}>
            Add {pluralize("Recipient", selectedRows.length, true)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
