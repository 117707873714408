import { RadioGroup } from "@headlessui/react";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import { CircleIcon, CheckCircleIcon } from "lucide-react";

export function BlankRadioOption<T>({
  value,
  children,
  className,
}: {
  value: T;
  children: ReactNode;
  className?: string;
}) {
  return (
    <RadioGroup.Option
      id={`option-${value}`}
      value={value}
      className={({ active }) =>
        cn(
          active ? "border-primary ring-2 ring-primary" : "border-slate-300",
          "relative flex h-full cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
          className,
        )
      }
    >
      {({ checked, active }) => (
        <>
          <span className="flex min-w-0 flex-1">
            <span className="flex min-w-0 flex-1 flex-col">{children}</span>
          </span>
          {checked ? (
            <CheckCircleIcon
              className="size-5 text-primary"
              aria-hidden="true"
            />
          ) : (
            <CircleIcon className="size-5 text-muted" aria-hidden="true" />
          )}
          <span
            className={cn(
              active ? "border" : "border-2",
              checked ? "border-primary" : "border-transparent",
              "pointer-events-none absolute -inset-px rounded-lg",
            )}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  );
}

export function RadioOption<T>({
  value,
  label,
  description,
  className,
}: {
  value: T;
  label: ReactNode;
  description: ReactNode;
  className?: string;
}) {
  return (
    <BlankRadioOption value={value} className={className}>
      <RadioGroup.Label as="span" className="block font-medium">
        {label}
      </RadioGroup.Label>
      <RadioGroup.Description
        as="span"
        className="mt-1 flex flex-grow flex-col items-start text-sm text-muted-foreground"
      >
        {description}
      </RadioGroup.Description>
    </BlankRadioOption>
  );
}
