import { ReactNode } from "react";
import { createColumnHelper, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { Project } from "avail-types";
import { curr } from "@/lib/curr";
import { Link } from "react-router-dom";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import { UseQueryResult } from "@tanstack/react-query";
import ProjectStatusBadge from "@/components/projects/ProjectStatusBadge";
import { SHIPPING_MODE_LABELS } from "@/constants/project";
import { Badge } from "@/components/ui/badge";
import { ArrowRight } from "lucide-react";
import ProjectDueBadge from "@/components/projects/ProjectDueBadge";
import { getItemName } from "@/lib/projects";
import startCase from "lodash/startCase";
import { formatDate } from "@/lib/dates";

const columnHelper = createColumnHelper<Project>();
const COLUMNS = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: ({ getValue, row }) => (
      <div>
        <Link
          to={`/projects/${row.original.id}`}
          className="whitespace-nowrap font-medium"
        >
          {getValue()}
        </Link>
        {row.original.type !== "project" && (
          <Badge className="ml-2">{startCase(row.original.type)}</Badge>
        )}
      </div>
    ),
  }),
  columnHelper.accessor("items", {
    enableSorting: false,
    header: "Items",
    cell: ({ getValue }) => {
      const items = getValue();
      return (
        <div className="flex flex-wrap gap-1.5">
          {items.slice(0, 2).map((item) => (
            <Badge key={item.id} variant="gray">
              {getItemName(item)}
            </Badge>
          ))}
          {items.length > 3 && (
            <Badge variant="gray">and {items.length - 2} more</Badge>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor("in_hands_date", {
    header: "In Hands Date",
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor("created_at", {
    header: "Created Date",
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor("shipping_mode", {
    header: "Shipping Method",
    cell: ({ getValue }) => SHIPPING_MODE_LABELS[getValue()],
  }),
  columnHelper.accessor("total", {
    header: "Project Total",
    enableSorting: false,
    cell: ({ getValue }) => <span>{curr(getValue())}</span>,
  }),
  columnHelper.accessor("user.name", {
    header: "Primary Contact",
  }),
  columnHelper.accessor("customer_po", {
    header: "PO Number",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: ({ row }) => <ProjectStatusBadge project={row.original} />,
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-3">
          <ProjectDueBadge project={row.original} />

          <Link to={`/projects/${row.original.id}`}>
            <ArrowRight className="size-5 text-muted-foreground" />
          </Link>
        </div>
      );
    },
  }),
];

export default function ProjectsTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<Project>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<Project>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS, {
    columnVisibility: {
      created_at: false,
      customer_po: false,
    },
  });

  return (
    <DataTable
      header={header && header(table)}
      table={table}
      isLoading={query.isLoading}
    />
  );
}
