import { ReactNode } from "react";
import { createColumnHelper, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerSku } from "avail-types";
import { Archive, Send } from "lucide-react";
import { Button } from "@/components/ui/button";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import Toolbar from "@/components/ui/toolbar";
import { UseQueryResult } from "@tanstack/react-query";
import { curr } from "@/lib/curr";
import { formatDate } from "@/lib/dates";
import { Badge } from "@/components/ui/badge";
import ArchiveSkuButton from "@/components/inventory/ArchiveSkuButton";
import EditSkuButton from "@/components/inventory/EditSkuButton";
import InventoryHistoryButton from "@/components/inventory/InventoryHistoryButton";
import RestockSheet from "@/components/inventory/RestockSheet";
import { Link } from "react-router-dom";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { imageUrl } from "@/lib/image";
import pluralize from "pluralize";

const columnHelper = createColumnHelper<CustomerSku>();
const COLUMNS = [
  columnHelper.accessor("product.image", {
    enableHiding: false,
    header: "",
    cell: ({ getValue }) => (
      <img
        src={imageUrl(getValue(), { h: 100, w: 100 })}
        alt=""
        className="size-8"
      />
    ),
  }),
  columnHelper.accessor("sku", {
    header: "SKU",
    cell: ({ getValue }) => <span className="font-medium">{getValue()}</span>,
  }),
  columnHelper.accessor("description", {
    header: "Description",
  }),
  columnHelper.accessor("product.style_number", {
    header: "Number",
  }),
  columnHelper.accessor("product.color", {
    header: "Color",
  }),
  columnHelper.accessor("calculated_value", {
    header: "Value",
    cell: ({ getValue }) => <span>{curr(getValue())}</span>,
    enableSorting: false,
  }),
  columnHelper.accessor("inventory_qty", {
    header: "Inventory",
  }),
  columnHelper.accessor("sold_qty", {
    header: "Used",
  }),
  columnHelper.accessor("last_pulled_at", {
    cell: ({ getValue }) => <span>{formatDate(getValue())}</span>,
    header: "Last Pulled",
  }),
  columnHelper.accessor("last_restocked_at", {
    cell: ({ getValue }) => <span>{formatDate(getValue())}</span>,
    header: "Last Restocked",
  }),
  columnHelper.accessor("pulled_qty", {
    header: "Pulled",
  }),
  columnHelper.accessor("stocked_qty", {
    header: "Stocked",
  }),
  columnHelper.accessor("created_at", {
    cell: ({ getValue }) => <span>{formatDate(getValue())}</span>,
    header: "Created",
  }),
  columnHelper.display({
    id: "levels",
    cell: ({ row }) => {
      const sku = row.original;
      return (
        <div className="flex items-center space-x-3">
          {sku.inventory_scrapped > 0 && (
            <Badge variant="amber">{sku.inventory_scrapped} scrapped</Badge>
          )}

          {sku.inventory_on_order > 0 && (
            <Badge variant="teal">{sku.inventory_on_order} on order</Badge>
          )}

          {sku.alert_level && sku.inventory_qty < sku.alert_level ? (
            <Badge variant="destructive">Low</Badge>
          ) : null}

          {sku.alert_level != null && <Badge>Min: {sku.alert_level}</Badge>}

          {sku.par_level != null && <Badge>Par: {sku.par_level}</Badge>}
        </div>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className="flex gap-3 text-slate-500">
        <SimpleTooltip tooltip="Send Swag">
          <Link to={`/send?variant=${row.original.id}`}>
            <Send className="size-5" />
          </Link>
        </SimpleTooltip>
        <EditSkuButton sku={row.original} />

        <InventoryHistoryButton sku={row.original} />

        {row.original.inventory_qty === 0 && (
          <ArchiveSkuButton skus={[row.original]} title="Archive SKU">
            <button className="align-center flex">
              <Archive className="size-5 text-slate-500" />
            </button>
          </ArchiveSkuButton>
        )}
      </div>
    ),
  }),
];
export default function InventoryTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<CustomerSku>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<CustomerSku>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS, {
    columnVisibility: {
      last_pulled_at: false,
      last_restocked_at: false,
      pulled_qty: false,
      stocked_qty: false,
      sold_qty: false,
      created_at: false,
      product_color: false,
      product_style_number: false,
    },
  });
  table.setOptions((prev) => ({
    ...prev,
    enableRowSelection: true,
  }));
  const { rows: selected } = table.getSelectedRowModel();
  const numRowsSelected = selected.length;
  const selectedHasInventory = selected.some(
    (row) => row.original.inventory_qty > 0,
  );

  return (
    <div>
      {numRowsSelected > 0 && (
        <Toolbar
          className="py-2"
          actions={
            numRowsSelected ? (
              <>
                <RestockSheet skus={selected.map((sku) => sku.original)} />
                <ArchiveSkuButton
                  disabled={selectedHasInventory}
                  onArchive={() => table.toggleAllPageRowsSelected(false)}
                  skus={selected.map((row) => row.original)}
                  title="Archive SKUs"
                >
                  <Button variant="destructive">
                    <Archive className="mr-2 size-5" />
                    Archive SKU{selected.length !== 1 && "s"}
                  </Button>
                </ArchiveSkuButton>
              </>
            ) : null
          }
        >
          {pluralize("SKU", numRowsSelected, true)} selected
        </Toolbar>
      )}
      <DataTable
        header={header && header(table)}
        table={table}
        isLoading={query.isLoading}
      />
    </div>
  );
}
