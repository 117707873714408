import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  Project,
  ProjectItem,
  ProjectCreatePayload,
  ProjectItemPayload,
  ProjectUpdatePayload,
} from "avail-types";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useProject, useProjectContext } from "@/context/ProjectContext";
import { track } from "@/lib/analytics";

export const PROJECTS_KEY = "projects";

export function useSetProject() {
  const queryClient = useQueryClient();
  return useCallback(
    (project: Project) => {
      queryClient.setQueryData([PROJECTS_KEY, "detail", project.id], project);
    },
    [queryClient],
  );
}

export function useGetProject(
  projectId: number,
  onSuccess?: (p: Project) => void,
) {
  return useQuery(
    [PROJECTS_KEY, "detail", projectId],
    () => axios.get<Project>(`projects/${projectId}`).then(({ data }) => data),
    {
      refetchOnWindowFocus: true,
      onSuccess,
    },
  );
}

export function useCreateProject() {
  const navigate = useNavigate();
  const setProject = useSetProject();

  return useMutation((payload: ProjectCreatePayload) => {
    return axios.post<Project>("projects", payload).then(({ data }) => {
      track("Project Created", {
        name: data.name,
        type: data.type,
        budget_type: data.budget_type,
        shipping_mode: data.shipping_mode,
        self_service: data.status === "proofs_requested",
      });
      setProject(data);
      navigate(`/projects/${data.id}?created=1`);
      return data;
    });
  });
}

export function useUpdateProject(projectId: number) {
  const setProject = useSetProject();
  return useMutation((payload: ProjectUpdatePayload) =>
    axios.put<Project>(`projects/${projectId}`, payload).then(({ data }) => {
      setProject(data);
      return data;
    }),
  );
}

export function useDuplicateProject(projectId: number) {
  const navigate = useNavigate();
  const setProject = useSetProject();
  return useMutation((payload: { name: string | null }) =>
    axios
      .post<Project>(`projects/${projectId}/duplicate`, payload)
      .then(({ data }) => {
        setProject(data);
        navigate(`/projects/${data.id}`);
        return data;
      }),
  );
}

export function useUpdateProjectStatus() {
  const { project, onShowConfetti } = useProjectContext();
  const setProject = useSetProject();
  const prevStatus = project?.status;

  return useMutation<
    Project,
    AxiosError<{ message?: string }>,
    { status: Project["status"]; note?: string | null }
  >((payload) =>
    axios
      .put<Project>(`projects/${project!.id}/status`, payload)
      .then(({ data }) => {
        if (prevStatus === "draft" && data.status === "options_requested") {
          onShowConfetti();
        }
        track("Project Status Updated", {
          prevStatus,
          status: data.status,
        });
        setProject(data);
        return data;
      }),
  );
}

export function useDownloadProject(projectId: number) {
  return useMutation(() =>
    axios
      .get<{ url: string }>(`/projects/${projectId}?format=pdf`)
      .then(({ data }) => {
        const el = document.createElement("a");
        el.setAttribute("href", data.url);
        el.setAttribute("target", "_blank");
        el.setAttribute("download", `project-${projectId}-invoice.pdf`);
        el.click();
      }),
  );
}

export interface UploadPurchaseOrderPayload {
  number: string;
  pdf?: File | null;
}

export function useUploadPurchaseOrder(projectId: number) {
  const setProject = useSetProject();
  return useMutation((payload: UploadPurchaseOrderPayload) => {
    const formData = new FormData();
    formData.append("number", payload.number);
    if (payload.pdf) {
      formData.append("pdf", payload.pdf);
    }
    return axios
      .post<Project>(`/projects/${projectId}/purchase-order`, formData)
      .then(({ data }) => {
        setProject(data);
        return data;
      });
  });
}

export function useCreateItem() {
  const project = useProject();
  const queryClient = useQueryClient();

  return useMutation((payload: ProjectItemPayload) =>
    axios
      .post<ProjectItem>(`/projects/${project.id}/items`, payload)
      .then(({ data }) => {
        queryClient.setQueryData<Project>(
          [PROJECTS_KEY, "detail", project.id],
          (prev) => ({
            ...prev!,
            items: [...prev!.items, data],
          }),
        );
        return data;
      }),
  );
}

export function useUpdateItem(itemId: number) {
  const project = useProject();
  const queryClient = useQueryClient();

  return useMutation((payload: Partial<ProjectItemPayload>) =>
    axios
      .put<ProjectItem>(`/projects/${project.id}/items/${itemId}`, payload)
      .then(({ data }) => {
        queryClient.setQueryData<Project>(
          [PROJECTS_KEY, "detail", project.id],
          (prev) => ({
            ...prev!,
            items: prev!.items.map((i) => (i.id === itemId ? data : i)),
          }),
        );
        return data;
      }),
  );
}

export function useDeleteItem(itemId: number) {
  const project = useProject();
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios.delete(`/projects/${project.id}/items/${itemId}`).then(() => {
      queryClient.setQueryData<Project>(
        [PROJECTS_KEY, "detail", project.id],
        (prev) => ({
          ...prev!,
          items: prev!.items.filter((i) => i.id !== itemId),
        }),
      );
    }),
  );
}

export function useProjectSummary() {
  return useQuery([PROJECTS_KEY, "summary"], () =>
    axios
      .get<{ data: Project[] }>("/projects", {
        params: {
          open_status: "not_cancelled",
          count: 1000,
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useRestock() {
  const navigate = useNavigate();

  return useMutation((payload: { items: { id: number; qty: number }[] }) => {
    return axios.post<Project>("/restock", payload).then(({ data }) => {
      track("Project Created", {
        name: data.name,
        budget_type: data.budget_type,
        shipping_mode: data.shipping_mode,
        self_service: data.status === "proofs_requested",
      });
      navigate(`/projects/${data.id}?created=1`);
      return data;
    });
  });
}
