import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IntegrationType } from "avail-types";

export interface Integration {
  type: IntegrationType;
  title: string;
  description: string;
  category: "crm" | "people";
  is_connected: boolean;
}

export const INTEGRATIONS_KEY = "integrations";

export function useGetIntegrations() {
  return useQuery(
    [INTEGRATIONS_KEY],
    () =>
      axios
        .get<{
          data: Integration[];
        }>("/integrations")
        .then(({ data }) => data.data),
    { staleTime: 1000 * 60 * 60 * 24 },
  );
}

export function useGetConnectedIntegrations() {
  return useGetIntegrations().data?.filter((i) => i.is_connected) || [];
}
