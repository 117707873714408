import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import useDebounce from "@/hooks/useDebounce";
import { useSetBusiness } from "@/context/BusinessContext";
import {
  CustomerOrder,
  OrderItem,
  Proof,
  PurchaseOrderStatus,
  Shipment,
} from "avail-types";
import { UploadPurchaseOrderPayload } from "@/api/projects";

export const ORDERS_KEY = "orders";

interface OrderWithDetails extends CustomerOrder {
  items: OrderItem[];
  shipments: Shipment[];
  vendor_statuses: PurchaseOrderStatus[];
  proofs: Proof[];
}

export function useGetOrder(code: string) {
  const setBusiness = useSetBusiness();
  return useQuery(
    [ORDERS_KEY, "detail", code],
    () =>
      axios
        .get<OrderWithDetails>(`/orders/${code}`, {
          params: {
            with: "items.inventory_variant",
          },
        })
        .then(({ data }) => {
          setBusiness(data.business);
          return data;
        }),
    {
      refetchOnWindowFocus: true,
    },
  );
}

export interface PaymentResponse {
  balance: number;
  ach_credit_transfer: {
    account_number: string;
    bank_name: string;
    routing_number: string;
    fingerprint: string;
    swift_code: string;
  };
  bank_accounts: object[];
  card: {
    session_url: string;
  };
}

export function useUploadPurchaseOrder(orderCode: string) {
  const queryClient = useQueryClient();

  return useMutation((payload: UploadPurchaseOrderPayload) => {
    const formData = new FormData();
    formData.append("number", payload.number);
    if (payload.pdf) {
      formData.append("pdf", payload.pdf);
    }
    return axios
      .post<CustomerOrder>(`/orders/${orderCode}/purchase-order`, formData)
      .then(({ data }) => {
        queryClient.setQueryData([ORDERS_KEY, "detail", orderCode], data);
        return data;
      });
  });
}

export function useSearchOrders(query: string) {
  const debouncedQuery = useDebounce(query, 500);

  return useQuery(
    [ORDERS_KEY, "search", debouncedQuery],
    () =>
      axios
        .get<{
          data: CustomerOrder[];
        }>(`/orders?query=${debouncedQuery}`)
        .then(({ data }) => data.data),
    {
      enabled: !!debouncedQuery,
    },
  );
}
