import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import useDebounce from "@/hooks/useDebounce";
import { INPUT_CLASSES } from "@/components/ui/input";
import {
  useGetGiftingProduct,
  useGetGiftingProducts,
} from "@/api/gifting-products";
import { Badge } from "@/components/ui/badge";
import { GiftingProduct } from "avail-types";
import GiftingProductImage from "@/components/gifting/products/GiftingProductImage";
import { curr } from "@/lib/curr";
import { getGiftingProductTypeTitle } from "@/components/gifting/utils";

function GiftingProductItem({ product }: { product: GiftingProduct }) {
  const minPriceNode = (
    <b className="font-medium">{curr(product.min_price)} ea</b>
  );

  return (
    <div className="flex flex-grow items-center justify-start gap-2">
      <GiftingProductImage
        product={product}
        className="size-10"
        iconClassName="p-2"
      />
      <div className="flex-grow text-left font-normal">
        <div className="text-sm">{product.name}</div>
        <div className="text-xs text-muted-foreground">
          {product.min_price === product.max_price ? (
            minPriceNode
          ) : (
            <>As low as {minPriceNode}</>
          )}
        </div>
      </div>
      <Badge>{getGiftingProductTypeTitle(product.type)}</Badge>
    </div>
  );
}

export default function GiftingProductSelect({
  value,
  onChange,
}: {
  value: number | null;
  onChange: (value: number) => void;
}) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);

  const { data, isLoading } = useGetGiftingProducts({
    query: debouncedQuery,
    "filter[type][nin]": "gift_card",
  });
  const { data: selectedProduct } = useGetGiftingProduct(value);
  const products = data ? data.data : [];

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", INPUT_CLASSES, "h-12")}
        >
          {selectedProduct ? (
            <GiftingProductItem product={selectedProduct} />
          ) : (
            <span>Find Product</span>
          )}

          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[600px] p-0" align="end">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search Products..."
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>
            {isLoading ? "Loading..." : "No Product found."}
          </CommandEmpty>
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {products.map((product) => (
              <CommandItem
                key={product.id}
                value={String(product.id)}
                onSelect={(currentValue) => {
                  onChange(Number(currentValue));
                  setOpen(false);
                }}
              >
                <GiftingProductItem product={product} />
                <Check
                  className={cn(
                    "ml-2 size-4",
                    value === product.id ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
