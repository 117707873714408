import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Webstore } from "avail-types";

export const WEBSTORES_KEY = "webstores";

export function useGetWebstores() {
  return useQuery([WEBSTORES_KEY], () =>
    axios.get<{ data: Webstore[] }>("/webstores").then(({ data }) => data.data),
  );
}
