import { useState } from "react";
import { CustomerTeam } from "avail-types";
import { useUpdateMembershipRequest } from "@/api/membership";
import MemberSelect from "@/components/selects/MemberSelect";
import { DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

export default function AddMemberToTeamForm({
  team,
  onSuccess,
}: {
  team: CustomerTeam;
  onSuccess: () => void;
}) {
  const [memberId, setMemberId] = useState<number | null>(null);
  const updateRequest = useUpdateMembershipRequest();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateRequest
          .mutateAsync({
            id: memberId!,
            team_id: team.id,
          })
          .then(onSuccess);
      }}
    >
      <MemberSelect value={memberId} onChange={setMemberId} />

      <DialogFooter className="mt-4">
        <Button
          type="submit"
          disabled={!memberId}
          isLoading={updateRequest.isLoading}
        >
          Add Member
        </Button>
      </DialogFooter>
    </form>
  );
}
