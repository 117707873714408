import { GiftRecipientPayload, giftRecipientPayloadSchema } from "avail-types";
import Papa from "papaparse";
import { ChangeEvent, useState } from "react";
import snakeCase from "lodash/snakeCase";
import { Alert } from "@/components/ui/alert";
import pluralize from "pluralize";
import { DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

export default function GiftRecipientUploadForm({
  onSubmit,
}: {
  onSubmit: (recipients: GiftRecipientPayload[]) => void;
}) {
  const [results, setResults] = useState<GiftRecipientPayload[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: snakeCase,
      complete: (result) => {
        const payloads: GiftRecipientPayload[] = [];
        const errors: string[] = [];

        result.data.forEach((row) => {
          if (!row) return;
          if (typeof row !== "object") return;
          if ("address_1" in row && row.address_1) {
            // @ts-ignore
            row.address = {
              name: "TO BE REPLACED",
              address_1: row.address_1,
              address_2: "address_2" in row ? row.address_2 : null,
              city: "city" in row ? row.city : null,
              state: "state" in row ? row.state : null,
              zip: "zip" in row ? row.zip : null,
              country: "country" in row ? row.country : null,
            };
          }
          const res = giftRecipientPayloadSchema.safeParse(row);

          if (res.success) {
            payloads.push({
              ...res.data,
              address: res.data.address
                ? {
                    ...res.data.address,
                    name: `${res.data.first_name} ${res.data.last_name}`.trim(),
                    company: res.data.company,
                  }
                : null,
            });
          } else {
            errors.push(res.error.message);
          }
        });

        setResults(payloads);
        setErrors(errors);
      },
      error: (err) => {
        setErrors([`Error parsing file: ${err.message}`]);
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(results);
      }}
    >
      <input type="file" accept=".csv" onChange={handleFileUpload} />

      {errors.length > 0 && (
        <Alert variant="destructive">
          <ul>
            {errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}

      <DialogFooter className="mt-6">
        <Button type="submit" disabled={results.length === 0}>
          Add {pluralize("Recipient", results.length, true)}
        </Button>
      </DialogFooter>
    </form>
  );
}
