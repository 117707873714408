import { ReactNode, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem } from "@/components/ui/form";
import { Button } from "../ui/button";
import { useCreateCustomerBrandColor } from "@/api/brand-colors";
import { SketchPicker } from "react-color";

const formSchema = z.object({
  hex: z.string(),
});

export default function CreateColorDialog({
  children,
}: {
  children?: ReactNode;
}) {
  const createColor = useCreateCustomerBrandColor();
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const handleCreate = (values: z.infer<typeof formSchema>) =>
    createColor.mutateAsync(values).then(() => {
      form.reset();
      setOpen(false);
    });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Create Color</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleCreate)}
            className="space-y-5"
          >
            <FormField
              control={form.control}
              name="hex"
              render={({ field }) => (
                <FormItem>
                  <SketchPicker
                    color={field.value}
                    onChange={(color) => field.onChange(color.hex)}
                  />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Create
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
