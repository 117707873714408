import { createContext, useCallback, useContext } from "react";
import {
  Address,
  CustomerBusiness,
  Country,
  CustomerProjectField,
  CustomerUser,
  UserMembership,
  Webstore,
} from "avail-types";
import { PaymentMethod } from "@stripe/stripe-js";
import Echo from "laravel-echo";

export type SharedState = {
  setShowSplash: (b: boolean) => void;
};
export type EmptyState = { user: null; membership: null; countries: Country[] };

export type NoCustomerState = {
  user: CustomerUser;
  membership: null;
  memberships: UserMembership[];
  countries: Country[];
  existing_customer: { id: number; admin_email: string } | null;
};

export type AppState = {
  user: CustomerUser;
  membership: UserMembership;
  memberships: UserMembership[];
  business: CustomerBusiness;
  webstores: Webstore[];
  countries: Country[];
  subscription: {
    plan_name: string;
    status: "active" | "past_due" | "trialing";
    trial_start: number | null;
    trial_end: number | null;
    features: string[];
  } | null;
  reverb: {
    key: string;
    host: string;
    scheme: "http" | "https";
  };
  portal_ui_url: string;
  credit_balance: number;
  settings: {
    color: string | null;
    drawer_color: string | null;
    is_fulfillment: boolean;
    is_gifting: boolean;
    portal_shopping_mode: "blank" | "decorated" | "stortal" | "both";
    terms: {
      is_upfront: boolean;
      name: string;
      days: number;
    };
    addresses: Address[];
    third_party_accounts: {
      account_number: string;
      carrier: string;
    }[];
    projects_enabled: boolean;
    project_fields: CustomerProjectField[];
    bank_accounts: PaymentMethod[];
    strings: Record<string, string>;
  };
};

type AppStateWithEcho = AppState & { Echo: Echo };

const AppContext = createContext<
  (EmptyState | NoCustomerState | AppStateWithEcho) & SharedState
>({
  user: null,
  membership: null,
  countries: [],
  setShowSplash: () => null,
});

export function useAppContext() {
  return useContext(AppContext);
}

export function useAppState(): AppStateWithEcho & SharedState {
  const context = useAppContext();

  if (context.membership) {
    return context;
  }

  throw new Error("No customer in context");
}

export function useAuthedState(): (NoCustomerState | AppStateWithEcho) &
  SharedState {
  const context = useAppContext();

  if (context.user) {
    return context;
  }

  throw new Error("No user in context");
}

export function useMaybeUser(): CustomerUser | null {
  return useAppContext().user;
}

export function useUser(): CustomerUser {
  const user = useMaybeUser();
  if (user) {
    return user;
  }
  throw new Error("No user in context");
}

export function useCountries(): Country[] {
  return useAppContext().countries || [];
}

export function usePortalShoppingMode() {
  return useAppState().settings.portal_shopping_mode;
}

export function useStrings() {
  return useSettings().strings;
}

export function useGetString() {
  const strings = useStrings();

  return useCallback(
    (key: string, fallback = "") => {
      return strings[key] || fallback;
    },
    [strings],
  );
}

export function useSubscription() {
  const context = useAppContext();
  if ("subscription" in context) {
    return context.subscription;
  }
  return null;
}

export function usePlanName() {
  return useSubscription()?.plan_name || "Free Plan";
}

export function useHasFeature() {
  const subscription = useSubscription();
  // const role = useAppContext().membership?.role || "guest";

  return useCallback(
    (feature: string) => {
      // if (role === "avail_incognito") {
      //   return true;
      // }
      return subscription && subscription.features.includes(feature);
    },
    [subscription],
  );
}

export function useSettings() {
  const context = useAppContext();

  if ("settings" in context) {
    return context.settings;
  }

  return {
    color: null,
    drawer_color: null,
    bank_accounts: [],
    is_fulfillment: false,
    is_gifting: false,
    projects_enabled: false,
    strings: {} as Record<string, string>,
    project_fields: [],
    portal_shopping_mode: null,
  };
}

export default AppContext;
