import { Gift } from "avail-types";
import StatusProgress from "@/components/ui/status-progress";
import { Alert } from "@/components/ui/alert";
import startCase from "lodash/startCase";

const STEPS: Gift["status"][] = [
  "pending",
  "sent",
  "viewed",
  "redeemed",
  "shipped",
  "delivered",
];

export default function GiftStatusSection({ gift }: { gift: Gift }) {
  if (gift.status === "cancelled") {
    return <Alert variant="destructive">This gift has been canceled.</Alert>;
  }

  const validSteps = STEPS.filter(
    (status) => status !== "sent" || gift.should_send,
  );

  return (
    <StatusProgress
      steps={validSteps.map((status) => ({
        status,
        title: startCase(status),
      }))}
      currentStatus={gift.status}
    />
  );
}
