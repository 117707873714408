import { CustomerDesignLayout } from "avail-types";
import { useState } from "react";
import SearchInput from "@/components/ui/search-input";
import { Button } from "@/components/ui/button";
import { ProductCardSkeleton } from "@/components/products/ProductCard";
import ImageAnchor from "@/components/ui/image-anchor";
import Lightbox from "yet-another-react-lightbox";
import { Download, Zoom } from "yet-another-react-lightbox/plugins";
import { imageUrl } from "@/lib/image";
import { Card } from "@/components/ui/card";
import { useInfiniteDesignLayouts } from "@/api/design-layouts";
import { formatDateTime, formatRelative } from "@/lib/dates";

export default function SearchDesignLayouts({
  onSelect,
}: {
  onSelect?: (layout: CustomerDesignLayout) => void;
}) {
  const [index, setIndex] = useState(-1);
  const [query, setQuery] = useState("");

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } =
    useInfiniteDesignLayouts(query);

  const layouts = (data?.pages || []).flatMap((page) => page.data);

  return (
    <div>
      <div className="mb-6 flex justify-between">
        <SearchInput onChange={setQuery} />
      </div>

      <div className="mt-3 grid gap-6 lg:grid-cols-3 2xl:grid-cols-4">
        {isLoading &&
          Array.from({ length: 6 }).map((_, i) => (
            <ProductCardSkeleton key={i} />
          ))}

        {!isLoading && layouts.length === 0 && (
          <div className="text-muted-foreground">No designs found</div>
        )}

        {layouts.map((layout, idx) => (
          <Card key={layout.id} className="flex flex-col overflow-hidden">
            {Boolean(layout.images.length) && (
              <ImageAnchor
                href={layout.images[0]}
                className="flex-grow"
                onClick={() => setIndex(idx)}
              >
                <img
                  src={imageUrl(layout.images[0], { w: 800 })}
                  alt={layout.name}
                  className="aspect-[8.5/11] w-full"
                />
              </ImageAnchor>
            )}
            <div className="p-6 pt-3">
              <div className="flex justify-between">
                <div className="text-lg font-semibold">
                  {layout.increment_id}
                </div>
                <time
                  className="text-xs text-muted-foreground"
                  dateTime={layout.created_at}
                  title={formatDateTime(layout.created_at)}
                >
                  {formatRelative(layout.created_at, {
                    style: "short",
                  })}
                </time>
              </div>

              <div className="text-muted-foreground">{layout.name}</div>

              {onSelect && (
                <Button
                  onClick={() => onSelect(layout)}
                  className="mt-3 w-full"
                >
                  Choose this Decoration
                </Button>
              )}
            </div>
          </Card>
        ))}
      </div>

      <div className="mt-6 flex items-center justify-center">
        {hasNextPage && (
          <Button
            onClick={() => fetchNextPage()}
            isLoading={isFetchingNextPage}
            disabled={!hasNextPage}
            variant="outline"
            size="sm"
          >
            Load More
          </Button>
        )}
      </div>

      <Lightbox
        open={index !== -1}
        close={() => setIndex(-1)}
        slides={layouts.map((l) => ({
          src: l.images[0] || "",
          alt: l.name,
        }))}
        index={index}
        plugins={[Zoom, Download]}
      />
    </div>
  );
}
