import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import { GiftPayload, giftPayloadSchema } from "avail-types";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { useUser } from "@/context/AppContext";
import Gate from "@/components/roles/Gate";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { getSendingMethod } from "@/components/gifting/utils";
import InsertFromTemplate from "@/components/gifting/templates/InsertFromTemplate";
import { GiftVariables } from "@/components/gifting/templates/utils";
import { useEffect } from "react";
import SenderField from "@/components/gifting/SenderField";

const formSchema = giftPayloadSchema
  .pick({
    should_send: true,
    first_name: true,
    email: true,
    subject: true,
    message: true,
    gift_note: true,
    queue: true,
    sender_id: true,
  })
  .refine(
    (schema) => {
      if (schema.should_send) {
        return schema.subject != null && schema.subject.trim().length > 0;
      }
      return true;
    },
    {
      message: "Subject is required when sending",
      path: ["subject"],
    },
  )
  .refine(
    (schema) => {
      if (schema.should_send) {
        return schema.message != null && schema.message.trim().length > 0;
      }
      return true;
    },
    {
      message: "Message is required when sending",
      path: ["message"],
    },
  );

type FormShape = z.infer<typeof formSchema>;

export default function GiftSendStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<GiftPayload>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const user = useUser();
  const shouldSend = form.watch("should_send");

  const variables: GiftVariables = {
    first_name: defaultValues.first_name!,
    last_name: defaultValues.last_name!,
    company: defaultValues.company,
    email: defaultValues.email!,
    sender_name: user.name,
    sender_email: user.email,
  };
  const canSendGiftNote = defaultValues.items!.every(
    (item) => item.product.fulfillment_type === "inventory",
  );

  const canQueue = defaultValues.items!.every(
    (item) => item.product.fulfillment_type === "batch",
  );

  const sendMethod = getSendingMethod(defaultValues);

  useEffect(() => {
    if (!shouldSend) {
      form.setValue("subject", null);
      form.setValue("message", null);
    }
  }, [form, shouldSend]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>How do you want to send this gift?</Title>
            <Description>
              Choose how you want to send this gift to the recipient.
            </Description>
          </Toolbar>

          <div className="mt-4 max-w-4xl space-y-4">
            <FormField
              control={form.control}
              name="should_send"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <FormLabel variant="checkbox">
                      Send {sendMethod} to {defaultValues.email}
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />

            {shouldSend && (
              <FormField
                control={form.control}
                name="subject"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center justify-between">
                      <FormLabel>Subject</FormLabel>
                      <InsertFromTemplate
                        type="subject"
                        variables={variables}
                        setValue={field.onChange}
                      />
                    </div>
                    <FormControl>
                      <Input {...field} value={field.value || ""} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center justify-between">
                    <FormLabel>{!shouldSend && "Optional "} Message</FormLabel>
                    <InsertFromTemplate
                      type="message"
                      variables={variables}
                      setValue={field.onChange}
                    />
                  </div>
                  <FormControl>
                    <Textarea
                      {...field}
                      value={field.value || ""}
                      className="min-h-[200px]"
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            {canSendGiftNote && (
              <FormField
                control={form.control}
                name="gift_note"
                render={({ field }) => (
                  <>
                    <FormItem>
                      <div className="flex items-center space-x-2">
                        <Switch
                          checked={field.value != null}
                          onCheckedChange={(checked) =>
                            field.onChange(checked ? "" : null)
                          }
                        />
                        <FormLabel variant="checkbox">
                          Include a custom note card with the gift (+$1.00)
                        </FormLabel>
                      </div>
                    </FormItem>
                    {field.value != null && (
                      <FormItem>
                        <div className="flex items-center justify-between">
                          <FormLabel>Gift Note</FormLabel>
                          <InsertFromTemplate
                            type="gift_note"
                            variables={variables}
                            setValue={field.onChange}
                          />
                        </div>
                        <FormControl>
                          <Textarea
                            {...field}
                            value={field.value}
                            className="min-h-[200px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  </>
                )}
              />
            )}

            {canQueue && (
              <Gate role={["avail", "avail_incognito"]}>
                <FormField
                  control={form.control}
                  name="queue"
                  render={({ field }) => (
                    <>
                      <FormItem>
                        <div className="flex items-center space-x-2">
                          <Switch
                            checked={field.value != null}
                            onCheckedChange={(checked) =>
                              field.onChange(checked ? "default" : null)
                            }
                          />
                          <FormLabel variant="checkbox">
                            Add this gift to a queue to be produced as part of a
                            batch.
                          </FormLabel>
                        </div>
                      </FormItem>
                      {field.value != null && (
                        <FormItem>
                          <FormLabel>Queue Name</FormLabel>
                          <FormControl>
                            <Input {...field} value={field.value || ""} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    </>
                  )}
                />
              </Gate>
            )}

            <SenderField form={form} />
          </div>

          <WizardSubmitButton>
            {shouldSend ? "Send Gift" : "Create Link"}
          </WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
