import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { AppState, useAppState } from "@/context/AppContext";
import { handleLaravelErrors } from "@/lib/form";
import { Alert } from "@/components/ui/alert";
import AvatarField from "@/components/account/AvatarField";
import { useRef } from "react";
import { CustomerUser } from "avail-types";
import { useQueryClient } from "@tanstack/react-query";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

const formSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  avatar: z.string().url().nullish(),
  title: z.string().nullish(),
  send_as: z.number(),
});

export default function AccountDetailsCard() {
  const {
    membership: { customer },
    user,
  } = useAppState();
  const avatarRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: user.name,
      email: user.email,
      avatar: user.avatar,
      title: user.title,
      send_as: user.send_as,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("title", values.title || "");
    formData.append("send_as", String(values.send_as));
    const image = avatarRef.current?.files?.[0];
    if (image) {
      formData.append("avatar", image);
    }

    return axios
      .post<CustomerUser>(`/user/update`, formData)
      .then(({ data }) => {
        queryClient.setQueryData<AppState>(["me"], (prev) => ({
          ...prev!,
          user: { ...prev!.user, ...data },
        }));
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Your Details</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            {form.formState.isSubmitSuccessful && (
              <Alert variant="success">Account updated successfully</Alert>
            )}

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="avatar"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Avatar</FormLabel>
                  <AvatarField
                    ref={avatarRef}
                    name={field.name}
                    value={field.value}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="send_as"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Send As</FormLabel>
                  <RadioGroup
                    value={String(field.value)}
                    onValueChange={(v) => field.onChange(Number(v))}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="0" id="myself" />
                      <Label variant="checkbox" htmlFor="myself">
                        Myself{" "}
                        <span className="font-normal">({user.name})</span>
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="1" id="myself" />
                      <Label variant="checkbox" htmlFor="myself">
                        Myself at Company{" "}
                        <span className="font-normal">
                          ({user.name} at {customer.name})
                        </span>
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="2" id="company" />
                      <Label variant="checkbox" htmlFor="company">
                        Company{" "}
                        <span className="font-normal">({customer.name})</span>
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="3" id="company-team" />
                      <Label variant="checkbox" htmlFor="company-team">
                        Team{" "}
                        <span className="font-normal">
                          (The {customer.name} Team)
                        </span>
                      </Label>
                    </div>
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" isLoading={form.formState.isSubmitting}>
              Save
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
