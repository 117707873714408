import { Button } from "@/components/ui/button";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { handleLaravelErrors, maybeRenderRootError } from "@/lib/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { useGetString } from "@/context/AppContext";
import { UploadPurchaseOrderPayload } from "@/api/projects";

const formSchema = z.object({
  number: z.string(),
  pdf: z.instanceof(File).nullish(),
});

export default function PurchaseOrderForm(props: {
  onSubmit: (v: UploadPurchaseOrderPayload) => Promise<unknown>;
  initialValues?: Partial<UploadPurchaseOrderPayload>;
}) {
  const getString = useGetString();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: props.initialValues || {},
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) =>
    props.onSubmit(values).catch(handleLaravelErrors(form));

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {maybeRenderRootError(form)}

        <FormField
          control={form.control}
          name="number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>PO Number</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  {...field}
                  placeholder={getString("po_placeholder", "e.g. PO-0001234")}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="pdf"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Upload PDF</FormLabel>
              <FormControl>
                <Input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => field.onChange(e.target.files![0]!)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Submit
        </Button>
      </form>
    </Form>
  );
}
