import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CustomerBrandColor, CustomerBrandColorPayload } from "avail-types";

export const BRAND_COLORS_KEY = "brandColors";

export function useGetCustomerBrandColors() {
  return useQuery([BRAND_COLORS_KEY, "list"], () =>
    axios
      .get<{ data: CustomerBrandColor[] }>("brand-colors")
      .then(({ data }) => data.data),
  );
}

export function useCreateCustomerBrandColor() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CustomerBrandColorPayload) =>
      axios
        .post<CustomerBrandColor>("brand-colors", payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandColor[]>(
          [BRAND_COLORS_KEY, "list"],
          (prev) => {
            if (!prev) return prev;
            return [...prev, data];
          },
        );
      },
    },
  );
}

export function useUpdateCustomerBrandColor(color: CustomerBrandColor) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CustomerBrandColorPayload) =>
      axios
        .put<CustomerBrandColor>(`brand-colors/${color.id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandColor[]>(
          [BRAND_COLORS_KEY, "list"],
          (prev) => {
            if (!prev) return prev;
            return prev.map((color) => {
              if (color.id === data.id) {
                return data;
              }
              return color;
            });
          },
        );
      },
    },
  );
}

export function useDeleteCustomerBrandColor() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`brand-colors/${id}`), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<CustomerBrandColor[]>(
        [BRAND_COLORS_KEY, "list"],
        (prev) => {
          if (!prev) return prev;
          return prev.filter((color) => color.id !== id);
        },
      );
    },
  });
}
