import { AttachmentPayload } from "avail-types";
import { HTMLAttributes, useCallback } from "react";
import { cn } from "@/lib/utils";
import { imageUrl } from "@/lib/image";
import { Loader, Paperclip, Trash } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { useUploadRequest } from "@/api/upload";
import Link from "@/components/ui/link";
import pickBy from "lodash/pickBy";

interface AttachmentProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "placeholder"> {
  attachments: AttachmentPayload[];
  onNew: (a: AttachmentPayload[]) => void;
  onDelete: (a: AttachmentPayload) => void;
  placeholder?: string | null;
}

export default function Attachments({
  attachments,
  onNew,
  onDelete,
  className,
  placeholder = "Upload logo files, examples, or other helpful assets.",
  ...props
}: AttachmentProps) {
  const uploadRequest = useUploadRequest();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      Promise.all(
        acceptedFiles.map((file) =>
          uploadRequest.mutateAsync(file).then((data) => ({
            name: file.name,
            file: data.url,
          })),
        ),
      ).then(onNew);
    },
    [uploadRequest, onNew],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={cn("-m-1 space-y-2 rounded p-1", className, {
        "ring-2 ring-primary ring-offset-4": isDragActive,
      })}
      {...props}
      {...getRootProps()}
    >
      {attachments.length > 0 && (
        <ul>
          {attachments.map((a) => (
            <li
              key={a.file}
              className="group flex items-center space-x-3 truncate py-1"
            >
              <Link
                target="blank"
                href={imageUrl(
                  a.file,
                  pickBy({ dl: "1", name: a.name }) as Record<string, string>,
                )}
                className="text-sm"
              >
                {a.name || a.file}
              </Link>
              <button
                type="button"
                className="hidden group-hover:inline-flex group-focus:inline-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(a);
                }}
              >
                <Trash className="size-4 text-muted-foreground" />
              </button>
            </li>
          ))}
        </ul>
      )}

      <div className="flex items-center gap-4">
        <div>
          {uploadRequest.isLoading ? (
            <Loader className="size-5 animate-spin" />
          ) : (
            <Paperclip className="size-5 text-primary" />
          )}
        </div>
        <div className="flex-grow">
          {placeholder && (
            <p className="flex-grow text-sm text-muted-foreground">
              {placeholder}
            </p>
          )}
        </div>
      </div>

      <input {...getInputProps()} />
    </div>
  );
}
