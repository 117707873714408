import { ProjectOption } from "avail-types";
import { useArchiveProjectOption } from "@/api/project-options";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Trash } from "lucide-react";
import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Form, FormControl, FormItem, FormLabel } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export default function ProjectOptionArchiveButton({
  option,
}: {
  option: ProjectOption;
}) {
  const form = useForm({
    resolver: zodResolver(z.object({ note: z.string() })),
    defaultValues: { note: "" },
  });

  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const archiveRequest = useArchiveProjectOption();

  const onSubmit = () =>
    archiveRequest
      .mutateAsync({
        optionId: option.id,
        note,
      })
      .then(() => {
        setNote("");
        setOpen(false);
      });

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <Trash className="size-5 text-muted-foreground" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <AlertDialogHeader>
              <AlertDialogTitle>Archive Option</AlertDialogTitle>
            </AlertDialogHeader>

            <div className="py-4">
              <FormItem>
                <FormLabel>Optionally, leave a note:</FormLabel>
                <FormControl>
                  <Textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="min-h-[80px]"
                    autoFocus
                  />
                </FormControl>
              </FormItem>
            </div>

            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <Button isLoading={archiveRequest.isLoading} type="submit">
                Archive
              </Button>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
