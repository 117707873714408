import { NavLink } from "react-router-dom";
import { useAppState } from "@/context/AppContext";
import { Disclosure } from "@headlessui/react";
import { cn } from "@/lib/utils";
import { ChevronRightIcon, LucideIcon } from "lucide-react";
import SidenavItem from "@/components/navigation/SidenavItem";
import { MembershipRole } from "avail-types";

interface BaseNavItem {
  name: string;
  icon?: LucideIcon;
  count?: number;
  hrefMatches?: string[];
  hideForRoles?: MembershipRole[];
}

interface NavItemWithoutChildren extends BaseNavItem {
  href: string;
}

interface NavItemWithChildren extends BaseNavItem {
  children: NavItem[];
}

export type NavItem = NavItemWithoutChildren | NavItemWithChildren;

export default function SidenavLink({
  item,
  isCollapsed,
  isChild = false,
}: {
  item: NavItem;
  isCollapsed?: boolean;
  isChild?: boolean;
}) {
  const { membership } = useAppState();

  if (item.hideForRoles?.includes(membership.role)) {
    return null;
  }

  if ("children" in item) {
    return (
      <Disclosure as="div">
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full">
              <SidenavItem
                Icon={item.icon}
                isCollapsed={isCollapsed}
                isChild={isChild}
                label={item.name}
              >
                <ChevronRightIcon
                  className={cn(
                    open && "rotate-90",
                    "ml-auto size-5 shrink-0 text-drawer-foreground/75 transition-transform",
                  )}
                  aria-hidden="true"
                />
              </SidenavItem>
            </Disclosure.Button>
            <Disclosure.Panel
              as="ul"
              className="ml-7 mt-1 space-y-0.5 border-l border-white border-opacity-10 pl-2"
            >
              {item.children.map((subItem) => (
                <SidenavLink
                  key={subItem.name}
                  item={subItem}
                  isCollapsed={isCollapsed}
                  isChild
                />
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <li key={item.name}>
      <NavLink to={item.href}>
        {({ isActive }) => (
          <SidenavItem
            Icon={item.icon}
            count={item.count}
            isActive={isActive}
            isCollapsed={isCollapsed}
            isChild={isChild}
            label={item.name}
          />
        )}
      </NavLink>
    </li>
  );
}
