import { useParams } from "react-router-dom";
import { useTitle } from "@/lib/title";
import { useGetTeam, useUpdateTeam } from "@/api/teams";
import { Loader } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import TeamForm from "@/components/gifting/teams/TeamForm";
import TeamMembers from "@/components/gifting/teams/TeamMembers";

export default function BudgetDetail() {
  const { id } = useParams();
  const { data: team, isLoading } = useGetTeam(Number(id));
  const updateRequest = useUpdateTeam();

  useTitle(team?.name, "Budget");

  if (isLoading) {
    return <Loader className="size-6 animate-spin" />;
  }

  if (!team) {
    return null;
  }

  return (
    <div className="flex-1 space-y-4">
      <div className="grid gap-4 xl:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle>Edit Budget</CardTitle>
          </CardHeader>
          <CardContent>
            <TeamForm
              defaultValues={team}
              onSubmit={(payload) =>
                updateRequest.mutateAsync({
                  id: Number(id),
                  ...payload,
                })
              }
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Budget Members</CardTitle>
          </CardHeader>
          <TeamMembers team={team} />
        </Card>
      </div>
    </div>
  );
}
