import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Tags } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useGetProductById } from "@/api/products";
import { useGetCollectionById } from "@/api/collection";
import { getItemImage, getItemName } from "@/lib/projects";
import pluralize from "pluralize";
import { ProjectItemPayload } from "avail-types";

export default function ProjectItemRow({
  item,
  setItem,
}: {
  item: ProjectItemPayload;
  setItem: (i: ProjectItemPayload) => void;
}) {
  const { data: collection } = useGetCollectionById(item.collection_id);
  const { data: product } = useGetProductById(item.product_id);

  const id = item.collection_id || item.product_id;

  return (
    <li className="relative flex flex-col gap-8 rounded-md border bg-card px-6 py-4 sm:flex-row sm:items-center">
      <Avatar className="flex-0 h-32 w-28 rounded-none xl:h-40 xl:w-36">
        <AvatarImage
          src={getItemImage({ product, collection })}
          className="object-cover"
        />
        <AvatarFallback>
          <Tags className="size-6" />
        </AvatarFallback>
      </Avatar>
      <div className="flex w-full flex-col">
        <div className="text-lg font-semibold md:text-xl">
          {getItemName({ product, collection })}
        </div>

        {item.decoration?.location ? (
          <div className="mt-1 text-sm text-muted-foreground">
            {item.decoration.location} &bull; {item.decoration.decoration}{" "}
            &bull; {pluralize("Color", item.decoration.complexity || 1, true)}
          </div>
        ) : item.decoration?.complexity ? (
          <div className="mt-1 text-sm text-muted-foreground">
            {pluralize("Color", item.decoration.complexity, true)} Logo
          </div>
        ) : null}

        <div className="mb-2 mt-4">
          <Label htmlFor={`notes-${id}`}>Notes</Label>
          <Textarea
            id={`notes-${id}`}
            value={item.notes || ""}
            onChange={(e) => {
              setItem({ ...item, notes: e.target.value });
            }}
            placeholder="Provide any additional details such as preferred colors, materials, or brands."
          />
        </div>
      </div>
    </li>
  );
}
