import Gate from "@/components/roles/Gate";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import MemberSelect from "@/components/selects/MemberSelect";
import { UseFormReturn } from "react-hook-form";
import { useUser } from "@/context/AppContext";

export default function SenderField({ form }: { form: UseFormReturn<any> }) {
  const user = useUser();

  return (
    <Gate role={["avail", "avail_incognito", "admin"]}>
      <FormField
        control={form.control}
        name="sender_id"
        render={({ field }) => (
          <>
            <FormItem>
              <div className="flex items-center space-x-2">
                <Switch
                  checked={field.value != null}
                  onCheckedChange={(checked) =>
                    field.onChange(checked ? user.id : null)
                  }
                />
                <FormLabel variant="checkbox">Send as another user</FormLabel>
              </div>
            </FormItem>
            {field.value != null && (
              <FormItem className="w-[400px]">
                <FormLabel>Sender</FormLabel>
                <FormControl>
                  <MemberSelect
                    value={field.value}
                    onChange={field.onChange}
                    asUserId
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          </>
        )}
      />
    </Gate>
  );
}
