import { XIcon } from "lucide-react";
import { CustomerMembership } from "avail-types";
import UserAvatar from "@/components/account/UserAvatar";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useAppState } from "@/context/AppContext";
import { useRemoveMembershipRequest } from "@/api/membership";
import MembershipRole from "@/components/account/MemebershipRole";
import UserLabel from "@/components/account/UserLabel";

export default function TeamMemberRow({
  membership,
  canInvite,
  canLeave,
  actions,
}: {
  membership: CustomerMembership;
  canInvite: boolean;
  canLeave: boolean;
  actions?: React.ReactNode;
}) {
  const { user } = useAppState();

  const removeMembershipRequest = useRemoveMembershipRequest();

  return (
    <li className="flex items-center space-x-3 px-5 py-3">
      <UserAvatar user={membership.user} />
      <div className="flex-grow flex-col">
        <div className="font-medium">
          <UserLabel user={membership.user} />
        </div>
        <div className="text-sm text-muted-foreground">
          {membership.user.email}
        </div>
      </div>
      <div className="ml-auto flex items-center space-x-2">
        <MembershipRole membership={membership} canInvite={canInvite} />
        {canInvite && membership.user.id !== user.id && (
          <AlertDialog>
            <AlertDialogTrigger>
              <XIcon className="size-4 text-slate-500" />
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Remove User</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want remove this user from your team?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <Button
                  variant="destructive"
                  isLoading={removeMembershipRequest.isLoading}
                  onClick={() => removeMembershipRequest.mutate(membership.id)}
                >
                  Remove
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
        {canLeave && membership.user.id === user.id && (
          <AlertDialog>
            <AlertDialogTrigger>
              <XIcon className="size-4 text-slate-500" />
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Leave Team</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to leave this team?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <Button
                  variant="destructive"
                  isLoading={removeMembershipRequest.isLoading}
                  onClick={() =>
                    removeMembershipRequest
                      .mutateAsync(membership.id)
                      .then(() => {
                        window.location.reload();
                      })
                  }
                >
                  Leave
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
        {actions}
      </div>
    </li>
  );
}
