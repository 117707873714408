import ProjectName from "@/components/projects/create/ProjectName";
import ProjectReview from "@/components/projects/create/ProjectReview";
import ProjectItems from "@/components/projects/create/ProjectItems";
import pluralize from "pluralize";
import { useCreateProject } from "@/api/projects";
import { useAppContext, useSettings } from "@/context/AppContext";
import { useTitle } from "@/lib/title";
import WizardPage, { WizardStep } from "@/components/wizard/WizardPage";
import WebstoreTimeline from "@/components/projects/create/WebstoreTimeline";
import { ProjectCreatePayload } from "avail-types";
import { CREATE_PROJECT_STORAGE_KEY } from "@/constants/project";
import { useNavigate } from "react-router-dom";

export default function WebstoreCreate() {
  useTitle("Request Webstore");

  const createProjectRequest = useCreateProject();
  const { membership } = useAppContext();
  const navigate = useNavigate();
  const settings = useSettings();

  const handleSubmit = (values: ProjectCreatePayload) => {
    if (membership) {
      return createProjectRequest.mutateAsync(values);
    }
    localStorage.setItem(CREATE_PROJECT_STORAGE_KEY, JSON.stringify(values));
    const searchParams = new URLSearchParams();
    searchParams.set("redirect_to", "/projects/submit");
    navigate("/register?" + searchParams.toString());
    return Promise.resolve();
  };

  const steps: WizardStep<ProjectCreatePayload>[] = [
    {
      name: "Items",
      description: (v) =>
        v.items && v.items.length > 0
          ? pluralize("item", v.items.length, true)
          : "What items would you like on the store?",
      isComplete: (v) => Boolean(v.items && v.items.length > 0),
      component: (props) => <ProjectItems {...props} projectType="webstore" />,
    },
    {
      name: "Webstore Name",
      description: (v) => v.name || "What should we name this store?",
      isComplete: (v) => !!v.name,
      component: (props) => <ProjectName {...props} projectType="webstore" />,
    },
    {
      name: "Timeline",
      description: (v) => v.name || "When are you looking to go live?",
      isComplete: (v) => !!v.name,
      component: WebstoreTimeline,
    },
    {
      name: "Review & Submit",
      description: "What else should we know?",
      isComplete: (v) => Boolean(v.description),
      component: (props) => <ProjectReview {...props} projectType="webstore" />,
    },
  ];

  return (
    <WizardPage
      title="Request a Webstore"
      sessionStorageKey="webstore-create"
      isLoading={createProjectRequest.isLoading}
      onSubmit={handleSubmit}
      defaultValues={{
        type: "webstore",
        budget_qty: 0,
        budget_amount: 0,
        budget_type: "total",
        shipping_mode: "kits",
        attachments: [],
        items: [],
        fields: settings.project_fields.map((field) => ({
          customer_project_field_id: field.id,
          value: "",
        })),
      }}
      steps={steps}
    />
  );
}
