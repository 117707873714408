import { cn } from "@/lib/utils";
import { CheckCircleIcon } from "lucide-react";
import { Card } from "@/components/ui/card";

export default function GiftSelectableCard({
  onSelect,
  isSelected,
  className,
  children,
}: {
  onSelect?: () => void;
  isSelected: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  if (!onSelect) {
    return <div className={cn(className)}>{children}</div>;
  }

  return (
    <Card
      role="button"
      className={cn(
        "relative cursor-pointer px-4 py-3",
        isSelected && "outline outline-primary",
        className,
      )}
      onClick={onSelect}
    >
      {isSelected && (
        <CheckCircleIcon className="absolute right-5 top-4 size-5 text-primary" />
      )}
      {children}
    </Card>
  );
}
