import SkuSelect from "@/components/selects/SkuSelect";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { SyntheticEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";

export default function AddFulfillmentOrderItemForm({
  onSubmit,
}: {
  onSubmit: (variantId: number, qty: number) => void;
}) {
  const [searchParams] = useSearchParams();
  const [variantId, setVariantId] = useState<number | null>(
    searchParams.has("variant") ? Number(searchParams.get("variant")) : null,
  );
  const [qty, setQty] = useState<string>("1");

  const handleSubmit = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (variantId && qty) {
      onSubmit(variantId, Number(qty));
      setVariantId(null);
      setQty("1");
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Find Items</CardTitle>
      </CardHeader>
      <CardContent className="space-y-5">
        <div className="flex flex-col items-stretch justify-stretch gap-1">
          <Label>SKU</Label>
          <SkuSelect
            value={variantId}
            onChange={setVariantId}
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-1">
          <Label htmlFor="quantity-field">Quantity</Label>
          <Input
            type="number"
            inputMode="numeric"
            step={1}
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            onFocus={(e) => e.target.select()}
          />
        </div>
      </CardContent>
      <CardFooter>
        <Button disabled={!variantId || !qty} onClick={handleSubmit}>
          Add to Order
        </Button>
      </CardFooter>
    </Card>
  );
}
