import { UseFormReturn } from "react-hook-form";
import { GiftingProductPayload } from "avail-types";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CurrencyInput from "@/components/ui/currency-input";
import { useEffect } from "react";
import { curr } from "@/lib/curr";
import TangoAccount from "@/components/gifting/TangoAccount";

export default function GiftCardType({
  form,
  isCreate,
}: {
  form: UseFormReturn<GiftingProductPayload>;
  isCreate?: boolean;
}) {
  const amount = form.watch("variants.0.price");

  useEffect(() => {
    if (amount && isCreate) {
      form.setValue("name", `${curr(amount).replace(".00", "")} Gift Card`);
    }
  }, [form, amount, isCreate]);

  return (
    <>
      <div className="space-y-4">
        <FormField
          control={form.control}
          name="variants.0.price"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Amount</FormLabel>
              <FormControl>
                <CurrencyInput {...field} value={field.value || ""} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tango_utid"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tango UTID (optional)</FormLabel>
              <FormControl>
                <Input {...field} value={field.value || ""} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      {!isCreate && (
        <div className="mt-auto border-t pt-4">
          <TangoAccount />
        </div>
      )}
    </>
  );
}
