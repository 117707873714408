import Container from "@/components/ui/container";
import Logo from "@/components/auth/Logo";
import { useGetGiftLink } from "@/api/gifts";
import { Link, useParams } from "react-router-dom";
import { GiftLink } from "avail-types";
import { Alert } from "@/components/ui/alert";
import { Skeleton } from "@/components/ui/skeleton";
import { useTheme } from "@/lib/colors";
import { useEffect } from "react";
import { useTitle } from "@/lib/title";

export default function GiftLinkWrapper({
  children,
}: {
  children: (gift: GiftLink) => React.ReactNode;
}) {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetGiftLink(id!);
  useTheme(data ? data.customer : { color: null, drawer_color: null });
  useEffect(() => {
    window.Beacon("destroy");
  }, []);

  useTitle(data?.subject, "Gift Link");

  return (
    <main className="bg-white">
      <Container className="min-h-[calc(100vh-3rem)] max-w-6xl pb-24 pt-4 lg:pt-6">
        {isError ? (
          <Alert variant="destructive">
            There was an error loading this page.
          </Alert>
        ) : isLoading ? (
          <Skeleton className="h-8 w-1/2" />
        ) : data ? (
          <>
            {data.customer.logo && (
              <Link to={data.url}>
                <img
                  src={data.customer.logo}
                  className="mb-6 h-12 w-64 object-contain object-left"
                />
              </Link>
            )}
            {children(data)}
          </>
        ) : null}
      </Container>

      <footer className="w-full bg-white px-6 py-3 text-sm">
        <Container className="max-w-6xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <span>Powered by</span>
              <a
                href="https://www.availswag.com"
                className="inline-block"
                target="_blank"
              >
                <Logo className="h-6" />
              </a>
            </div>
            <nav className="space-x-4 text-xs">
              <a
                href="https://www.availswag.com/privacy-policy"
                className="text-muted-foreground"
                target="_blank"
              >
                Privacy
              </a>
              <a
                href="mailto:support@availlabs.com"
                className="text-muted-foreground"
              >
                Support
              </a>
            </nav>
          </div>
        </Container>
      </footer>
    </main>
  );
}
