import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useTitle } from "@/lib/title";
import GiftsTable from "@/components/gifting/GiftsTable";
import { ChevronDown, SendIcon } from "lucide-react";
import GiftQueues from "@/components/gifting/GiftQueues";
import Gate from "@/components/roles/Gate";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import GiftingBudgetProgressBar from "@/components/gifting/GiftingBudgetProgressBar";

export default function Gifts() {
  useTitle("Gifting");

  const actions = (
    <div className="flex items-center gap-12">
      <GiftingBudgetProgressBar />
      <div className="flex">
        <Link to="/gifts/send">
          <Button className="rounded-r-none">
            <SendIcon className="mr-2 size-4" />
            Send Gift
          </Button>
        </Link>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="rounded-l-none border-l border-l-black/25 px-3">
              <ChevronDown className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <Link to="/gifts/bulk-send">
              <DropdownMenuItem>Bulk Send</DropdownMenuItem>
            </Link>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );

  return (
    <div className="flex-1 space-y-5">
      <Toolbar actions={actions}>
        <Title>Gifts</Title>
      </Toolbar>

      <Gate role={["avail", "avail_incognito"]}>
        <GiftQueues />
      </Gate>

      <GiftsTable />
    </div>
  );
}
