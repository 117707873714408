import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import { useTitle } from "@/lib/title";
import { useParams } from "react-router-dom";
import SplashScreen from "@/pages/SplashScreen";
import { useGetGiftingProduct } from "@/api/gifting-products";
import GiftingProductEditForm from "@/components/gifting/products/GiftingProductEditForm";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreVerticalIcon, TrashIcon } from "lucide-react";
import GiftingProductDeleteDialog from "@/components/gifting/products/GiftingProductDeleteDialog";

export default function GiftingProductDetail() {
  const { id } = useParams();
  const { data } = useGetGiftingProduct(Number(id));
  useTitle(data?.name || "Gifting Product");

  if (!data) {
    return <SplashScreen />;
  }

  return (
    <div className="flex-1 space-y-6">
      <Toolbar
        actions={
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="print:hidden">
              <Button size="icon" variant="outline">
                <MoreVerticalIcon className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <GiftingProductDeleteDialog product={data}>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  <TrashIcon className="mr-2 h-4 w-4" />
                  Delete
                </DropdownMenuItem>
              </GiftingProductDeleteDialog>
            </DropdownMenuContent>
          </DropdownMenu>
        }
      >
        <Title>{data.name}</Title>
      </Toolbar>

      <GiftingProductEditForm product={data} />
    </div>
  );
}
