import { useGetCustomerBrandFiles } from "@/api/brand-files";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { Link } from "react-router-dom";
import pluralize from "pluralize";
import BrandCardLabel from "@/components/brand/BrandCardLabel";
import BrandCard from "@/components/brand/BrandCard";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

export default function BrandReminder() {
  const { data: files = [], isLoading } = useGetCustomerBrandFiles();
  const logos = files.filter((file) => file.type === "logo" && file.image);

  return (
    <Popover>
      <PopoverTrigger disabled={isLoading}>
        <InformationCircleIcon className="h-5 w-5 text-blue-500" />
      </PopoverTrigger>
      <PopoverContent className="w-96 text-sm" align="start">
        <CardTitle className="mb-1 text-lg">Brand Logos</CardTitle>
        <CardDescription>
          Your brand has {pluralize("saved logo", logos.length, true)}. Saved
          logos are automatically included in every project request.
        </CardDescription>

        {logos.length > 0 && (
          <div className="mt-4 grid grid-cols-3 gap-1">
            {logos.map((file) => (
              <BrandCard key={file.id}>
                <img
                  src={file.image!}
                  className="h-full w-full object-contain"
                />

                <BrandCardLabel className="truncate px-2 py-1 text-xs">
                  {file.name}
                </BrandCardLabel>
              </BrandCard>
            ))}
          </div>
        )}

        <Button variant="link" asChild className="mt-4 h-auto p-0" size="sm">
          <Link to="/brand">
            Manage Your Brand <ArrowRight className="ml-1.5 size-4" />{" "}
          </Link>
        </Button>
      </PopoverContent>
    </Popover>
  );
}
