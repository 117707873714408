import { Link } from "react-router-dom";
import { useAppState } from "@/context/AppContext";
import {
  ClipboardList,
  FolderKanban,
  GiftIcon,
  HelpCircle,
  Home,
  LinkIcon,
  Palette,
  QrCode,
  Receipt,
  Shirt,
  ShoppingCart,
  Store,
  Tags,
} from "lucide-react";
import ActivityNavItem from "@/components/notifications/ActivityNavItem";
import { HTMLAttributes, useState } from "react";
import { cn } from "@/lib/utils";
import SidenavLink, { NavItem } from "@/components/navigation/SidenavLink";
import Logo from "@/components/auth/Logo";
import SidenavItem from "@/components/navigation/SidenavItem";
import AccountNavItem from "@/components/navigation/AccountNavItem";
import { ALLOWS_SIGNUP } from "@/constants/services";
import useDebounce from "@/hooks/useDebounce";
import { curr } from "@/lib/curr";

interface SidenavProps extends HTMLAttributes<HTMLDivElement> {
  isCollapsed?: boolean;
}

export default function Sidenav({
  className,
  isCollapsed: isCollapsedProp,
  ...other
}: SidenavProps) {
  const { webstores, settings, credit_balance: creditBalance } = useAppState();
  const [isHovering, setIsHovering] = useState(false);
  const isCollapsed = !isHovering && isCollapsedProp;
  const shouldShowBadge = useDebounce(!isCollapsed, 100);

  const navigation: (NavItem | false | null | undefined)[] = [
    { name: "Dashboard", href: "/", icon: Home },
    settings.projects_enabled && {
      name: "Projects",
      href: "/projects",
      icon: FolderKanban,
    },
    settings.portal_shopping_mode
      ? {
          name: "Shop",
          href: "/shop",
          icon: ShoppingCart,
          hideForRoles: ["guest"],
        }
      : settings.projects_enabled
      ? {
          name: "Products",
          href: "/collections",
          icon: Tags,
        }
      : null,
    {
      name: "Orders",
      href: "/orders",
      icon: ClipboardList,
      hideForRoles: ["guest"],
    },
    {
      name: "Invoices",
      href: "/invoices",
      icon: Receipt,
      hideForRoles: ["guest"],
    },
    ALLOWS_SIGNUP && {
      name: "Gifting",
      icon: GiftIcon,
      children: [
        {
          name: "Gifts",
          href: "/gifts",
        },
        {
          name: "Catalog",
          href: "/gifting-products",
          hideForRoles: ["guest", "member"],
        },
        {
          name: "Templates",
          href: "/gifting-templates",
          hideForRoles: ["guest", "member"],
        },
        {
          name: "Budgets",
          href: "/gifting-budgets",
          hideForRoles: ["guest", "member"],
        },
      ],
    },
    settings.is_fulfillment && {
      name: "Inventory",
      icon: QrCode,
      hideForRoles: ["guest"],
      children: [
        {
          name: "SKUs",
          href: "/inventory",
        },
        {
          name: "Fulfillment Orders",
          href: "/fulfillment-orders",
        },
        {
          name: "Inventory Returns",
          href: "/inventory-returns",
        },
        {
          name: "Create Fulfillment Order",
          href: "/send",
        },
      ],
    },
    webstores.length
      ? {
          name: "Stores",
          href: "/webstores",
          icon: Store,
          count: webstores.length,
          hideForRoles: ["guest"],
        }
      : null,
    { name: "Closet", href: "/closet", icon: Shirt },
    { name: "Brand", href: "/brand", icon: Palette, hideForRoles: ["guest"] },
    ALLOWS_SIGNUP && {
      name: "Integrations",
      href: "/integrations",
      icon: LinkIcon,
      hideForRoles: ["guest", "member"],
    },
  ];

  return (
    <div
      className={cn(
        "flex flex-shrink-0 transform flex-col gap-y-5 overflow-y-auto bg-drawer px-1 text-drawer-foreground transition-all",
        className,
        isCollapsed ? "w-14" : "w-[17rem]",
        isHovering && isCollapsedProp && "z-40 shadow-r",
      )}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...other}
    >
      <div className="flex shrink-0 items-center px-2 py-4 text-drawer-foreground">
        <Link to="/">
          <Logo
            className={cn(
              "h-8 max-w-40 fill-current object-contain",
              isCollapsed && "w-10 -translate-x-1",
            )}
            useIcon={isCollapsed}
          />
        </Link>
      </div>

      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="space-y-1">
              {navigation.map((item) => {
                if (!item) return null;
                return (
                  <SidenavLink
                    key={item.name}
                    item={item}
                    isCollapsed={isCollapsed}
                  />
                );
              })}
            </ul>
          </li>
          <li className="mt-auto">
            <ul className="space-y-1">
              {ALLOWS_SIGNUP && (
                <>
                  {!isCollapsed && shouldShowBadge && creditBalance > 0 && (
                    <li className="mb-2 rounded-md border border-white/20 px-4 py-3 text-center text-sm">
                      You have{" "}
                      <b className="font-semibold">{curr(creditBalance)}</b> in
                      credits
                    </li>
                  )}
                  <li>
                    <a href="https://help.availswag.com" target="_blank">
                      <SidenavItem
                        Icon={HelpCircle}
                        isCollapsed={isCollapsed}
                        label="Help"
                      />
                    </a>
                  </li>
                </>
              )}
              <li>
                <ActivityNavItem isCollapsed={isCollapsed} />
              </li>
              <li className="-mx-1">
                <AccountNavItem isCollapsed={isCollapsed} />
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
}
