import { usePlanName } from "@/context/AppContext";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CheckCircleIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useGetApiUrl } from "@/lib/auth";
import { cn } from "@/lib/utils";

function PlanFeature({
  children,
  className = "text-primary",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <li className="flex items-center gap-2">
      <CheckCircleIcon className={cn("size-5", className)} />
      {children}
    </li>
  );
}

export default function PriceTable({
  title,
  featureTitle,
}: {
  title?: string;
  featureTitle?: string;
}) {
  const planName = usePlanName();
  const getApiUrl = useGetApiUrl();

  return (
    <div className="mx-auto max-w-4xl">
      <div className="py-12 text-center">
        {featureTitle && (
          <div className="mb-2 font-medium uppercase text-primary">
            {featureTitle}
          </div>
        )}
        <h2 className="mb-4 text-3xl font-semibold">
          {title ?? "Upgrade your plan to access this feature"}
        </h2>
        <p>
          Current Plan: <b>{planName}</b>
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Free</CardTitle>
          </CardHeader>
          {/*<CardContent>*/}
          {/*  <div className="text-4xl font-medium">$0</div>*/}
          {/*</CardContent>*/}
          <CardContent>
            <ul className="space-y-2">
              <PlanFeature className="text-slate-900">
                Product Sourcing and Project Management
              </PlanFeature>
              <PlanFeature className="text-slate-900">
                Swag Kitting and Distribution
              </PlanFeature>
              <PlanFeature className="text-slate-900">
                Store and Send Inventory Globally
              </PlanFeature>
              <PlanFeature className="text-slate-900">
                Send Gifts to Individuals or Groups
              </PlanFeature>
              <PlanFeature className="text-slate-900">
                Gifting Templates
              </PlanFeature>
            </ul>
          </CardContent>
          <CardContent>
            {planName.includes("Free") ? (
              <Button variant="outline" className="w-full" disabled>
                Current Plan
              </Button>
            ) : (
              <Button variant="outline" className="w-full" asChild>
                <a href={getApiUrl("billing")}>Downgrade</a>
              </Button>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Enterprise</CardTitle>
          </CardHeader>
          {/*<CardContent>*/}
          {/*  <div className="text-4xl font-medium">*/}
          {/*    $5,000*/}
          {/*    <span className="ml-2 text-sm font-normal text-muted-foreground">*/}
          {/*      per year*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</CardContent>*/}
          <CardContent>
            <ul className="space-y-2">
              <PlanFeature>Everything in Free</PlanFeature>
              <PlanFeature>CRM, HRIS, and Slack/Teams Integrations</PlanFeature>
              <PlanFeature>Budget Management</PlanFeature>
              <PlanFeature>Send Digital Gift Cards</PlanFeature>
              <PlanFeature>Custom Email Domains</PlanFeature>
            </ul>
          </CardContent>
          <CardContent>
            {planName.includes("Enterprise") ? (
              <Button className="w-full" disabled>
                Current Plan
              </Button>
            ) : (
              <Button
                className="w-full"
                onClick={() => {
                  window.Beacon("open");
                  window.Beacon("prefill", {
                    subject: "Upgrade to Enterprise Plan",
                    text: "Hello, I would like to talk with someone about upgrading",
                  });
                }}
              >
                Request Trial
              </Button>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
