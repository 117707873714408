import { useNavigate } from "react-router-dom";
import OrderPreviewTable from "@/components/shop/OrderPreviewTable";
import { Card } from "@/components/ui/card";
import { useAppState } from "@/context/AppContext";
import { Button } from "@/components/ui/button";
import axios from "axios";
import { Cart, CustomerOrder, addressPayloadSchema } from "avail-types";
import AddressFormSection from "@/components/addresses/AddressFormSection";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useQueryClient } from "@tanstack/react-query";
import { Label } from "@/components/ui/label";
import { FormErrors } from "@/components/ui/form-errors";
import { CART_KEY } from "@/api/cart";

const formSchema = z.object({
  descriptions: z.array(z.string().optional()).optional(),
  address: addressPayloadSchema,
});

export default function CheckoutForm({ cart }: { cart: Cart }) {
  const { terms } = useAppState().settings;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      descriptions: cart.orders.map((_, i) => `Self-Service Order #${i + 1}`),
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    return axios
      .post<{ data: CustomerOrder[] } | { url: string }>("/cart/place", values)
      .then(({ data }) => {
        queryClient.invalidateQueries([CART_KEY]);
        if ("url" in data) {
          window.location.href = data.url;
        } else {
          navigate(`/orders/${data.data[0]!.code}`);
        }
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {cart.orders.map((order, index) => (
          <div key={`order-${index}`}>
            <FormField
              control={form.control}
              name={`descriptions.${index}`}
              render={({ field }) => (
                <FormItem className="mb-2 w-96 max-w-full">
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="text-lg font-medium"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <OrderPreviewTable order={order} />
          </div>
        ))}

        <AddressFormSection form={form} label="Shipping Address" size="lg" />

        <div className="space-y-2">
          <Label>Payment</Label>
          <Card className="p-4">
            {terms.is_upfront
              ? "Payment is required upfront for this order. You will be taken to a payment page after you click submit below."
              : `Terms: ${terms.name}`}
          </Card>
        </div>

        <FormErrors form={form} />

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Submit
        </Button>
      </form>
    </Form>
  );
}
