import { useGetGiftingTemplates } from "@/api/gifting-templates";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useCallback } from "react";
import {
  GiftVariables,
  renderTemplate,
} from "@/components/gifting/templates/utils";

export default function InsertFromTemplate({
  type,
  variables,
  setValue,
}: {
  type: "subject" | "message" | "gift_note";
  variables: GiftVariables | undefined;
  setValue: (value: string) => void;
}) {
  const { data } = useGetGiftingTemplates(type);

  const handleSelect = useCallback(
    (template: string) => {
      setValue(variables ? renderTemplate(template, variables) : template);
    },
    [setValue, variables],
  );

  if (!data || !data.length) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="xs" variant="link" className="h-5">
          Insert from template
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {data.map((template) => (
          <DropdownMenuItem
            key={template.id}
            onSelect={() => {
              handleSelect(template.template);
            }}
          >
            {template.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
