import { ProjectOption } from "avail-types";
import { useUpdateProjectOption } from "@/api/project-options";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { ArrowUpDownIcon } from "lucide-react";
import { useState } from "react";
import { projectOptionToPayload, getItemName } from "@/lib/projects";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useProject } from "@/context/ProjectContext";
import { Label } from "@/components/ui/label";

export default function ProjectOptionMoveButton({
  option,
}: {
  option: ProjectOption;
}) {
  const [open, setOpen] = useState(false);
  const updateRequest = useUpdateProjectOption(option.id);
  const [projectItemId, setProjectItemId] = useState(option.project_item_id);
  const { items } = useProject();

  const onSubmit = () => {
    updateRequest
      .mutateAsync({
        ...projectOptionToPayload(option),
        project_item_id: projectItemId,
      })
      .then(() => {
        setOpen(false);
      });
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <ArrowUpDownIcon className="size-5 text-muted-foreground" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Move Option to Different Category</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="space-y-3 py-4">
          Which category would you like to move this option to?
        </div>
        <RadioGroup
          value={String(projectItemId)}
          onValueChange={(v) => setProjectItemId(Number(v))}
        >
          {items.map((item) => (
            <div className="flex items-center space-x-2" key={item.id}>
              <RadioGroupItem value={String(item.id)} id={`item-${item.id}`} />
              <Label variant="checkbox" htmlFor={`item-${item.id}`}>
                {getItemName(item)}
              </Label>
            </div>
          ))}
        </RadioGroup>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="default"
            isLoading={updateRequest.isLoading}
            onClick={onSubmit}
          >
            Update
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
