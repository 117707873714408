import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Loader } from "lucide-react";
import { useGetTangoAccount } from "@/api/tango";

export default function TangoAccount() {
  const { data: account, isLoading } = useGetTangoAccount();

  if (isLoading) {
    return <Loader />;
  }

  if (!account) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="min-w-0 flex-grow">
        <div>{account.name}</div>
        <div className="text-sm text-muted-foreground">
          Balance: {curr(account.balance)}
        </div>
      </div>
      <div>
        <Button
          size="sm"
          variant="ghost"
          onClick={() => {
            window.Beacon("open");
            window.Beacon("prefill", {
              subject: "Fund Gift Card Account",
              text: "I would like to fund my gift card account. Can you provide me ACH instructions?",
            });
          }}
        >
          Fund
        </Button>
      </div>
    </div>
  );
}
