import SearchDesignLayouts from "@/components/gifting/products/designLayouts/SearchDesignLayouts";
import { Button } from "@/components/ui/button";
import { usePortalShoppingMode } from "@/context/AppContext";
import { useShopState } from "@/context/ShopContext";
import { Navigate } from "react-router-dom";

export default function ShopDecoration() {
  const { updateShopState, product } = useShopState();
  const shoppingMode = usePortalShoppingMode();

  if (!product) {
    return <Navigate to="/shop/products" />;
  }

  return (
    <div>
      {shoppingMode !== "decorated" && (
        <Button
          variant="outline"
          style={{ float: "right" }}
          onClick={() =>
            updateShopState({ skipDecoration: true, designLayout: undefined })
          }
        >
          Skip Decoration &rarr;
        </Button>
      )}
      <SearchDesignLayouts
        onSelect={(l) => updateShopState({ designLayout: l })}
      />
    </div>
  );
}
