import {
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { createColumnHelper } from "@tanstack/react-table";
import { GiftingTemplate } from "avail-types";
import { useMemo } from "react";
import { DataTable } from "@/components/ui/data-table";
import { Badge } from "@/components/ui/badge";
import startCase from "lodash/startCase";
import { formatDate } from "@/lib/dates";
import GiftingTemplateDeleteButton from "@/components/gifting/templates/GiftingTemplateDeleteButton";
import { PencilIcon } from "lucide-react";
import { GIFTING_TEMPLATES_KEY } from "@/api/gifting-templates";

const columnHelper = createColumnHelper<GiftingTemplate>();

export default function GiftingTemplatesTable({
  onEdit,
}: {
  onEdit: (template: GiftingTemplate) => void;
}) {
  const tableState = useManualTableState([
    {
      id: "name",
      desc: false,
    },
  ]);
  const templatesQuery = useManualTableQuery<GiftingTemplate>(
    GIFTING_TEMPLATES_KEY,
    "/gifting-templates",
    tableState,
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ getValue, row }) => (
          <button
            type="button"
            className="font-medium hover:underline"
            onClick={() => onEdit(row.original)}
          >
            {getValue()}
          </button>
        ),
      }),
      columnHelper.accessor("field", {
        header: "Field",
        cell: ({ getValue }) => <Badge>{startCase(getValue())}</Badge>,
      }),
      columnHelper.accessor("created_at", {
        header: "Created At",
        cell: ({ getValue }) => formatDate(getValue()),
      }),
      columnHelper.display({
        id: "actions",
        header: "",
        cell: ({ row }) => (
          <div className="flex items-center gap-3">
            <button
              type="button"
              className="text-muted-foreground hover:text-black"
              onClick={() => onEdit(row.original)}
            >
              <PencilIcon className="size-4" />
            </button>
            <GiftingTemplateDeleteButton template={row.original} />
          </div>
        ),
      }),
    ],
    [onEdit],
  );
  const table = useManualTable(tableState, templatesQuery, columns);
  return <DataTable table={table} isLoading={templatesQuery.isLoading} />;
}
