import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import { useTitle } from "@/lib/title";
import { useGetIntegrations } from "@/api/integrations";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { CheckCircleIcon, ExternalLinkIcon } from "lucide-react";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { useGetApiUrl } from "@/lib/auth";
import { useHasFeature } from "@/context/AppContext";
import { useState } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import PriceTable from "@/components/account/PriceTable";
import { DialogClose } from "@radix-ui/react-dialog";

export default function Integrations() {
  useTitle("Integrations");
  const { data = [], isLoading } = useGetIntegrations();
  const getApiUrl = useGetApiUrl();
  const hasFeature = useHasFeature();
  const [showPriceTable, setShowPriceTable] = useState(false);

  return (
    <div className="flex-1 space-y-6">
      <Toolbar>
        <Title>Integrations</Title>
      </Toolbar>

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
          {data.map((integration) => {
            return (
              <Card className="flex flex-col items-center">
                <div className="flex w-full items-center justify-center border-b bg-primary/5 p-4">
                  <IntegrationIcon type={integration.type} size="size-24" />
                </div>
                <div className="px-5 py-4">
                  <h4 className="text-xl font-semibold">{integration.title}</h4>
                  <p className="mb-5 mt-1 text-sm text-muted-foreground">
                    {integration.description}
                  </p>

                  <Button className="w-full" asChild variant="outline">
                    <a
                      href={
                        hasFeature("integrations")
                          ? getApiUrl(`integrations/${integration.type}`)
                          : "#"
                      }
                      onClick={(e) => {
                        if (!hasFeature("integrations")) {
                          e.preventDefault();
                          setShowPriceTable(true);
                        }
                      }}
                    >
                      {integration.is_connected && (
                        <CheckCircleIcon className="mr-2 h-5 w-5 text-green-500" />
                      )}
                      {integration.is_connected ? "Connected" : "Connect"}
                      {!integration.is_connected && (
                        <ExternalLinkIcon className="ml-2 h-5 w-5 text-primary" />
                      )}
                    </a>
                  </Button>
                </div>
              </Card>
            );
          })}
        </div>
      )}

      <Dialog open={showPriceTable} onOpenChange={setShowPriceTable}>
        <DialogContent className="pt-0 sm:max-w-4xl">
          <DialogClose />
          <PriceTable featureTitle="Integrations" />
        </DialogContent>
      </Dialog>
    </div>
  );
}
