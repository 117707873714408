import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { matchPath } from "react-router-dom";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function findLowestNonZeroNumber(arr: string[]) {
  const numbers = arr.map(Number);
  const nonZeroNumbers = numbers.filter((num) => !isNaN(num) && num !== 0);
  const lowestNonZeroNumber = Math.min(...nonZeroNumbers);

  return lowestNonZeroNumber;
}

export function isPermittedGuestLink(pathname: string) {
  const guestRoutes = ["orders/:code", "approvals/:code", "quotes/:code"];

  return (
    guestRoutes.some((path) => matchPath({ path }, pathname)) &&
    !pathname.startsWith("/guest")
  );
}

export function getGuestRoutePath(pathname: string) {
  if (!pathname.startsWith("/guest")) {
    return `/guest${pathname}`;
  }

  return pathname;
}
