import { useProject } from "@/context/ProjectContext";
import pluralize from "pluralize";
import DraftStatus from "@/components/projects/statuses/DraftStatus";
import OptionsRequestedStatus from "@/components/projects/statuses/OptionsRequestedStatus";
import OptionsProvidedStatus from "@/components/projects/statuses/OptionsProvidedStatus";
import ProofsRequestedStatus from "@/components/projects/statuses/ProofsRequestedStatus";
import OrderedStatus from "@/components/projects/statuses/OrderedStatus";
import { Alert } from "@/components/ui/alert";
import { Step, Steps } from "@/components/ui/steps-horizontal";
import ProjectAction, {
  ProjectActionProps,
} from "@/components/projects/ProjectAction";
import { Project } from "avail-types";
import { ReactNode } from "react";

interface StatusStep {
  status: Project["status"];
  title: string;
  render: () => ReactNode;
  goToAction?: ProjectActionProps | false;
}

export default function ProjectStatuses() {
  const project = useProject();
  const projectStatus =
    project.status === "options_requested"
      ? "options_provided"
      : project.status === "complete"
      ? "ordered"
      : project.status;

  const STATUSES: StatusStep[] = [
    {
      status: "draft",
      title: "Project Created",
      render: () => <DraftStatus />,
      goToAction: project.status === "options_requested" && {
        status: "draft",
        label: "Revert to Draft",
        description:
          "This will put the project back into the draft status. Our team will hold off on sourcing options until you're ready to proceed.",
        direction: "prev",
      },
    },
    {
      status: "options_provided",
      title: "Review Options",
      render: () =>
        project.status === "options_requested" ? (
          <OptionsRequestedStatus />
        ) : (
          <OptionsProvidedStatus />
        ),
      goToAction: project.status === "proofs_requested" && {
        status: "options_provided",
        label: "Edit Selections",
        description:
          "This will cancel any proof requests for this project, allowing you to change the options you selected.",
        direction: "prev",
      },
    },
    {
      status: "proofs_requested",
      title: `Approve ${pluralize("Proof", project.orders.length)}`,
      render: () => <ProofsRequestedStatus />,
    },
    {
      status: "ordered",
      title: `${pluralize("Order", project.orders.length)} In Progress`,
      render: () => <OrderedStatus />,
    },
  ];
  const currentIndex = STATUSES.findIndex((s) => s.status === projectStatus);

  if (project.status === "cancelled") {
    return (
      <Alert variant="destructive">This project has been cancelled.</Alert>
    );
  }
  return (
    <div className="space-y-4">
      <Steps className="print:hidden">
        {STATUSES.map((step, index) => {
          const children = (
            <Step
              key={step.status}
              index={index}
              title={step.title}
              status={
                index < currentIndex || project.status === "complete"
                  ? "complete"
                  : index === currentIndex
                  ? "current"
                  : "upcoming"
              }
              isLast={index === STATUSES.length - 1}
            />
          );

          if (step.goToAction) {
            return (
              <ProjectAction key={step.status} {...step.goToAction}>
                {children}
              </ProjectAction>
            );
          }

          return children;
        })}
      </Steps>

      <div className="relative space-y-5 print:border-none print:px-0 print:shadow-none">
        {STATUSES[currentIndex]!.render()}
      </div>
    </div>
  );
}
