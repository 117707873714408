import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const DOMAINS_KEY = "domains";

export function useGetDomains() {
  return useQuery([DOMAINS_KEY], () =>
    axios
      .get<{
        data: { domain: string; is_auto: boolean }[];
      }>("/domains")
      .then(({ data }) => data.data),
  );
}
