import ProjectEditableField from "@/components/projects/ProjectEditableField";
import AutoForm from "@/components/ui/auto-form";
import { z } from "zod";
import Attachments from "@/components/attachments/Attachments";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useProject } from "@/context/ProjectContext";
import { useCallback } from "react";
import { AttachmentPayload } from "avail-types";
import { useUpdateProject } from "@/api/projects";
import EnhancedText from "@/components/ui/enhanced-text";
import ProjectFields from "@/components/projects/ProjectFields";
import { canEdit } from "@/lib/projects";
import ClampedText from "@/components/ui/clamped-text";
import ProjectPurchaseOrderField from "@/components/projects/ProjectPurchaseOrderField";
import { Badge } from "@/components/ui/badge";
import startCase from "lodash/startCase";
import { Label } from "@/components/ui/label";
import BrandReminder from "@/components/brand/BrandReminder";
import { attachmentToPayload } from "@/lib/attachments";

export default function ProjectDetails() {
  const project = useProject();
  const updateProject = useUpdateProject(project.id);

  const onNewAttachment = useCallback(
    (attachments: AttachmentPayload[]) => {
      updateProject.mutate({
        attachments: [
          ...project.attachments.map(attachmentToPayload),
          ...attachments,
        ],
      });
    },
    [project, updateProject],
  );

  const onDeleteAttachment = useCallback(
    (attachment: AttachmentPayload) => {
      updateProject.mutate({
        attachments: project.attachments
          .map(attachmentToPayload)
          .filter((a) => attachment.file !== a.file),
      });
    },
    [project, updateProject],
  );

  return (
    <Card className="break-inside-avoid-page">
      <CardHeader className="flex-row justify-between">
        <CardTitle className="text-xl">Project Details</CardTitle>
        {project.type !== "project" && <Badge>{startCase(project.type)}</Badge>}
      </CardHeader>
      <CardContent className="space-y-5 text-sm">
        <ProjectEditableField
          formLabel="Project Description"
          getForm={(
            onSubmit: (v: { description: string }) => Promise<unknown>,
          ) => (
            <AutoForm
              formSchema={z.object({
                description: z.string(),
              })}
              fieldConfig={{
                description: {
                  fieldType: "textarea",
                  inputProps: {
                    className: "h-[14rem]",
                  },
                },
              }}
              defaultValues={{
                description: project.description,
              }}
              onSubmit={onSubmit}
              submitLabel="Save"
            />
          )}
        >
          <ClampedText clampedClass="line-clamp-[10]">
            <EnhancedText
              className={project.description ? "" : "text-muted-foreground"}
            >
              {project.description
                ? project.description.replace(/\n{3,}/g, "\n\n")
                : "Empty project description"}
            </EnhancedText>
          </ClampedText>
        </ProjectEditableField>

        {(canEdit(project) || project.attachments.length > 0) && (
          <div className="print:hidden">
            <Label className="mb-1 flex items-center gap-2">
              Attachments <BrandReminder />
            </Label>
            <Attachments
              className="py-2"
              attachments={project.attachments.map(attachmentToPayload)}
              onNew={onNewAttachment}
              onDelete={onDeleteAttachment}
            />
          </div>
        )}

        <ProjectFields />

        {project.customer_po && <ProjectPurchaseOrderField />}
      </CardContent>
    </Card>
  );
}
