import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import { Button } from "@/components/ui/button";
import { useTitle } from "@/lib/title";
import { PlusCircleIcon } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import GiftingTemplateForm from "@/components/gifting/templates/GiftingTemplateForm";
import { useState } from "react";
import { GiftingTemplate } from "avail-types";
import {
  useCreateGiftingTemplate,
  useUpdateGiftingTemplate,
} from "@/api/gifting-templates";
import GiftingTemplatesTable from "@/components/gifting/templates/GiftingTemplatesTable";

export default function GiftingTemplates() {
  useTitle("Gifting Templates");

  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<GiftingTemplate | null>(null);
  const createRequest = useCreateGiftingTemplate();
  const updateRequest = useUpdateGiftingTemplate();

  const actions = (
    <Button onClick={() => setCreating(true)}>
      <PlusCircleIcon className="mr-2 size-4" />
      New Template
    </Button>
  );

  return (
    <div className="flex-1 space-y-6">
      <Toolbar actions={actions}>
        <Title>Gifting Templates</Title>
      </Toolbar>

      <GiftingTemplatesTable onEdit={setEditing} />

      <Sheet open={Boolean(editing)} onOpenChange={() => setEditing(null)}>
        <SheetContent className="sm:max-w-xl">
          <SheetHeader className="mb-4">
            <SheetTitle>Edit Template</SheetTitle>
          </SheetHeader>
          <GiftingTemplateForm
            defaultValues={editing || {}}
            onSubmit={(payload) =>
              updateRequest
                .mutateAsync({ id: editing!.id, ...payload })
                .then(() => {
                  setEditing(null);
                })
            }
          />
        </SheetContent>
      </Sheet>

      <Sheet open={creating} onOpenChange={setCreating}>
        <SheetContent className="sm:max-w-xl">
          <SheetHeader className="mb-4">
            <SheetTitle>New Template</SheetTitle>
          </SheetHeader>
          <GiftingTemplateForm
            defaultValues={{}}
            onSubmit={(payload) =>
              createRequest.mutateAsync(payload).then(() => {
                setCreating(false);
              })
            }
          />
        </SheetContent>
      </Sheet>
    </div>
  );
}
