import { FormField, FormItem } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import MarginFormDialog from "@/components/projects/optionForm/MarginFormDialog";
import { useFormContext } from "react-hook-form";

export default function DynamicPricingSwitch({
  disabled,
}: {
  disabled: boolean;
}) {
  const form = useFormContext<{
    use_dynamic_pricing: boolean;
    target_margin: number;
  }>();
  const targetMargin = form.watch("target_margin");

  return (
    <FormField
      control={form.control}
      name="use_dynamic_pricing"
      render={({ field }) => (
        <FormItem className="flex items-center space-x-2 space-y-0">
          <Switch
            id="existing-layout-switch"
            checked={field.value}
            onCheckedChange={field.onChange}
            disabled={disabled}
          />
          <Label variant="checkbox" htmlFor="existing-layout-switch">
            <span>Use Dynamic Pricing </span>
            <MarginFormDialog
              initialValue={targetMargin}
              onSubmit={(v) => form.setValue("target_margin", v)}
            />
          </Label>
        </FormItem>
      )}
    />
  );
}
