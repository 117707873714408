import { GiftLink } from "avail-types";
import { RedeemPayload, useRedeemGift } from "@/api/gifts";
import Wizard, { WizardStep } from "@/components/wizard/Wizard";
import GiftAddressForm from "@/components/gifting/links/GiftAddressForm";
import MakeSelectionsForm from "@/components/gifting/links/MakeSelectionsForm";
import { Navigate, useParams } from "react-router-dom";
import pluralize from "pluralize";

export default function GiftLinkForm({ gift }: { gift: GiftLink }) {
  const { id } = useParams();
  const redeemGift = useRedeemGift(id!);

  const steps: WizardStep<RedeemPayload>[] = [];

  if (!gift.address) {
    steps.push({
      name: "Address",
      description: "Where should we send this gift?",
      component: GiftAddressForm,
      isComplete: (v) => Boolean(v.address),
    });
  }

  if (gift.items.some((i) => !i.selections || i.selections.length === 0)) {
    steps.push({
      name: `Choose ${pluralize("Gift", gift.items.length)}`,
      description: "Make your selections",
      component: (props) => <MakeSelectionsForm gift={gift} {...props} />,
      isComplete: (v) => (v.items ? v.items.every((v) => v.selections) : false),
    });
  }

  if (steps.length === 0) {
    return <Navigate to=".." replace />;
  }

  return (
    <Wizard
      defaultValues={{
        address: gift.address || undefined,
        items: gift.items.map((i) => ({
          id: i.id,
          selections: undefined,
        })),
      }}
      isLoading={redeemGift.isLoading}
      onSubmit={redeemGift.mutateAsync}
      steps={steps}
    />
  );
}
