import { useParams } from "react-router-dom";
import SplashScreen from "@/pages/SplashScreen";
import { useTitle } from "@/lib/title";
import { useGetMembership } from "@/api/membership";
import GiftsTable from "@/components/gifting/GiftsTable";
import { Card } from "@/components/ui/card";
import UserAvatar from "@/components/account/UserAvatar";
import MembershipRole from "@/components/account/MemebershipRole";
import { curr } from "@/lib/curr";
import Breadcrumbs from "@/components/ui/breadcrumbs";
import MembershipTeam from "@/components/account/MembershipTeam";

export default function MembershipDetail() {
  const { id } = useParams<{ id: string }>();
  const { data: membership, isLoading } = useGetMembership(Number(id));
  useTitle(membership?.user.name, "User");

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!membership) {
    return null;
  }

  const { user } = membership;

  return (
    <div>
      <Breadcrumbs
        className="mb-4"
        crumbs={[
          { href: "/account?tab=team", title: "Team Members" },
          {
            href: `/members/${id}`,
            title: user.name,
          },
        ]}
      />
      <div className="flex flex-col gap-4 xl:flex-row-reverse">
        <div className="flex-0 xl:w-96">
          <Card>
            <div className="flex items-center gap-4 border-b px-5 py-4">
              <UserAvatar user={membership.user} className="size-14" />
              <div>
                <h1 className="text-lg font-medium">{user.name}</h1>
                <p className="text-muted-foreground">{user.email}</p>
              </div>
            </div>
            <div className="p-5">
              <div className="grid grid-cols-3 gap-6">
                <div className="text-sm font-medium">Title</div>
                <div className="col-span-2">{user.title ?? "-"}</div>
                <div className="text-sm font-medium">Role</div>
                <div className="col-span-2">
                  <MembershipRole membership={membership} />
                </div>
                <div className="text-sm font-medium">Budget</div>
                <div className="col-span-2">
                  <MembershipTeam membership={membership} />
                </div>
                <div className="text-sm font-medium">Balance</div>
                <div className="col-span-2">
                  {curr(membership.gifting_balance)}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="min-w-0 flex-grow">
          <GiftsTable
            params={{ "filter[sender_id]": user.id }}
            columnVisibility={{
              sender: false,
            }}
            enableSelection={false}
          />
        </div>
      </div>
    </div>
  );
}
