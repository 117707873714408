import { ReactNode, useState } from "react";
import { Check, Cog, CreditCard, LogOut, SparklesIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  useAuthedState,
  usePlanName,
  useSubscription,
} from "@/context/AppContext";
import { useQueryClient } from "@tanstack/react-query";
import { Badge } from "@/components/ui/badge";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import Gate from "@/components/roles/Gate";
import orderBy from "lodash/orderBy";
import { useOnLogout } from "@/api/auth";
import { setCustomerId, useGetApiUrl } from "@/lib/auth";
import UserAvatar from "@/components/account/UserAvatar";
import { formatRelative } from "@/lib/dates";
import { fromUnixTime } from "date-fns";

export default function AccountDropdownMenu({
  children,
}: {
  children: ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const context = useAuthedState();
  const { membership, memberships, setShowSplash, user } = context;
  const queryClient = useQueryClient();
  const [customerQuery, setCustomerQuery] = useState("");
  const navigate = useNavigate();
  const getApiUrl = useGetApiUrl();
  const subscription = useSubscription();
  const planName = usePlanName();

  const changeCustomer = (customerId: number) => {
    setShowSplash(true);
    setCustomerId(String(customerId));
    queryClient.resetQueries();
    setTimeout(() => {
      setShowSplash(false);
    }, 500);
  };

  const logoutRequest = useOnLogout();

  const filteredMemberships = memberships.filter(
    (m) =>
      !customerQuery ||
      m.customer.name.toLowerCase().includes(customerQuery.toLowerCase()),
  );
  const sortedMemberships = orderBy(
    filteredMemberships,
    [(m) => m.unread_notification_count, (m) => m.customer.name],
    ["desc", "asc"],
  );

  const handleSelect = (func: () => void) => () => {
    setOpen(false);
    func();
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="p-0">
        <Command shouldFilter={false}>
          <CommandGroup>
            <CommandItem
              disabled
              className="flex w-full items-center text-left text-sm leading-5"
            >
              <UserAvatar user={user} className="mr-3 size-6" />
              <div className="flex flex-grow flex-col overflow-hidden">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs opacity-75">
                  {membership?.customer.name}
                </span>
              </div>
            </CommandItem>
            <CommandItem
              onSelect={handleSelect(() => navigate("/plans"))}
              className="text-sm"
            >
              <SparklesIcon className="mr-3 size-6 p-1" />
              <div>
                <div>{planName}</div>
                {subscription?.status === "trialing" && (
                  <div className="text-xs text-primary">
                    Trial Ends{" "}
                    {formatRelative(fromUnixTime(subscription.trial_end!))}
                  </div>
                )}
              </div>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          {memberships.length > 3 && (
            <CommandInput
              placeholder="Search customers..."
              value={customerQuery}
              onValueChange={setCustomerQuery}
            />
          )}
          {memberships.length > 1 && (
            <CommandGroup className="max-h-[16rem] overflow-y-auto">
              {sortedMemberships.map((m) => (
                <CommandItem
                  key={m.id}
                  onSelect={handleSelect(() => changeCustomer(m.customer.id))}
                  className="overflow-hidden"
                >
                  <Avatar className="flex-0 mr-3 size-6">
                    {m.customer.logo || m.customer.website ? (
                      <AvatarImage
                        src={
                          m.customer.logo ||
                          `https://logo.clearbit.com/${m.customer.website}`
                        }
                      />
                    ) : null}
                    <AvatarFallback>{m.customer.name[0]}</AvatarFallback>
                  </Avatar>
                  <div className="truncate">{m.customer.name}</div>
                  {m.unread_notification_count ? (
                    <Badge variant="primary" className="flex-0 -my-1 ml-2">
                      {m.unread_notification_count}
                    </Badge>
                  ) : null}
                  <Check
                    className={cn(
                      "flex-0 ml-auto size-4",
                      membership?.id === m.id ? "opacity-100" : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          <CommandSeparator />
          <CommandGroup>
            <Gate role={["admin", "avail", "avail_incognito"]}>
              <CommandItem
                onSelect={handleSelect(() => {
                  window.location.href = getApiUrl("billing");
                })}
              >
                <CreditCard className="mr-3 size-6 p-1" />
                Billing
              </CommandItem>
            </Gate>
            <CommandItem onSelect={handleSelect(() => navigate("/account"))}>
              <Cog className="mr-3 size-6 p-1" />
              Account Settings
            </CommandItem>
            <CommandItem onSelect={handleSelect(() => logoutRequest.mutate())}>
              <LogOut className="mr-3 size-6 p-1" /> Logout
            </CommandItem>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
