import { ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useRetryGift } from "@/api/gifts";
import { Gift } from "avail-types";

export default function GiftRetryDialog({
  gift,
  children,
}: {
  gift: Gift;
  children?: ReactNode;
}) {
  const retryGiftRequest = useRetryGift(gift.id);
  const [open, setOpen] = useState(false);

  const handleRetry = () =>
    retryGiftRequest.mutateAsync().then(() => {
      setOpen(false);
    });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Retry Gift</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Are you sure you want to retry sending this gift?
        </DialogDescription>

        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            No
          </Button>
          <Button isLoading={retryGiftRequest.isLoading} onClick={handleRetry}>
            Retry
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
