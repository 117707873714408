import {
  useCreateCustomerBrandFile,
  useDeleteCustomerBrandFile,
  useGetCustomerBrandFiles,
} from "@/api/brand-files";
import ImageUploadField from "@/components/images/ImageUploadField";
import { ExternalLink, Loader, PencilIcon, X } from "lucide-react";
import BrandCard from "@/components/brand/BrandCard";
import BrandCardAction, {
  BrandCardAnchorAction,
} from "@/components/brand/BrandCardAction";
import BrandCardActions from "@/components/brand/BrandCardActions";
import UpdateFileDialog from "@/components/brand/UpdateFileDialog";
import BrandCardGrid from "@/components/brand/BrandCardGrid";
import BrandCardLabel from "@/components/brand/BrandCardLabel";
import { Badge } from "@/components/ui/badge";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { useToast } from "@/components/ui/use-toast";

export default function BrandLogos() {
  const { data: files = [], isLoading } = useGetCustomerBrandFiles();
  const createFileRequest = useCreateCustomerBrandFile("logo");
  const deleteFileRequest = useDeleteCustomerBrandFile();
  const { toast } = useToast();
  return (
    <div>
      <h3 className="mb-1 text-lg font-semibold">Logos</h3>

      {isLoading ? (
        <Loader />
      ) : (
        <BrandCardGrid>
          {files
            .filter((file) => file.type === "logo")
            .map((file) => (
              <BrandCard key={file.id}>
                {file.image ? (
                  <img
                    src={file.image}
                    className="mb-12 h-full w-full object-contain"
                  />
                ) : null}

                <BrandCardLabel>
                  <div>{file.name}</div>
                  <div className="mt-1 flex items-center justify-center gap-1">
                    <Badge className="h-6 text-xs">
                      {file.extension.toUpperCase()}
                    </Badge>
                    {file.colors?.map((hex) => (
                      <SimpleTooltip key={hex} tooltip={hex} asChild>
                        <button
                          type="button"
                          onClick={() =>
                            window.navigator.clipboard
                              .writeText(hex)
                              .then(() => {
                                toast({ title: "Color copied to clipboard" });
                              })
                          }
                          className="inline-block size-5 flex-shrink-0 rounded-full"
                          style={{ backgroundColor: hex }}
                        />
                      </SimpleTooltip>
                    ))}
                  </div>
                </BrandCardLabel>

                <BrandCardActions>
                  <UpdateFileDialog file={file}>
                    <BrandCardAction>
                      <PencilIcon className="size-4" />
                    </BrandCardAction>
                  </UpdateFileDialog>
                  <BrandCardAnchorAction href={file.url}>
                    <ExternalLink className="size-4" />
                  </BrandCardAnchorAction>
                  <BrandCardAction
                    onClick={() => {
                      if (
                        confirm("Are you sure you want to delete this logo?")
                      ) {
                        deleteFileRequest.mutate(file.id);
                      }
                    }}
                  >
                    <X className="size-4" />
                  </BrandCardAction>
                </BrandCardActions>
              </BrandCard>
            ))}

          <ImageUploadField
            image={undefined}
            accept={{
              "application/pdf": [".pdf"],
              "application/postscript": [".ai", ".eps"],
            }}
            onChange={(file, name) =>
              createFileRequest.mutateAsync({ file, name })
            }
          />
        </BrandCardGrid>
      )}
    </div>
  );
}
