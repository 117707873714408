import { Attachment, AttachmentPayload, CustomerBrandFile } from "avail-types";

export function brandFileToAttachment(file: CustomerBrandFile): Attachment {
  return {
    id: 0 - file.id,
    name: file.name,
    file: file.file,
    url: file.image || file.url,
    download_url: file.download_url,
    group: null,
    size: null,
    created_at: file.created_at,
  };
}

export function payloadToAttachment(
  payload: AttachmentPayload,
  i: number,
): Attachment {
  return {
    id: 0 - i,
    name: payload.name || payload.file.split("/").at(-1)!,
    file: payload.file,
    url: payload.file,
    download_url: payload.file,
    group: payload.group || null,
    size: payload.size || null,
    created_at: new Date().toISOString(),
  };
}

export function attachmentToPayload(a: Attachment): AttachmentPayload {
  return {
    name: a.name || a.file.split("/").at(-1)!,
    file: a.file,
    group: a.group || undefined,
    size: a.size || undefined,
  };
}
