import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const TANGO_ACCOUNT_KEY = "tangoAccount";

export function useGetTangoAccount(enabled = true) {
  return useQuery(
    [TANGO_ACCOUNT_KEY],
    () =>
      axios
        .get<{
          id: string;
          number: string;
          name: string;
          email: string;
          balance: number;
          status: string;
          created_at: string;
        }>("tango-account")
        .then(({ data }) => data),
    {
      enabled,
    },
  );
}
