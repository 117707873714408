import { Link } from "react-router-dom";
import {
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { createColumnHelper } from "@tanstack/react-table";
import Toolbar from "@/components/ui/toolbar";
import { Gift } from "avail-types";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data-table";
import { formatDate } from "@/lib/dates";
import GiftStatusBadge from "@/components/gifting/GiftStatusBadge";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { canCancelGift } from "@/components/gifting/utils";
import GiftSendReminderDialog from "@/components/gifting/GiftSendReminderDialog";
import pluralize from "pluralize";
import { ArrowRightIcon, Mail } from "lucide-react";
import UserLabel from "@/components/account/UserLabel";
import { curr } from "@/lib/curr";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { DateRange } from "react-day-picker";
import StatCard from "@/components/ui/stat-card";
import GiftsTableFilters, {
  GiftFilters,
} from "@/components/gifting/GiftsTableFilters";
import { GIFTS_KEY } from "@/api/gifts";

const columnHelper = createColumnHelper<Gift>();

export default function GiftsTable({
  params = {},
  columnVisibility,
  enableSelection = true,
}: {
  params?: Record<string, any>;
  columnVisibility?: Record<string, boolean>;
  enableSelection?: boolean;
}) {
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [filters, setFilters] = useState<GiftFilters>({});
  const tableState = useManualTableState(
    [
      {
        id: "created_at",
        desc: true,
      },
    ],
    {
      filter: {
        created_at: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
      ...params,
    },
  );

  useEffect(() => {
    const filter = {
      ...filters,
      created_at: {
        gte: dateRange?.from || null,
        lte: dateRange?.to || null,
      },
    };

    tableState.setFilters((prev) => ({
      ...prev,
      filter,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateRange]);

  const giftsQuery = useManualTableQuery<
    Gift,
    {
      count: number;
      price_sum: number;
      recipients_count: number;
      senders: { [key: number]: string };
      queues: string[];
    }
  >(GIFTS_KEY, "/gifts", tableState);
  const columns = useMemo(
    () => [
      columnHelper.accessor("first_name", {
        header: "First Name",
        cell: ({ getValue, row }) => (
          <Link to={`/gifts/${row.id}`} className="font-medium hover:underline">
            {getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("last_name", { header: "Last Name" }),
      columnHelper.accessor("company", { header: "Company" }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: ({ row, getValue }) => (
          <div className="flex items-center gap-2">
            {getValue()}
            {row.original.external_source && (
              <IntegrationIcon
                type={row.original.external_source}
                size="size-5"
                url={row.original.external_url}
              />
            )}
          </div>
        ),
      }),
      columnHelper.accessor("price", {
        header: "Current Price",
        cell: ({ getValue }) => curr(getValue()),
      }),
      columnHelper.accessor("sender.name", {
        id: "sender",
        header: "Sender",
        cell: ({ row }) => <UserLabel user={row.original.sender} />,
      }),
      columnHelper.accessor("created_at", {
        header: "Sent At",
        cell: ({ getValue }) => formatDate(getValue()),
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ({ row }) => <GiftStatusBadge gift={row.original} />,
      }),
      columnHelper.display({
        id: "actions",
        enableHiding: false,
        cell: ({ row }) => (
          <Link
            to={`/gifts/${row.original.id}`}
            className="text-muted-foreground hover:text-black"
          >
            <ArrowRightIcon className="size-4" />
          </Link>
        ),
      }),
    ],
    [],
  );
  const table = useManualTable(tableState, giftsQuery, columns, {
    columnVisibility,
  });
  if (enableSelection) {
    table.setOptions((prev) => ({
      ...prev,
      enableRowSelection: (row) => canCancelGift(row.original),
    }));
  }
  const { rows: selected } = table.getSelectedRowModel();
  const selectedIds = selected.map((row) => row.original.id);

  return (
    <div>
      <div className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-3">
        <StatCard
          title="Gifts Sent"
          value={giftsQuery.data?.count}
          isLoading={giftsQuery.isLoading}
        />
        <StatCard
          title="Unique Recipients"
          value={giftsQuery.data?.recipients_count}
          isLoading={giftsQuery.isLoading}
          className="hidden md:block"
        />
        <StatCard
          title="Gifts Value"
          value={curr(giftsQuery.data?.price_sum)}
          isLoading={giftsQuery.isLoading}
        />
      </div>
      {selectedIds.length > 0 && (
        <Toolbar
          className="mb-2"
          actions={
            <GiftSendReminderDialog ids={selectedIds}>
              <Button variant="outline">
                <Mail className="mr-1.5 size-4" />
                Send {pluralize("Reminder", selectedIds.length)}
              </Button>
            </GiftSendReminderDialog>
          }
        >
          {pluralize("Gift", selectedIds.length, true)} selected
        </Toolbar>
      )}
      <DataTable
        table={table}
        isLoading={giftsQuery.isLoading}
        header={
          <DataTableActions
            customActions={() => (
              <GiftsTableFilters
                senders={giftsQuery.data?.senders}
                queues={giftsQuery.data?.queues}
                filters={filters}
                setFilters={setFilters}
              />
            )}
            dateRange={dateRange}
            setDateRange={setDateRange}
            table={table}
            setQuery={tableState.setQuery}
          />
        }
      />
    </div>
  );
}
