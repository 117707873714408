import { Link } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import GiftLinkWrapper from "@/components/gifting/GiftLinkWrapper";
import GiftingProductImage from "@/components/gifting/products/GiftingProductImage";
import pattern from "@/assets/pattern.svg";
import { CheckCircleIcon } from "lucide-react";
import EmptyState from "@/components/ui/empty-state";
import pluralize from "pluralize";

export default function GiftLink() {
  return (
    <GiftLinkWrapper>
      {(gift) => {
        const isMyself = gift.sender_name.includes(gift.sender.name);
        return (
          <div>
            <h1 className="mb-4 text-3xl font-semibold">
              {gift.subject ?? `A gift from ${gift.sender_name} for you`}
            </h1>
            <div className="flex flex-col gap-6 lg:flex-row">
              <div className="min-w-0 flex-grow">
                <div className="grid gap-8 md:grid-cols-2">
                  <div className="col-span-full h-40 overflow-hidden rounded-2xl bg-primary lg:h-64">
                    <img
                      src={pattern}
                      className="h-full w-full object-cover object-left"
                    />
                  </div>

                  <div>
                    <h3 className="mb-3 font-semibold">From</h3>

                    <div className="flex items-center gap-3">
                      {isMyself && gift.sender.avatar ? (
                        <img
                          src={gift.sender.avatar}
                          className="size-10 rounded-full"
                        />
                      ) : gift.customer.logo ? (
                        <img
                          src={gift.customer.logo}
                          className="size-10 rounded-full"
                        />
                      ) : null}
                      <div className="min-w-0 flex-grow">
                        {isMyself ? (
                          <>
                            <p>{gift.sender_name}</p>
                            {gift.sender.title && (
                              <p className="text-muted-foreground">
                                {gift.sender.title}
                              </p>
                            )}
                            <p className="text-sm">
                              <a
                                href={`mailto:${gift.sender.email}`}
                                className="text-primary"
                              >
                                {gift.sender.email}
                              </a>
                            </p>
                          </>
                        ) : (
                          <p>{gift.sender_name}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="mb-3 font-semibold">To</h3>

                    <p>
                      {gift.first_name} {gift.last_name}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {gift.email}
                    </p>
                  </div>

                  {gift.message && (
                    <div className="col-span-full">
                      <h3 className="mb-3 font-semibold">Message</h3>
                      <blockquote className="whitespace-pre-line">
                        {gift.message}
                      </blockquote>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-shrink-0 lg:w-[22rem]">
                <Card className="flex flex-col px-5 py-4 lg:sticky lg:top-2">
                  <div className="space-y-4">
                    {gift.items.map((i) => (
                      <div key={i.id} className="space-y-3">
                        <GiftingProductImage product={i.product} />
                        <div className="line-clamp-3 text-center font-semibold leading-tight text-black">
                          {i.product.name}
                        </div>
                      </div>
                    ))}
                  </div>

                  {gift.is_redeemed ? (
                    <EmptyState
                      Icon={CheckCircleIcon}
                      className="mt-6 border-t pt-6"
                      iconClassName="text-green-500"
                      title="Your gift is on the way!"
                      description="Once it ships, you'll receive a confirmation email with tracking information. If you have any questions, contact us."
                    />
                  ) : (
                    <Link to="./redeem">
                      <Button className="mt-4 w-full">
                        Redeem {pluralize("Gift", gift.items.length)}
                      </Button>
                    </Link>
                  )}
                </Card>
              </div>
            </div>
          </div>
        );
      }}
    </GiftLinkWrapper>
  );
}
