import {
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Command as CommandPrimitive } from "cmdk";
import { FocusEventHandler, type KeyboardEvent, useRef, useState } from "react";

import { cn } from "@/lib/utils";
import { INPUT_CLASSES } from "@/components/ui/input";
import { Loader } from "lucide-react";

export interface Option {
  value: string;
  label: React.ReactNode;
}

export const AutoComplete = ({
  options,
  placeholder,
  emptyMessage,
  loadingMessage = "Loading...",
  value,
  onValueChange,
  onOptionSelect,
  disabled,
  isLoading = false,
}: {
  options: Option[] | undefined;
  emptyMessage: string;
  loadingMessage?: string;
  value: string;
  onValueChange: (value: string) => void;
  onOptionSelect: (option: Option) => void;
  isLoading: boolean;
  disabled?: boolean;
  placeholder?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setOpen] = useState(false);

  const handleSelectOption = (selectedOption: Option) => {
    onOptionSelect(selectedOption);

    // This is a hack to prevent the input from being focused after the user selects an option
    // We can call this hack: "The next tick"
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 0);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }

    // Keep the options displayed when the user is typing
    if (!isOpen) {
      setOpen(true);
    }

    if (event.key === "Escape") {
      input.blur();
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setOpen(false);
    onValueChange(e.target.value);
  };

  return (
    <CommandPrimitive onKeyDown={handleKeyDown} shouldFilter={false}>
      <CommandPrimitive.Input
        ref={inputRef}
        value={value}
        onValueChange={onValueChange}
        onBlur={handleBlur}
        onFocus={() => setOpen(true)}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(
          INPUT_CLASSES,
          "flex h-11 w-full border border-input bg-card text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        )}
      />
      {(options || isLoading) && (
        <div className="relative mt-2">
          <div
            className={cn(
              "absolute top-0 z-10 w-full rounded-xl bg-white outline-none animate-in fade-in-0 zoom-in-95",
              isOpen ? "block" : "hidden",
            )}
          >
            <CommandList className="rounded-lg ring-1 ring-slate-200">
              {isLoading ? (
                <CommandPrimitive.Loading>
                  <div className="flex items-center justify-between px-3 py-2 text-sm text-muted-foreground">
                    {loadingMessage}
                    <Loader className="size-5 animate-spin" />
                  </div>
                </CommandPrimitive.Loading>
              ) : options!.length > 0 ? (
                <CommandGroup>
                  {options!.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onSelect={() => handleSelectOption(option)}
                      className={cn("flex w-full items-center gap-2")}
                    >
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ) : (
                <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                  {emptyMessage}
                </CommandPrimitive.Empty>
              )}
            </CommandList>
          </div>
        </div>
      )}
    </CommandPrimitive>
  );
};
