import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { GiftPayload, GiftRecipientPayload } from "avail-types";
import GiftRecipientForm from "@/components/gifting/send/GiftRecipientForm";
import { getIsDigitalGift } from "@/components/gifting/utils";

export default function GiftRecipientStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<GiftPayload>;
  onSubmit: (v: GiftRecipientPayload) => Promise<void>;
}) {
  return (
    <WizardStep>
      <Toolbar>
        <Title>Who is the recipient of this gift?</Title>
        <Description>
          Enter the recipient's information to send them a gift.
        </Description>
      </Toolbar>

      <GiftRecipientForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isDigitalGift={getIsDigitalGift(defaultValues)}
      >
        <WizardSubmitButton className="mt-6">Next</WizardSubmitButton>
      </GiftRecipientForm>
    </WizardStep>
  );
}
