import { ChangeEvent, SyntheticEvent, useState } from "react";
import { CustomerSku } from "avail-types";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { SheetFooter } from "@/components/ui/sheet";
import SkuItem from "@/components/inventory/SkuItem";
import { Label } from "@/components/ui/label";
import { Alert } from "@/components/ui/alert";
import pluralize from "pluralize";

export type SkuQty = CustomerSku & { qty: number };

export default function RestockForm({
  onSubmit,
  skus,
  isLoading,
}: {
  onSubmit: (e: SyntheticEvent, skus: SkuQty[]) => Promise<void>;
  skus: SkuQty[];
  isLoading: boolean;
}) {
  const [skusQty, setSkusQty] = useState<SkuQty[]>(skus);

  const onChange = ({
    e,
    skuId,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    skuId: number;
  }) => {
    const qty = JSON.parse(e.target.value);
    setSkusQty((prev) =>
      prev.map((sku) => {
        if (skuId === sku.id) {
          return { ...sku, qty };
        }
        return sku;
      }),
    );
  };

  return (
    <form onSubmit={(e) => onSubmit(e, skusQty)}>
      {skusQty.map((sku, idx) => {
        return (
          <div className="grid gap-4 py-4" key={idx}>
            <div className="flex flex-col">
              <SkuItem sku={sku} />
              {sku.inventory_on_order > 0 && (
                <Alert variant="success">
                  {pluralize("unit", sku.inventory_on_order, true)} are already
                  on order
                </Alert>
              )}

              <Label className="mt-3">Quantity to Restock</Label>
              <Input
                onChange={(e) => onChange({ e, skuId: sku.id })}
                value={sku.qty}
                step={1}
                inputMode="numeric"
                className="mt-1"
                type="number"
                onFocus={(e) => e.target.select()}
              />
              {sku.par_level && (
                <div className="mt-1 text-sm text-muted-foreground">
                  Par Level: {sku.par_level}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <SheetFooter>
        <Button type="submit" isLoading={isLoading}>
          Create Project
        </Button>
      </SheetFooter>
    </form>
  );
}
