import { CustomerUser } from "avail-types";
import { useMemberships } from "@/api/membership";
import { Link } from "react-router-dom";

export default function UserLabel({ user }: { user: CustomerUser | null }) {
  const { data: memberships = [] } = useMemberships();

  if (!user) {
    return "-";
  }

  const member = memberships.find((m) => m.user.id === user.id);

  if (!member) {
    return user.name;
  }

  return (
    <Link to={`/members/${member.id}`} className="border-b border-dashed">
      {user.name}
    </Link>
  );
}
