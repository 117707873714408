import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { handleLaravelErrors } from "@/lib/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
  SelectTrigger,
} from "@/components/ui/select";
import { INVITES_KEY } from "@/api/invites";

const formSchema = z.object({
  email: z.string().email(),
  project_id: z.number().optional(),
  role: z.string(),
});

export default function InviteTeamMemberForm({
  projectId,
  onInvited,
  userQuery,
}: {
  projectId?: number;
  onInvited?: () => void;
  userQuery?: string;
}) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: userQuery || "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (projectId) {
      values.project_id = projectId;
    }

    return axios
      .post(`/invites`, values)
      .then(() => {
        form.reset();
        queryClient.invalidateQueries([INVITES_KEY]);
        if (onInvited) {
          onInvited();
        }
        toast({ title: "Invitation sent", variant: "success" });
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-2 md:flex md:items-center md:space-x-3 md:space-y-0"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="md:w-72 md:flex-grow">
              <FormControl>
                <Input
                  type="email"
                  placeholder="e.g. john.doe@gmail.com"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem className="md:w-36 md:flex-1">
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="admin">Admin</SelectItem>
                  <SelectItem value="member">Member</SelectItem>
                  <SelectItem value="guest">Guest</SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          disabled={!form.formState.isValid}
        >
          Invite
        </Button>
      </form>
    </Form>
  );
}
