import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const BUSINESSES_KEY = "businesses";

export function useGetBusiness(slug: string | undefined) {
  return useQuery(
    [BUSINESSES_KEY, "detail", slug],
    () => axios.get(`/businesses/${slug}`).then(({ data }) => data),
    {
      enabled: !!slug,
    },
  );
}
