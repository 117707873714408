import { Table as ReactTable } from "@tanstack/react-table";
import Toolbar from "@/components/ui/toolbar";
import { Button } from "@/components/ui/button";
import { Columns, Download } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { DateRange } from "react-day-picker";
import SearchInput from "@/components/ui/search-input";
import WebstoreSelect from "@/components/selects/WebstoreSelect";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export function DataTableActions({
  canFilterByWebstore,
  customActions,
  dateRange,
  downloadRequest,
  filters,
  inputClassName,
  placeholder,
  query,
  setDateRange,
  setQuery,
  setFilters,
  table,
}: {
  canFilterByWebstore?: boolean;
  customActions?: () => React.ReactNode;
  dateRange?: DateRange | undefined;
  downloadRequest?: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    void,
    unknown
  >;
  filters?: Record<string, any>;
  inputClassName?: string;
  placeholder?: string;
  query?: string;
  setDateRange?: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  setQuery?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setFilters?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  table?: ReactTable<any>;
}) {
  return (
    <Toolbar
      actions={
        <>
          {customActions && customActions()}
          {setDateRange && (
            <DateRangePicker selected={dateRange} onSelect={setDateRange} />
          )}
          {canFilterByWebstore && filters && setFilters && (
            <WebstoreSelect
              value={filters.webstore}
              onChange={(v) => setFilters({ webstore: v })}
            />
          )}
          <div>
            {table && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="icon" className="rounded-lg">
                    <Columns className="size-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .map((column) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={column.id}
                          className="capitalize"
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) =>
                            column.toggleVisibility(!!value)
                          }
                        >
                          {typeof column.columnDef.header === "string"
                            ? column.columnDef.header
                            : column.id}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            {downloadRequest && (
              <Button
                variant="outline"
                size="icon"
                className="ml-2 rounded-lg"
                onClick={() => downloadRequest.mutate()}
                isLoading={downloadRequest.isLoading}
              >
                {!downloadRequest.isLoading && <Download className="size-4" />}
              </Button>
            )}
          </div>
        </>
      }
    >
      {setQuery && (
        <SearchInput
          onChange={setQuery}
          placeholder={placeholder}
          className={inputClassName ?? "w-80"}
          defaultValue={query}
        />
      )}
    </Toolbar>
  );
}
