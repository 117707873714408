import { ReactNode, useEffect } from "react";
import { createColumnHelper, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerInvoice } from "avail-types";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import Toolbar from "@/components/ui/toolbar";
import { UseQueryResult } from "@tanstack/react-query";
import { usePayRequest } from "@/lib/orders";
import { Badge } from "@/components/ui/badge";
import { ArrowRight } from "lucide-react";
import { formatDate } from "@/lib/dates";

const columnHelper = createColumnHelper<CustomerInvoice>();
const COLUMNS = [
  columnHelper.accessor("increment_id", {
    header: "Invoice Number",
    cell: ({ getValue, row }) => (
      <Link to={`/invoices/${row.original.code}`} className="font-medium">
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("invoiced_at", {
    header: "Invoice Date",
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor("customer_po", {
    header: "PO Number",
  }),
  columnHelper.accessor("description", {
    header: "Description",
  }),
  columnHelper.accessor("order_type.name", {
    header: "Order Type",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: ({ getValue }) => <Badge>{getValue()}</Badge>,
  }),
  columnHelper.accessor("total", {
    header: "Total",
    cell: ({ getValue }) => curr(getValue()),
  }),
  columnHelper.accessor("balance", {
    header: "Balance",
    cell: ({ getValue }) => curr(getValue()),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <Button asChild variant="link" size="sm">
        <Link to={`/orders/${row.original.code}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </Button>
    ),
  }),
];

export default function InvoicesTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<CustomerInvoice>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<CustomerInvoice>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS);
  table.setOptions((prev) => ({
    ...prev,
    enableRowSelection: (row) => row.original.balance > 0,
  }));
  const { rows: selected } = table.getSelectedRowModel();
  const payRequest = usePayRequest();

  useEffect(() => {
    if (query.data) {
      table.setColumnVisibility({
        customer_po: query.data.data.some((r) => r.customer_po),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  return (
    <div>
      {selected.length > 0 && (
        <Toolbar
          actions={
            <Button
              isLoading={payRequest.isLoading}
              onClick={() =>
                payRequest.mutate({
                  invoices: selected.map((r) => r.original.code),
                })
              }
            >
              Pay
            </Button>
          }
          className="py-2"
        >
          {selected.length} {selected.length === 1 ? "invoice" : "invoices"}{" "}
          selected
        </Toolbar>
      )}
      <DataTable
        header={header && header(table)}
        table={table}
        isLoading={query.isLoading}
      />
    </div>
  );
}
