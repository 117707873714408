import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { CustomerTeam } from "avail-types";
import { useToast } from "@/components/ui/use-toast";
import { TrashIcon } from "lucide-react";
import { useDeleteTeam } from "@/api/teams";

export default function TeamDeleteButton({ team }: { team: CustomerTeam }) {
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const deleteRequest = useDeleteTeam();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <TrashIcon className="size-4 text-muted-foreground" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Gifting Team</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want delete this team?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={deleteRequest.isLoading}
            onClick={() =>
              deleteRequest.mutateAsync(team.id).then(() => {
                setOpen(false);
                toast({
                  title: "Successfully deleted team",
                  variant: "success",
                });
              })
            }
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
