import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDebounce from "@/hooks/useDebounce";
import { ProjectOptionPayload } from "avail-types";

export const SAGE_KEY = "sage";

export interface SageSearchProduct {
  name: string;
  prc: string;
  prodEId: number;
  spx: string;
  thumbPic: string;
}

interface OptionValue {
  Value: string;
  Prc: string[];
  Net: string[];
}

interface Option {
  Name: string;
  Values: OptionValue[];
  PricingIsTotal: number;
  PriceCode: string;
}

export interface Picture {
  index: number;
  caption: string;
  url: string;
  hasLogo: number;
}

interface SupplierInfo {
  suppId: number;
  coName: string;
  lineName: string;
  contactName: string;
  mAddr: string;
  mCity: string;
  mState: string;
  mZip: string;
  mCountry: string;
  sAddr: string;
  sCity: string;
  sState: string;
  sZip: string;
  sCountry: string;
  tel: string;
  tollFreeTel: string;
  fax: string;
  tollFreeFax: string;
  email: string;
  web: string;
  unionShop: number;
  esg: string;
  artContactName: string;
  artContactEmail: string;
  catYear: string;
  catExpOn: string;
  catCurrency: string;
  comment: string;
  prefGroupIDs: string;
  prefGroups: string;
  persCsRep: string;
  persCsRepPhn: string;
  persCsRepEmail: string;
  persCustNum: string;
  persSuppNote: string;
  generalInfo: {
    artInfo: string;
    copyChangeInfo: string;
    imprintMethods: string;
    imprintColors: string;
    proofInfo: string;
    pMsCharge: string;
    pMsChargeCode: string;
    copyChangeCharge: string;
    copyChangeChargeCode: string;
    artChargeHr: string;
    artChargeHrCode: string;
    artChargeJob: string;
    artChargeJobCode: string;
    proofCharge: string;
    proofChargeCode: string;
    specSampleCharge: string;
    specSampleChargeCode: string;
    orderChangeInfo: string;
    orderCancelInfo: string;
    lessMinInfo: string;
    overrunInfo: string;
    shipInfo: string;
    termsInfo: string;
    warrantyInfo: string;
    returnsInfo: string;
    coOpInfo: string;
    otherInfo: string;
  };
}

export interface SageProductDetail {
  prodEid: number;
  category: string;
  suppId: number;
  lineName: string;
  catPage: string;
  catYear: number;
  itemNum: string;
  spc: string;
  prName: string;
  description: string | null;
  dimensions: string;
  keywords: string;
  colors: string;
  themes: string;
  qty: string[];
  prc: string[];
  priceCode: string;
  catPrc: string[];
  catPriceCode: string;
  net: string[];
  priceAdjustMsg: string;
  currency: string;
  piecesPerUnit: string[];
  options: Option[];
  madeInCountry: string;
  assembledInCountry: string;
  decoratedInCountry: string;
  recyclable: number;
  newProduct: number;
  envFriendly: number;
  audienceWarning: number;
  food: number;
  clothing: number;
  productCompliance: string;
  warningLbl: string;
  productComplianceMemo: string;
  verified: number;
  imprintArea: string;
  imprintLoc: string;
  secondImprintArea: string;
  secondImprintLoc: string;
  decorationMethod: string;
  decorationNotOffered: number;
  setupChg: string;
  setupChgCode: string;
  repeatSetupChg: string;
  repeatSetupChgCode: string;
  screenChg: string;
  screenChgCode: string;
  plateChg: string;
  plateChgCode: string;
  dieChg: string;
  dieChgCode: string;
  toolingChg: string;
  toolingChgCode: string;
  addClrChg: string;
  addClrChgCode: string;
  addClrRunChg: string[];
  addClrRunChgCode: string;
  priceIncludes: string;
  package: string;
  weightPerCarton: string;
  unitsPerCarton: string;
  cartonL: string;
  cartonW: string;
  cartonH: string;
  shipPointCountry: string;
  shipPointZip: string;
  prodTime: string;
  comment: string;
  pics: Picture[];
  expDate: string;
  discontinued: number;
  active: number;
  supplier: SupplierInfo;
}

export function useSearchSageProducts(query: string) {
  const debouncedQuery = useDebounce(query, 500);

  return useQuery<SageSearchProduct[], Error>(
    [SAGE_KEY, "search", debouncedQuery],
    () =>
      axios
        .get<SageSearchProduct[]>(`/sage/search?query=${debouncedQuery}`)
        .then(({ data }) => data),
    {
      enabled: !!debouncedQuery,
    },
  );
}

export function useGetSageProductDetails(prodEId: number) {
  return useQuery<SageProductDetail, Error>([SAGE_KEY, "detail", prodEId], () =>
    axios
      .get<SageProductDetail>(`/sage/get?query=${prodEId}`)
      .then(({ data }) => data),
  );
}

export function useSelectSageProduct() {
  return useMutation(
    (data: {
      color: string | null;
      prodEId: number;
      project_item_id: number;
    }) =>
      axios
        .post<ProjectOptionPayload>("/sage/select", data)
        .then(({ data }) => data),
  );
}
