import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { curr } from "@/lib/curr";
import pluralize from "pluralize";
import { GiftingProduct, GiftItemSelection } from "avail-types";
import GiftingProductImage from "@/components/gifting/products/GiftingProductImage";

export default function GiftingProductCard({
  product,
  selections,
  className,
  children,
}: {
  product: GiftingProduct;
  selections?: GiftItemSelection[];
  className?: string;
  children?: React.ReactNode;
}) {
  const minPriceNode = <b className="font-medium">{curr(product.min_price)}</b>;
  return (
    <Card
      className={cn(
        "relative pt-3 text-xs transition-all sm:text-sm",
        className,
      )}
    >
      <div className="mb-20">
        <GiftingProductImage product={product} />
      </div>
      <div className="absolute bottom-0 space-y-2 bg-white p-4 text-left">
        <div className="line-clamp-3 font-semibold leading-tight text-black">
          {product.name}
        </div>
        {selections != null ? (
          <>
            {selections.length > 0 ? (
              <ul className="space-y-1 text-xs text-muted-foreground">
                {selections.map((s) => (
                  <li key={s.id}>
                    {s.name}
                    {s.qty != 1 && <span> x {s.qty}</span>}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-xs text-muted-foreground">
                Awaiting selections by recipient
              </p>
            )}
          </>
        ) : (
          <div className="space-y-1 text-xs leading-tight text-slate-700">
            <div>
              {product.min_price === product.max_price ? (
                minPriceNode
              ) : (
                <>As low as {minPriceNode}</>
              )}
            </div>
            {product.variants.length > 1 && (
              <div className="text-slate-700">
                {pluralize("size", product.variants.length, true)}
              </div>
            )}
          </div>
        )}
      </div>

      {children}
    </Card>
  );
}
