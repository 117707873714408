import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-1 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 whitespace-nowrap",
  {
    variants: {
      variant: {
        primary: "border-primary/40 text-primary bg-primary/10",
        destructive: "border-destructive/40 text-destructive bg-destructive/10",
        outline: "text-foreground bg-white",

        gray: "border-slate-500/10 bg-slate-50 text-slate-600",
        red: "border-red-600/10 bg-red-50 text-red-700",
        amber: "border-amber-700/10 bg-amber-50 text-amber-700",
        yellow: "border-yellow-600/20 bg-yellow-50 text-yellow-800",
        green: "border-green-600/20 bg-green-50 text-green-700",
        blue: "border-blue-700/10 bg-blue-50 text-blue-700",
        teal: "border-teal-700/10 bg-teal-50 text-teal-700",
        indigo: "border-indigo-700/10 bg-indigo-50 text-indigo-700",
        purple: "border-purple-700/10 bg-purple-50 text-purple-700",
        pink: "border-pink-700/10 bg-pink-50 text-pink-700",
      },
    },
    defaultVariants: {
      variant: "outline",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
