import { Progress } from "@/components/ui/progress";
import { useAppState } from "@/context/AppContext";
import { curr } from "@/lib/curr";
import startCase from "lodash/startCase";

export default function GiftingBudgetProgressBar() {
  const { membership } = useAppState();
  if (membership.gifting_balance != null && membership.team) {
    const used = membership.team.budget - membership.gifting_balance;
    const percent = (used * 100) / membership.team.budget;
    return (
      <div className="w-80 space-y-1 text-xs">
        <div className="flex items-center justify-between">
          <span className="font-medium">
            {startCase(membership.team.interval)}ly Budget
          </span>
          <span>{Math.round(percent)}%</span>
        </div>
        <Progress value={percent} className="h-2" />
        <div className="flex items-center justify-between">
          <div className="text-muted-foreground">
            <span>You have used </span>
            <b className="font-semibold">{curr(used)}</b>
            <span> of your </span>
            <b className="font-semibold">{curr(membership.team.budget)}</b>
            <span> budget.</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
