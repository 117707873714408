import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import useDebounce from "@/hooks/useDebounce";
import SkuItem from "@/components/inventory/SkuItem";
import { INPUT_CLASSES } from "@/components/ui/input";
import { useGetVariant, useSearchVariants } from "@/api/variants";

export default function SkuSelect({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (value: number | null) => void;
  className?: string;
}) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);

  const { data: variants = [], isLoading } = useSearchVariants(debouncedQuery);
  const { data: selectedVariant } = useGetVariant(value);

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            INPUT_CLASSES,
            "h-12 w-[300px] justify-between",
            className,
          )}
        >
          <span className="flex flex-col items-start truncate">
            <div>{value ? selectedVariant?.description : "Find SKU"}</div>
            {selectedVariant && (
              <div className="text-xs font-normal text-muted-foreground">
                {selectedVariant.sku}
              </div>
            )}
          </span>

          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search SKUs..."
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>
            {isLoading ? "Loading..." : "No SKUs found"}.
          </CommandEmpty>
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {variants.map((variant) => (
              <CommandItem
                key={variant.id}
                value={String(variant.id)}
                onSelect={(currentValue) => {
                  onChange(
                    Number(currentValue) === value
                      ? null
                      : Number(currentValue),
                  );
                  setOpen(false);
                }}
                className="gap-x-2"
              >
                <SkuItem sku={variant} />

                <Check
                  className={cn(
                    "size-4",
                    value === variant.id ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
