import { CustomerSku } from "avail-types";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Clock } from "lucide-react";
import { useState } from "react";
import SkuItem from "@/components/inventory/SkuItem";
import InventoryEntryListItem from "@/components/inventory/InventoryEntryListItem";
import { Skeleton } from "@/components/ui/skeleton";
import { useVariantInventoryEntries } from "@/api/variants";

export default function InventoryHistoryButton({ sku }: { sku: CustomerSku }) {
  const [open, setOpen] = useState(false);
  const { data: entries = [], isLoading } = useVariantInventoryEntries(
    open ? sku.id : undefined,
  );

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <Clock className="size-5 text-slate-500" />
      </SheetTrigger>
      <SheetContent className="overflow-y-auto">
        <SheetHeader className="mb-6">
          <SheetTitle>Inventory History</SheetTitle>
          <SheetDescription>
            <SkuItem sku={sku} />
          </SheetDescription>
        </SheetHeader>

        {isLoading && (
          <div className="space-y-3">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-full" />
          </div>
        )}

        <ul className="space-y-3">
          {entries.map((entry) => (
            <InventoryEntryListItem entry={entry} key={entry.id} />
          ))}
        </ul>
      </SheetContent>
    </Sheet>
  );
}
