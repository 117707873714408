import { useEffect } from "react";

function displayBeacon(display: string) {
  const el = document.getElementById("beacon-container");
  if (el) {
    el.style.display = display;
  }
}

export function useHideBeacon() {
  return useEffect(() => {
    displayBeacon("none");
    setTimeout(() => {
      displayBeacon("none");
    }, 100);
    return () => {
      displayBeacon("block");
    };
  }, []);
}
