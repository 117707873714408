import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactNode, useMemo } from "react";
import {
  QueryClient,
  MutationCache,
  QueryCache,
  QueryClientProvider,
} from "@tanstack/react-query";
import { isAxiosError } from "axios";

const getIcon = (
  variant: "info" | "success" | "warning" | "error" | undefined,
) => {
  if (variant === "error") {
    return <ExclamationCircleIcon className="size-6 text-red-400" />;
  }
  if (variant === "warning") {
    return <ExclamationTriangleIcon className="size-6 text-yellow-400" />;
  }
  if (variant === "success") {
    return <CheckCircleIcon className="size-6 text-green-400" />;
  }

  return <InformationCircleIcon className="size-6 text-blue-400" />;
};

export default function AppWrapper({ children }: { children: ReactNode }) {
  const { toasts, toast } = useToast();

  const queryClient = useMemo(() => {
    const onError = (e: any) => {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          queryClient.setQueryData(["me"], { user: null });
          queryClient.resetQueries();
        } else if (e.response?.status === 403) {
          window.location.href = "/verify-email";
        } else {
          const message = e.response?.data.message || "Something went wrong";
          toast({ title: message, variant: "error" });
        }
      }
    };

    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
      queryCache: new QueryCache({
        onError,
      }),
      mutationCache: new MutationCache({
        onError: (e, _variables, _context, mutation) => {
          if (mutation.options.onError) return;
          onError(e);
        },
      }),
    });
  }, [toast]);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        {children}
        {toasts.map(function ({ id, title, description, variant, ...props }) {
          return (
            <Toast key={id} {...props}>
              <div className="flex-shrink-0">{getIcon(variant)}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <ToastClose />
              </div>
            </Toast>
          );
        })}
        <ToastViewport />
      </ToastProvider>
    </QueryClientProvider>
  );
}
